import React, { Component, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import RootContext from '../../provider/RootProvider';
import useId from 'react-use-uuid';
import axios from 'axios';
import Files from 'react-files'
import Select from "react-select";


const AddListingAIF = () => {

    // const { projectData, setProjectData, pictureUrl, setPictureUrl ,similarProducts , setSimilarProducts } = useContext(RootContext);
    const _id = useId();
    // const [fundName,setFundName]=useState('');
    // const [product,setProduct]=useState('');
    // const [fundManager,setFundManager]=useState('');
    // const [priceRange,setPriceRange]=useState('');
    // const [productHighlights,setProductHighlights]=useState('');
    // const [fundInfo,setFundInfo]=useState('');
    // const [companyInfo,setCompanyInfo]=useState('');
    // const [productVideo,setProductVideo] = useState('')
    const [productPicture, setProductPicture] = useState([])
    const [similarProduct, setSimilarProduct] = useState([])
    const [investmentType,setInvestmentType] = useState('')
    const [projectData, setProjectData] = useState({
        _id: '',
        companyName: '',
        product: '',
        minPrice: '',
        maxPrice: '',
        productHighlights: '',
        assetInfo: '',
        companyInfo: '',
        productVideo: '',
        developerDetails:'',
        industry:'',
        sector:''
        // pictureUrls:[{id:(Math.random() + 1).toString(36).substring(7),url:""}]
        // projectPictures:[],
        // similarProducts:[],
    });

    const optionsInvestmentType = [
        { value: 'ProductEquity', label: 'Product Equity' },
        { value: 'BrandFranchise', label: 'Brand franchise' },
        { value: 'DirectEquity', label: 'Direct Equity' },
        { value: 'CorporateDebtBonds', label: 'Corporate Debt Bonds' },
    ]

    useEffect(() => {

        setProjectData(prev => { return { ...prev, _id } });
    }, [])

    const onFilesChange = (files) => {
        console.log(files)
    }

    const onFilesError = (error, file) => {
        console.log('error code ' + error.code + ': ' + error.message)
    }

    const handleRemovePictureUrl = (index, id) => {
        console.log('index', index)

        let pictureUrls = [...productPicture];
        var pictureUrls_count = pictureUrls.length;
        console.log('picture urlssss', pictureUrls)
        if (pictureUrls_count > 1) {
            // Add item to it
            //   pictureUrls.splice(index, 1);
            let filtered = pictureUrls.filter(function (value, index, arr) {
                return value.id !== id;
            });
            setProductPicture(filtered)
            // Set state
            //   this.setState({ pictureUrls });


        }






        console.log('picture url', productPicture)

    }

    useEffect(() => {

    }, [productPicture])

    const handleAddPictureUrl = () => {
        let pictureUrls = [...productPicture];

        pictureUrls.push({ id: (Math.random() + 1).toString(36).substring(7), url: "" });


        setProductPicture(pictureUrls)
        console.log('picture url', productPicture)
    }

    const onChangePictureHandler = (e, id) => {
        // console.lo
        let pictureUrls = [...productPicture];

        pictureUrls.map((url) => {
            if (url.id == id) {
                url.url = e.target.value;
            }
        })


        setProductPicture(pictureUrls)
        console.log('picture url', productPicture)

    }

    const onChangeHandler = (event) => {

        setProjectData((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })

    }

    const handleAddSimilarProducts = () => {
        let pictureUrls = [...similarProduct];

        pictureUrls.push({ id: (Math.random() + 1).toString(36).substring(7), url: "" });


        setSimilarProduct(pictureUrls)
        console.log('picture url', similarProduct)
    }

    const handleRemoveSimilarProducts = (index, id) => {
        console.log('index', index)

        let pictureUrls = [...similarProduct];
        var pictureUrls_count = pictureUrls.length;
        console.log('picture urlssss', pictureUrls)
        if (pictureUrls_count > 1) {
            // Add item to it
            //   pictureUrls.splice(index, 1);
            let filtered = pictureUrls.filter(function (value, index, arr) {
                return value.id !== id;
            });
            setSimilarProduct(filtered)
            // Set state
            //   this.setState({ pictureUrls });


        }






        console.log('picture url', similarProduct)

    }

    const onChangeSimilarProductsHandler = (e, id) => {
        // console.lo
        let pictureUrls = [...similarProduct];

        pictureUrls.map((url) => {
            if (url.id == id) {
                url.url = e.target.value;
            }
        })


        setSimilarProduct(pictureUrls)
        console.log('picture url', similarProduct)
    }

    const submitProjectDataHandler = () => {
        console.log("project data: ", projectData, productPicture, similarProduct, investmentType);
        if (!projectData._id ||
            !projectData.companyName ||
            !projectData.product ||
            !projectData.minPrice ||
            !projectData.maxPrice ||
            !projectData.productHighlights ||
            !projectData.assetInfo ||
            !projectData.companyInfo ||
            !projectData.productVideo ||
            !projectData.developerDetails ||
            !projectData.industry ||
            !projectData.sector
            )
            return;


            let data = {...projectData , "productPicture":productPicture , "similarProduct":similarProduct, "investmentType":investmentType, "persona": [], "type":"aif"}
            console.log("data", data)
            // data = JSON.stringify(data);
            var config = {
                method: 'post',
                url: 'https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/realstateadd',
                headers: {
                    'Content-Type': 'application/json'
                    // 'Access-Control-Allow-Headers': 'Content-Type',
                    // "Content-Type": "application/json",
                    // 'Access-Control-Allow-Methods':'OPTIONS,POST',
                    // "Access-Control-Allow-Origin":"*"
                    // "X-Requested-With":'*'
                    // 'Access-Control-Allow-Origin': '*',
                    // 'Content-Type': 'application/json',
                },
                data
            };
    
            axios(config)
                .then(function (response) {
    
                    // alert('Your account is successfully registered. You can now login with your registered email ID and password.')
                    alert("RECORD added successfully!!")
    
                })
                .catch(function (error) {
                    console.log(error);
                });
    
    
            alert("Record Added Successfully")


        // var config = {
        //     method: 'post',
        //     url: ' https://a4r3tyxqsa.execute-api.us-east-1.amazonaws.com/dev/add-real-state-project',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     data
        // };

        // axios(config)
        //     .then(function (response) {

        //         // alert('Your account is successfully registered. You can now login with your registered email ID and password.')
        //         alert("RECORD added successfully!!")

        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });




    }

    // const AddPictureContainer = () =>{
    //     return (
    //         <div className="row">

    //                                     <div className="col-md-6">
    //                                         <input type="text" value={url.value} id={url.id} name={url.id} placeholder="picture link" onChange={onChangePictureHandler} />
    //                                     </div>
    //                                     <div className="col-md-6">
    //                                         <svg xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} height='32px' width='32px' viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-minus-circle">
    //                                             <circle cx="12" cy="12" r="10"></circle>
    //                                             <line x1="8" y1="12" x2="16" y2="12"></line>
    //                                         </svg>
    //                                         {/* <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="button" onClick={handleRemovePictureUrl}>Remove </button> */}
    //                                     </div>

    //                                 </div>
    //     )
    // }


    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
        <div className="ltn__appointment-area pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ltn__appointment-inner">
                            {/* <form action="#"> */}

                            <div className="row">

                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.companyName} id="companyName" name="companyName" placeholder="company Name" onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.product} id="product" name="product" placeholder="Product" onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.minPrice} id="minPrice" name="minPrice" placeholder="MinPrice" onChange={onChangeHandler} />
                                    </div>

                                </div>
                            </div>
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.maxPrice} id="maxPrice" name="maxPrice" placeholder="Max Price " onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.productHighlights} id="productHighlights" name="productHighlights" placeholder="Product Highlights" onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.assetInfo} id="assetInfo" name="assetInfo" placeholder="Asset Info" onChange={onChangeHandler} />
                                    </div>

                                </div>
                            </div>
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.companyInfo} id="companyInfo" name="companyInfo" placeholder="Company Info" onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.productVideo} id="productVideo" name="productVideo" placeholder="Product Video" onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.developerDetails} id="developerDetails" name="developerDetails" placeholder="Developer Details" onChange={onChangeHandler} />
                                    </div>

                                </div>

                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.industry} id="industry" name="industry" placeholder="Industry" onChange={onChangeHandler} />
                                    </div>

                                </div>

                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.sector} id="sector" name="sector" placeholder="sector" onChange={onChangeHandler} />
                                    </div>

                                </div>

                            </div>
                            <div style={{marginBottom:25}}>
                                <label>Investment Type</label>
                            <Select options={optionsInvestmentType} onChange={(value) => {
                                console.log("property type option", value)
                                setInvestmentType(value.value)
                            }} />
                            </div>


                            {/* <h6>Project Video</h6>
                            <div className="input-item input-item-name ">
                                <input type="text" id="projectVideo" value={projectData.projectVideo} name="projectVideo" placeholder="video url" onChange={onChangeHandler} />
                            </div> */}

                            {/* <input type="file" id="projectVideo" value={projectData.projectVideo} name="projectVideo" className="btn theme-btn-3 mb-10" onChange={onChangeHandler}/><br /> */}




                            {/* <h6>Location</h6>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.locationMap} id="locationMap" name="locationMap" placeholder="Location link" onChange={onChangeHandler} />
                                    </div>
                                </div>

                            </div> */}
                            <div className="row">
                                <div className="col-md-6"><h6>Product Picture</h6></div>
                                <div className="col-md-6">

                                    <svg onClick={handleAddPictureUrl} xmlns="http://www.w3.org/2000/svg" height='32px' width='32px' viewBox="0 0 24 24" style={{ cursor: "pointer", fill: "none", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2px" }}>
                                        <g id="_8.add" data-name="8.add">
                                            <circle class="cls-1" cx="12" cy="12" r="11" />
                                            <line class="cls-1" x1="12" y1="6" x2="12" y2="18" />
                                            <line class="cls-1" x1="18" y1="12" x2="6" y2="12" />
                                        </g>
                                    </svg>
                                    {/* <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="button" onClick={handleRemovePictureUrl}>ADD </button> */}
                                </div>
                            </div>


                            {
                                productPicture.map((url, index) => {
                                    console.log('changed url')
                                    return (<div className="row">

                                        <div className="col-md-6">
                                            <input type="text" index={index} value={url.url} id={url.id} name={url.id} placeholder="picture link" onChange={(e) => onChangePictureHandler(e, url.id)} />
                                        </div>
                                        <div className="col-md-6" >
                                            <svg onClick={() => handleRemovePictureUrl(index, url.id)} index={index} xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} height='32px' width='32px' viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-minus-circle">
                                                <circle index={index} cx="12" cy="12" r="10"></circle>
                                                <line index={index} x1="8" y1="12" x2="16" y2="12"></line>
                                            </svg>

                                        </div>

                                    </div>
                                    )
                                })
                            }


                            <div className="row">
                                <div className="col-md-6"><h6>similar Products</h6></div>
                                <div className="col-md-6">

                                    <svg onClick={handleAddSimilarProducts} xmlns="http://www.w3.org/2000/svg" height='32px' width='32px' viewBox="0 0 24 24" style={{ cursor: "pointer", fill: "none", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2px" }}>
                                        <g id="_8.add" data-name="8.add">
                                            <circle class="cls-1" cx="12" cy="12" r="11" />
                                            <line class="cls-1" x1="12" y1="6" x2="12" y2="18" />
                                            <line class="cls-1" x1="18" y1="12" x2="6" y2="12" />
                                        </g>
                                    </svg>
                                    {/* <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="button" onClick={handleRemovePictureUrl}>ADD </button> */}
                                </div>
                            </div>


                            {
                                similarProduct.map((url, index) => {
                                    console.log('changed url')
                                    return (<div className="row">

                                        <div className="col-md-6">
                                            <input type="text" index={index} value={url.url} id={url.id} name={url.id} placeholder="product link" onChange={(e) => onChangeSimilarProductsHandler(e, url.id)} />
                                        </div>
                                        <div className="col-md-6" >
                                            <svg onClick={() => handleRemoveSimilarProducts(index, url.id)} index={index} xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} height='32px' width='32px' viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-minus-circle">
                                                <circle index={index} cx="12" cy="12" r="10"></circle>
                                                <line index={index} x1="8" y1="12" x2="16" y2="12"></line>
                                            </svg>

                                        </div>

                                    </div>
                                    )
                                })
                            }





                            <div className="btn-wrapper text-center mt-30">
                                <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="button" onClick={submitProjectDataHandler}>Submit </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AddListingAIF



