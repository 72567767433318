import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Social extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__social-media">
			<ul>
				<li><a href="https://m.facebook.com/100088132861419/" target="_blank" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
				<li><a href="https://twitter.com/Invest_Market_?s=20&t=Hpsnqtg8Q0hvBzGgfR9U4A" target="_blank" title="Twitter"><i className="fab fa-twitter" /></a></li>
				<li><a href="https://instagram.com/invest.market_?igshid=Nzg3NjI1NGI=" target="_blank" title="Instagram"><i className="fab fa-instagram" /></a></li>
				{/* <li><a href="https://www.dribble.com/tuna-theme" title="Dribbble"><i className="fab fa-dribbble" /></a></li> */}
			</ul>
		</div>
        }
}

export default Social