import React from 'react';
import { Parallax } from 'react-parallax';
import Navbar from './components/global-components/navbar';
import Footer from './components/global-components/footer';
// import Footer from './global-components/footer';
// import bgPhoto from "/assets/img/banner/2.jpg"
const AboutUs = () => {
    const insideStyles = {
        background: "white",
        padding: 20,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)"
    };

    const image1 =
        "https://images.unsplash.com/photo-1498092651296-641e88c3b057?auto=format&fit=crop&w=1778&q=60&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D";
    const image2 =
        "https://img00.deviantart.net/2bd0/i/2009/276/c/9/magic_forrest_wallpaper_by_goergen.jpg";
    const image3 =
        "https://brightcove04pmdo-a.akamaihd.net/5104226627001/5104226627001_5297440765001_5280261645001-vs.jpg?pubId=5104226627001&videoId=5280261645001";
    const image4 =
        "https://images.fineartamerica.com/images/artworkimages/mediumlarge/1/empire-state-building-black-and-white-square-format-john-farnan.jpg";

    return (
        <div>
            <Navbar />
            <div style={{ backgroundColor: "#2b4742" }}>
                <div style={{ textAlign: "center", paddingTop: 55, color: "#fff", fontSize: 45, fontWeight: 600 }}>
                    About
                </div>
                <p style={{ textAlign: "center", color: "#fff", marginBottom: 25 }}>
                    We are best in class portfolio consultancy providers who not only <br /> guide you for the financial goals but also ensure the <br /> protection under all the kind of uncertainty.


                </p>
                <div className='container' style={{ marginTop: 40 }}>
                    <div className='row' style={{ marginTop: 70, marginLeft: 70, marginRight: 70 }}>
                        <div className='col-lg-6' >
                            <p style={{ borderTop: "2px solid ", color: "#fff", fontWeight: 500, fontSize: 25 }}> Vision</p>
                            <p style={{ color: "#fff" }}>
                                We want to democratize the financial products which are available to the big fund managers and to make it accessible to our customers.

                            </p>
                        </div>

                        <div className='col-lg-6'>
                            <p style={{ borderTop: "2px solid ", color: "#fff", fontWeight: 500, fontSize: 25 }}> Our Philosophy</p>
                            <p style={{ color: "#fff" }}>Our company is based on the trust and passion to outperform. </p>
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: 70, paddingBottom: 70, marginLeft: 70, marginRight: 70 }}>
                        <div className='col-lg-6'>
                            <p style={{ borderTop: "2px solid ", color: "#fff", fontWeight: 500, fontSize: 25 }}> Our Commitment to Results</p>
                            <p style={{ color: "#fff" }}>
                                We are a result oriented company to match your short term financial goals and long term financial goals.

                            </p>
                        </div>

                        <div className='col-lg-6'>
                            <p style={{ borderTop: "2px solid ", color: "#fff", fontWeight: 500, fontSize: 25 }}> Our Experience</p>
                            <p style={{ color: "#fff" }}>Our core team comprises of various financial backgrounds with sound knowledge in the given field of experience to make your portfolio stay strong at all times.

                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Parallax
                bgImage={image4}
                strength={200}
                renderLayer={(percentage) => (
                    <div>
                        <div
                            style={{
                                position: "absolute",
                                // background: `rgba(255, 125, 0, ${percentage * 1})`,
                                left: "50%",
                                top: "50%",
                                borderRadius: "50%",
                                transform: "translate(-50%,-50%)",
                                width: percentage * 500,
                                height: percentage * 500
                            }}
                        />
                    </div>
                )}
            >
                <div style={{ height: 500 }}>
                    <div ></div>
                </div>
            </Parallax>
            <div style={{ backgroundColor: "#2b4742" }}>
                <div style={{ textAlign: "center", paddingTop: 55, color: "#fff", fontSize: 45, fontWeight: 400 }}>
                    The Team
                </div>
                <p style={{ textAlign: "center", color: "#fff", marginBottom: 25, fontSize: 15 }}>
                    The team contains of passionate individuals who strive to bring the best in the<br /> industry, everyday.


                </p>
                <div className='container' style={{ marginTop: 40 }}>
                    <div className='row' style={{ marginTop: 70, marginLeft: 70, marginRight: 70 }}>
                        <div className='col-lg-5 aboutPara'  >
                            <p style={{ color: "#fff", fontWeight: 500, fontSize: 18 }} > Bhargav Kamesh Vontethu
                                CEO </p>
                            <p style={{ color: "#fff" }}>

                                He had completed his PGDM from IMT Ghaziabad. He has more than three years of experience in portfolio management, real estate, private equities and team management .
                            </p>
                            <p>
                            </p>
                            <p>
                            </p>
                            <p>
                            </p>
                            <span style={{ color: "#fff", fontWeight: 500, fontSize: 15 }}>Phone: +971-581197123

                            </span>
                            <hr style={{ marginTop: 10, marginBottom: 10 }} />
                            <span style={{ color: "#fff", fontWeight: 500, fontSize: 15 }}>Mail: bhargav_vontethu@yahoo.com

                            </span>
                        </div>

                        <div className='col-lg-7'>
                            <img src='https://static.wixstatic.com/media/0c2d11194b8042ca9f11f7f681339102.jpg/v1/fill/w_588,h_717,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/0c2d11194b8042ca9f11f7f681339102.jpg' alt='About Us Image' style={{ marginBottom: 70 }} />
                        </div>
                    </div>
                    {/* <div className='row' style={{ marginTop: 70, paddingBottom: 70, marginLeft: 70, marginRight: 70 }}>
                        <div className='col-lg-6'>
                            <p style={{ borderTop: "2px solid ", color: "#fff", fontWeight: 500, fontSize: 25 }}> Our Commitment to Results</p>
                            <p style={{ color: "#fff" }}>
                                We are a result oriented company to match your short term financial goals and long term financial goals.

                            </p>
                        </div>

                        <div className='col-lg-6'>
                            <p style={{ borderTop: "2px solid ", color: "#fff", fontWeight: 500, fontSize: 25 }}> Our Experience</p>
                            <p style={{ color: "#fff" }}>Our core team comprises of various financial backgrounds with sound knowledge in the given field of experience to make your portfolio stay strong at all times.

                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
            <Footer />
        </div>

    );
}

export default AboutUs;
