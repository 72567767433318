import React, { Component, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import csc from "country-state-city";
import { useFormik } from "formik";
import Select from "react-select";
// import axios from 'axios';
import RootContext from '../../provider/RootProvider';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import { Axios } from 'axios';
var axios = require('axios');
const BannerV5 = () => {
	const { fetchedAIFData, setFetchedAIFData } = useContext(RootContext);
	const [companyName, setCompanyName] = useState('');
	const [industry, setIndustry] = useState('');
	const [sector, setSector] = useState('');
	const [investmentType, setInvestmentType] = useState('')
	const [sectorValues, setSectorValues] = useState([])
	const [recommendedProduct, setRecommendedProduct] = useState([])
	const navigate = useNavigate();
	useEffect(() => {

		fetch("https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/promotedProducts").then(response => response.json())
			.then(data => {
				console.log("data real State", JSON.parse(data.body))
				setRecommendedProduct(JSON.parse(data.body))
			}).catch(e => console.log("Error aif search field", e));

		// let config = {
		// 	method: 'get',
		// 	url: `https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/promotedProducts`,
		// 	headers: {
		// 		"Access-Control-Allow-Headers": "*",
		// 		"Content-Type": "application/json",
		// 		"Access-Control-Allow-Origin": "*"
		// 	}
		// };
		// axios(config)
		// 	.then(function (response) {
		// 		console.log(JSON.stringify(response.data));
		// 		setRecommendedProduct(response.data.data)
		// 	})
		// 	.catch(function (error) {
		// 		console.log(error);
		// 	});


	}, [])
	let publicUrl = process.env.PUBLIC_URL + '/';
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
			slidesToSlide: 3 // optional, default to 1.
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 3,
			slidesToSlide: 3// optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1 // optional, default to 1.
		}
	};



	const optionsIndustry = [
		{ value: '3- D & Virtual Reality', label: '3- D & Virtual Reality' },
		{ value: 'Auto- Tech/ Ancillaries', label: 'Auto- Tech/ Ancillaries' },
		{ value: 'Airlines- Aerospace & Defence', label: 'Airlines- Aerospace & Defence' },
		{ value: 'Agriculuture- Agri Tech', label: 'Agriculuture- Agri Tech' },
		{ value: 'Amusement Parks/ Recreation/Club', label: 'Amusement Parks/ Recreation/Club' },
		{ value: 'Batteries, Breweries & Distilleries', label: 'Batteries, Breweries & Distilleries' },
		{ value: 'Blockchain', label: 'Blockchain' },
		{ value: 'Cable & D2H, Cables', label: 'Cable & D2H, Cables' },
		{ value: 'Chemicals', label: 'Chemicals' },
		{ value: 'Ciggarettes/ Tobacco', label: 'Ciggarettes/ Tobacco' },
		{ value: 'Construction & Real Estate - Tech', label: 'Construction & Real Estate - Tech' },
		{ value: 'Consumer Tech & Food', label: 'Consumer Tech & Food' },
		{ value: 'Data & Data Security', label: 'Data & Data Security' },
		{ value: 'Diamond & Jewellery- Online', label: 'Diamond & Jewellery- Online' },
		{ value: 'Education & Ed-Tech', label: 'Education & Ed-Tech' },
		{ value: 'Electric equipments & Electronics', label: 'Electric equipments & Electronics' },
		{ value: 'Energy', label: 'Energy' },
		{ value: 'Enterprise Applications', label: 'Enterprise Applications' },
		{ value: 'Finance,Fintech & Insurtech', label: 'Finance,Fintech & Insurtech' },
		{ value: 'Technology', label: 'Technology' },
		{ value: 'Food & Food Tech', label: 'Food & Food Tech' },
		{ value: 'Fashion- Tech  & Footwear', label: 'Fashion- Tech  & Footwear' },
		{ value: 'Home Tech', label: 'Home Tech' },
		{ value: 'Hospital & Healthcare', label: 'Hospital & Healthcare' },
		{ value: 'Hotel, Resort & Restaurants', label: 'Hotel, Resort & Restaurants' },
		{ value: 'HR Tech', label: 'HR Tech' },
		{ value: 'Industrial Goods, Manufacturing- Tech & Infrastructure', label: 'Industrial Goods, Manufacturing- Tech & Infrastructure' },
		{ value: 'IT & Robotics', label: 'IT & Robotics' },
		{ value: 'ITES & Internet of Things', label: 'ITES & Internet of Things' },
		{ value: 'Logistics', label: 'Logistics' },
		{ value: 'LPG', label: 'LPG' },
		{ value: 'Marketing and Sales -Tech', label: 'Marketing and Sales -Tech' },
		{ value: 'Media, Entertainment & Travel', label: 'Media, Entertainment & Travel' },
		{ value: 'Medical & Medical Tech', label: 'Medical & Medical Tech' },
		{ value: 'Metals', label: 'Metals' },
		{ value: 'Others- Trending Themes', label: 'Others- Trending Themes' },
		{ value: 'Oil Exploration & production', label: 'Oil Exploration & production' },
		{ value: 'Online Services- Fitness, Gig Economy, Gaming & Wedding Tech', label: 'Online Services- Fitness, Gig Economy, Gaming & Wedding Tech' },
		{ value: 'Pacakging', label: 'Pacakging' },
		{ value: 'Paints', label: 'Paints' },
		{ value: 'Paper, Paper Products & printing', label: 'Paper, Paper Products & printing' },
		{ value: 'Plastics', label: 'Plastics' },
		{ value: 'Ports & Shipping', label: 'Ports & Shipping' },
		{ value: 'Real Estate', label: 'Real Estate' },
		{ value: 'Retail', label: 'Retail' },
		{ value: 'Semi- Conductors & Artificial Intelligence', label: 'Semi- Conductors & Artificial Intelligence' },
		{ value: 'Tech', label: 'Tech' },
		{ value: 'Telecom, Mobile & Tele-Communication', label: 'Telecom, Mobile & Tele-Communication' },
		{ value: 'Textiles', label: 'Textiles' },
		{ value: 'TV & Web Broadcasting', label: 'TV & Web Broadcasting' },
		{ value: 'Wood', label: 'Wood' },
	]

	const optionsInvestmentType = [
		{ value: 'ProductEquity', label: 'Product Equity' },
		{ value: 'BrandFranchise', label: 'Brand franchise' },
		{ value: 'DirectEquity', label: 'Direct Equity' },
		{ value: 'CorporateDebtBonds', label: 'Corporate Debt Bonds' },
	]

	var optionSector;

	useEffect(() => {
		console.log("inside use effect")
		if (industry == "3- D & Virtual Reality") {
			console.log("inside use effect 1", industry)
			optionSector = [{ value: ' 3- D Tech ', label: ' 3- D Tech ' },
			{ value: ' 3D Cameras ', label: ' 3D Cameras ' },
			{ value: ' Standalone VR Headsets ', label: ' Standalone VR Headsets ' },
			{ value: ' AR VR Device Controllers ', label: ' AR VR Device Controllers ' },
			{ value: ' VR Social Experiences ', label: ' VR Social Experiences ' },
			{ value: ' Location Based VR ', label: ' Location Based VR ' },
			{ value: ' Sensors for Industrial Equipment Monitoring ', label: ' Sensors for Industrial Equipment Monitoring ' },
			{ value: ' Gas Sensors ', label: ' Gas Sensors ' },
			{ value: ' 3D Sensors ', label: ' 3D Sensors ' },
			{ value: ' Construction Collaboration ', label: ' Construction Collaboration ' },
			{ value: ' 3D Visualization Tools ', label: ' 3D Visualization Tools ' },
			{ value: ' Construction 3D Printing ', label: ' Construction 3D Printing ' },
			{ value: ' Construction Project Management ', label: ' Construction Project Management ' },
			{ value: ' Modular Housing ', label: ' Modular Housing ' },
			{ value: ' BIM ', label: ' BIM ' },
			{ value: ' Building Materials E-Commerce ', label: ' Building Materials E-Commerce ' },
			{ value: ' Heavy Equipment Rentals ', label: ' Heavy Equipment Rentals ' },
			{ value: ' Circular Building Materials ', label: ' Circular Building Materials ' },
			{ value: ' Energy Efficient Materials ', label: ' Energy Efficient Materials ' },
			{ value: ' Bio Based Construction Materials ', label: ' Bio Based Construction Materials ' },
			{ value: ' Augmented Reality in Field Service ', label: ' Augmented Reality in Field Service ' },
			{ value: ' Augmented Reality Development Tools ', label: ' Augmented Reality Development Tools ' },
			{ value: ' Heads Up Displays ', label: ' Heads Up Displays ' },
			{ value: ' Holographic Displays ', label: ' Holographic Displays ' },
			{ value: ' 3D Cameras ', label: ' 3D Cameras ' },
			{ value: ' 3D Printing in Healthcare ', label: ' 3D Printing in Healthcare ' },
			{ value: ' 3D Printing in Construction ', label: ' 3D Printing in Construction ' },
			{ value: ' 3D Printing in Retail ', label: ' 3D Printing in Retail ' },
			{ value: ' 3D Printing in Fashion ', label: ' 3D Printing in Fashion ' },
			{ value: ' 3D Scanning in Real Estate ', label: ' 3D Scanning in Real Estate ' },
			{ value: ' 3D Cameras ', label: ' 3D Cameras ' },
			{ value: ' 3D Body Scanners ', label: ' 3D Body Scanners ' },
			{ value: ' 3D Bio Printers ', label: ' 3D Bio Printers ' },
			{ value: ' Industrial 3D Printers ', label: ' Industrial 3D Printers ' },
			{ value: ' 3D Printer Filaments ', label: ' 3D Printer Filaments ' },
			{ value: ' Metal Powders for 3D Printing ', label: ' Metal Powders for 3D Printing ' },
			{ value: ' 3D Printing Post Processing ', label: ' 3D Printing Post Processing ' },
			{ value: ' 3D Print Management Software ', label: ' 3D Print Management Software ' },
			{ value: '3D Print Management Software', label: '3D Print Management Software' }]
		} else if (industry == "Auto- Tech/ Ancillaries") {
			optionSector = [{ value: ' Auto Tech ', label: ' Auto Tech ' },
			{ value: ' Auto mobiles ', label: ' Auto mobiles ' },
			{ value: ' Auto Ancillaries ', label: ' Auto Ancillaries ' },
			{ value: ' Electric Vehicles ', label: ' Electric Vehicles ' },
			{ value: ' Flying Cars ', label: ' Flying Cars ' },
			{ value: ' Hyperloop ', label: ' Hyperloop ' },
			{ value: ' Auto E-Commerce & Content ', label: ' Auto E-Commerce & Content ' },
			{ value: ' Road Transport Tech ', label: ' Road Transport Tech ' },
			{ value: ' Auto IT ', label: ' Auto IT ' },
			{ value: ' Autonomous Vehicles ', label: ' Autonomous Vehicles ' },
			{ value: ' Connected Vehicles ', label: ' Connected Vehicles ' },
			{ value: ' Automotive FinTech ', label: ' Automotive FinTech ' },
			{ value: ' Off Highway Vehicles Tech ', label: ' Off Highway Vehicles Tech ' },
			{ value: ' Electric Cars ', label: ' Electric Cars ' },
			{ value: ' Electric Scooter Manufacturers ', label: ' Electric Scooter Manufacturers ' },
			{ value: ' Electric Vehicle Charging ', label: ' Electric Vehicle Charging ' },
			{ value: ' Electric Two Wheeler Rentals ', label: ' Electric Two Wheeler Rentals ' },
			{ value: ' Electric Trucks ', label: ' Electric Trucks ' },
			{ value: ' Electric Motorcycles ', label: ' Electric Motorcycles ' },
			{ value: ' Shuttle Service ', label: ' Shuttle Service ' },
			{ value: ' Cab Ride-Hailing ', label: ' Cab Ride-Hailing ' },
			{ value: ' Bike Taxi ', label: ' Bike Taxi ' },
			{ value: ' Bicycle Rentals ', label: ' Bicycle Rentals ' },
			{ value: ' Online Car Rentals ', label: ' Online Car Rentals ' },
			{ value: ' OnDemand Valet ', label: ' OnDemand Valet ' },
			{ value: ' Parking Spot Booking ', label: ' Parking Spot Booking ' },
			{ value: ' In-Flight Entertainment ', label: ' In-Flight Entertainment ' },
			{ value: ' Multimodal Transport Apps ', label: ' Multimodal Transport Apps ' },
			{ value: ' Mobility as a Service ', label: ' Mobility as a Service ' },
			{ value: ' Predictive Maintenance for Railways ', label: ' Predictive Maintenance for Railways ' },
			{ value: ' Electric Two Wheeler Rentals ', label: ' Electric Two Wheeler Rentals ' },
			{ value: ' Two Wheeler Rentals ', label: ' Two Wheeler Rentals ' },
			{ value: ' Smart Parking Management ', label: ' Smart Parking Management ' },
			{ value: ' Fuel Delivery ', label: ' Fuel Delivery ' },
			{ value: ' Automobile Repair ', label: ' Automobile Repair ' },
			{ value: ' Internet First Auto Finance ', label: ' Internet First Auto Finance ' },
			{ value: ' Usage Based Auto Insurance ', label: ' Usage Based Auto Insurance ' },
			{ value: ' Online Auto Parts and Accessories ', label: ' Online Auto Parts and Accessories ' },
			{ value: ' Car Subscription Service ', label: ' Car Subscription Service ' },
			{ value: ' Second Hand Cars E-Commerce ', label: ' Second Hand Cars E-Commerce ' },
			{ value: ' P2P Second Hand Cars Marketplace ', label: ' P2P Second Hand Cars Marketplace ' },
			{ value: ' B2B Second Hand Cars Marketplaces ', label: ' B2B Second Hand Cars Marketplaces ' },
			{ value: ' Online New Cars Retail ', label: ' Online New Cars Retail ' },
			{ value: ' Auto Dealership Management ', label: ' Auto Dealership Management ' },
			{ value: ' CRM for Auto Dealers ', label: ' CRM for Auto Dealers ' },
			{ value: ' Auto Workshop Management ', label: ' Auto Workshop Management ' },
			{ value: ' Connected Car Platforms ', label: ' Connected Car Platforms ' },
			{ value: ' V2X ', label: ' V2X ' },
			{ value: ' Telematics Based Fleet Management ', label: ' Telematics Based Fleet Management ' },
			{ value: ' ADAS ', label: ' ADAS ' },
			{ value: ' Connected Motorcycles ', label: ' Connected Motorcycles ' },
			{ value: ' Smart Dash Cams ', label: ' Smart Dash Cams ' },
			{ value: ' Automotive Cybersecurity ', label: ' Automotive Cybersecurity ' },
			{ value: ' In Vehicle Infotainment Systems ', label: ' In Vehicle Infotainment Systems ' },
			{ value: ' Automotive Heads Up Displays ', label: ' Automotive Heads Up Displays ' },
			{ value: ' C2B Second Hand Cars Marketplaces ', label: ' C2B Second Hand Cars Marketplaces ' },
			{ value: ' Internet First Auto Insurers ', label: ' Internet First Auto Insurers ' },
			{ value: ' Internet First Automotive Media ', label: ' Internet First Automotive Media ' },
			{ value: ' Collision Avoidance ', label: ' Collision Avoidance ' },
			{ value: ' Autonomous Vehicles Mapping ', label: ' Autonomous Vehicles Mapping ' },
			{ value: ' Autonomous Vehicles LIDAR ', label: ' Autonomous Vehicles LIDAR ' },
			{ value: ' Self Driving Cars ', label: ' Self Driving Cars ' },
			{ value: ' Autonomous Delivery Vehicles ', label: ' Autonomous Delivery Vehicles ' },
			{ value: ' Self Driving Trucks ', label: ' Self Driving Trucks ' },
			{ value: ' Driving Simulation Platforms ', label: ' Driving Simulation Platforms ' },
			{ value: ' Autonomous Tractors ', label: ' Autonomous Tractors ' },
			{ value: ' Auto Components ', label: ' Auto Components ' },
			{ value: ' Auto Brands ', label: ' Auto Brands ' },
			{ value: ' Off Highway Vehicles ', label: ' Off Highway Vehicles ' },
			{ value: 'Off Highway Vehicles', label: 'Off Highway Vehicles' }]
		} else if (industry == "Airlines- Aerospace & Defence") {
			optionSector = [{ value: ' Airlines ', label: ' Airlines ' },
			{ value: ' Aerospace ', label: ' Aerospace ' },
			{ value: ' Defense ', label: ' Defense ' },
			{ value: ' Drone Defense Systems ', label: ' Drone Defense Systems ' },
			{ value: ' Consumer Drones ', label: ' Consumer Drones ' },
			{ value: ' Drone Peripherals ', label: ' Drone Peripherals ' },
			{ value: ' Military Drones ', label: ' Military Drones ' },
			{ value: ' Commercial Drones ', label: ' Commercial Drones ' },
			{ value: ' Aerial Survey Drones ', label: ' Aerial Survey Drones ' },
			{ value: ' Drone Delivery ', label: ' Drone Delivery ' },
			{ value: ' Industrial Inspection Using Drones ', label: ' Industrial Inspection Using Drones ' },
			{ value: ' Crop Management Using Drones ', label: ' Crop Management Using Drones ' },
			{ value: ' Drone Software ', label: ' Drone Software ' },
			{ value: ' Drone Fleet Management ', label: ' Drone Fleet Management ' },
			{ value: ' Drone Flight Planning ', label: ' Drone Flight Planning ' },
			{ value: ' Military Tech ', label: ' Military Tech ' },
			{ value: ' NewSpace ', label: ' NewSpace ' },
			{ value: ' Aerospace Tech ', label: ' Aerospace Tech ' },
			{ value: ' Maritime Tech ', label: ' Maritime Tech ' },
			{ value: ' Drones ', label: ' Drones ' },
			{ value: ' Aviation IT ', label: ' Aviation IT ' },
			{ value: ' Avionics ', label: ' Avionics ' },
			{ value: ' Electric Aircraft ', label: ' Electric Aircraft ' },
			{ value: ' Electric Vertical Takeoff and Landing Aircraft ', label: ' Electric Vertical Takeoff and Landing Aircraft ' },
			{ value: ' Supersonic Aircraft ', label: ' Supersonic Aircraft ' },
			{ value: ' Drone Defense Systems ', label: ' Drone Defense Systems ' },
			{ value: ' Unmanned Surveillance Vehicles ', label: ' Unmanned Surveillance Vehicles ' },
			{ value: ' Large Satellite Launch Vehicle ', label: ' Large Satellite Launch Vehicle ' },
			{ value: ' Small Satellite Launch Vehicle ', label: ' Small Satellite Launch Vehicle ' },
			{ value: ' Small Satellite Manufacturers ', label: ' Small Satellite Manufacturers ' },
			{ value: ' Spacecraft ', label: ' Spacecraft ' },
			{ value: ' In space Shuttle Services ', label: ' In space Shuttle Services ' },
			{ value: ' Satellite Imagery Services ', label: ' Satellite Imagery Services ' },
			{ value: ' Satellite based Weather Forecasting Services ', label: ' Satellite based Weather Forecasting Services ' },
			{ value: ' Satellite Communication Antenna System ', label: ' Satellite Communication Antenna System ' },
			{ value: ' Ground Station Network ', label: ' Ground Station Network ' },
			{ value: ' Space Launch and Mission Management Services ', label: ' Space Launch and Mission Management Services ' },
			{ value: ' Satellite Propulsion System ', label: ' Satellite Propulsion System ' },
			{ value: ' Satellite Communication Services ', label: ' Satellite Communication Services ' },
			{ value: 'Satellite Communication Services', label: 'Satellite Communication Services' }]
		} else if (industry == "Agriculuture- Agri Tech") {
			optionSector = [{ value: ' Agriculuture ', label: ' Agriculuture ' },
			{ value: ' Crop Management Using Drones ', label: ' Crop Management Using Drones ' },
			{ value: ' Smart Agriculture LED Lights ', label: ' Smart Agriculture LED Lights ' },
			{ value: ' Sustainable Animal And Fish Feed ', label: ' Sustainable Animal And Fish Feed ' },
			{ value: ' Autonomous Farming ', label: ' Autonomous Farming ' },
			{ value: ' Farm Inputs E-Commerce ', label: ' Farm Inputs E-Commerce ' },
			{ value: ' Farm Analytics ', label: ' Farm Analytics ' },
			{ value: ' Crop Biologicals ', label: ' Crop Biologicals ' },
			{ value: ' Plant Breeding ', label: ' Plant Breeding ' },
			{ value: ' GMO and Hybrid Seeds ', label: ' GMO and Hybrid Seeds ' },
			{ value: ' Farm Management Software ', label: ' Farm Management Software ' },
			{ value: ' Indoor Farming ', label: ' Indoor Farming ' },
			{ value: ' Aeroponics ', label: ' Aeroponics ' },
			{ value: ' Aquaponics ', label: ' Aquaponics ' },
			{ value: ' Hydroponics ', label: ' Hydroponics ' },
			{ value: ' Farm Produce Preservation ', label: ' Farm Produce Preservation ' },
			{ value: ' Agriculture Biotechnology ', label: ' Agriculture Biotechnology ' },
			{ value: ' Precision Farming ', label: ' Precision Farming ' },
			{ value: ' Autonomous Tractors ', label: ' Autonomous Tractors ' },
			{ value: ' Sustainable Farming ', label: ' Sustainable Farming ' },
			{ value: ' B2C Farm Produce E-Commerce ', label: ' B2C Farm Produce E-Commerce ' },
			{ value: ' Food and Beverage Analytics ', label: ' Food and Beverage Analytics ' },
			{ value: ' Plant Based Food Ingredients ', label: ' Plant Based Food Ingredients ' },
			{ value: ' Food Tech ', label: ' Food Tech ' },
			{ value: ' Livestock Tech ', label: ' Livestock Tech ' },
			{ value: ' Crop Tech ', label: ' Crop Tech ' },
			{ value: ' Aquaculture Tech ', label: ' Aquaculture Tech ' },
			{ value: ' Forestry Tech ', label: ' Forestry Tech ' },
			{ value: ' Insect Farming Tech ', label: ' Insect Farming Tech ' },
			{ value: ' Agri FinTech ', label: ' Agri FinTech ' },
			{ value: ' AgriTech ', label: ' AgriTech ' },
			{ value: 'AgriTech', label: 'AgriTech' }]
		} else if (industry == "Amusement Parks/ Recreation/Club") {
			optionSector = [{ value: 'Amusement Parks', label: ' Amusement Parks' },
			{ value: 'Recreation', label: 'Recreation' },
			{ value: 'Club', label: 'Club' },]
		} else if (industry == "Banking & Banking Tech") {
			optionSector = [{ value: ' Banking ', label: ' Banking ' },
			{ value: ' Private ', label: ' Private ' },
			{ value: ' Public ', label: ' Public ' },
			{ value: ' Conversational Banking ', label: ' Conversational Banking ' },
			{ value: ' Internet First Banks ', label: ' Internet First Banks ' },
			{ value: ' Credit Assessment ', label: ' Credit Assessment ' },
			{ value: ' Loan Origination ', label: ' Loan Origination ' },
			{ value: ' Open Banking ', label: ' Open Banking ' },
			{ value: ' Banking as a Service ', label: ' Banking as a Service ' },
			{ value: ' Cloud Banking ', label: ' Cloud Banking ' },
			{ value: ' Dispute Resolution ', label: ' Dispute Resolution ' },
			{ value: ' IP Management Software ', label: ' IP Management Software ' },
			{ value: ' Brand Protection Software ', label: ' Brand Protection Software ' },
			{ value: ' Legal Consultation Platforms ', label: ' Legal Consultation Platforms ' },
			{ value: ' Legal Marketplace ', label: ' Legal Marketplace ' },
			{ value: ' Legal Contract Management ', label: ' Legal Contract Management ' },
			{ value: ' Legal Practice Management ', label: ' Legal Practice Management ' },
			{ value: ' Legal Research Software ', label: ' Legal Research Software ' },
			{ value: ' eDiscovery Software ', label: ' eDiscovery Software ' },
			{ value: ' Legal Analytics ', label: ' Legal Analytics ' },
			{ value: ' Algo Trading Platforms ', label: ' Algo Trading Platforms ' },
			{ value: ' Asset Tokenization ', label: ' Asset Tokenization ' },
			{ value: ' Equity Crowdfunding Platforms ', label: ' Equity Crowdfunding Platforms ' },
			{ value: ' Private Funds Investment Platforms ', label: ' Private Funds Investment Platforms ' },
			{ value: ' Real Estate Investment Platforms ', label: ' Real Estate Investment Platforms ' },
			{ value: ' High Frequency Trading Infrastructure ', label: ' High Frequency Trading Infrastructure ' },
			{ value: ' Trade Management Software ', label: ' Trade Management Software ' },
			{ value: ' Online Financial Databases ', label: ' Online Financial Databases ' },
			{ value: ' Alternative Trading Systems ', label: ' Alternative Trading Systems ' },
			{ value: ' Fixed Income Trading Platforms ', label: ' Fixed Income Trading Platforms ' },
			{ value: ' Forex Trading Platforms ', label: ' Forex Trading Platforms ' },
			{ value: ' Mutual Funds Investment Platforms ', label: ' Mutual Funds Investment Platforms ' },
			{ value: ' Equity Research Platforms ', label: ' Equity Research Platforms ' },
			{ value: ' Discount Stock Brokers ', label: ' Discount Stock Brokers ' },
			{ value: ' Private Fund Portfolio Management ', label: ' Private Fund Portfolio Management ' },
			{ value: ' Wealth Management Software ', label: ' Wealth Management Software ' },
			{ value: ' Expense Aggregation Platforms ', label: ' Expense Aggregation Platforms ' },
			{ value: ' Online Financial Planners and Advisors ', label: ' Online Financial Planners and Advisors ' },
			{ value: ' ESG Investing ', label: ' ESG Investing ' },
			{ value: ' Payment Card Loyalty ', label: ' Payment Card Loyalty ' },
			{ value: ' Reward Based Loyalty ', label: ' Reward Based Loyalty ' },
			{ value: ' Loyalty Management Platform ', label: ' Loyalty Management Platform ' },
			{ value: 'Loyalty Management Platform', label: 'Loyalty Management Platform' }]
		} else if (industry == "Batteries, Breweries & Distilleries") {
			optionSector = [{ value: 'Batteries', label: 'Batteries' },
			{ value: 'Breweries', label: 'Breweries' },
			{ value: 'Distilleries', label: 'Distilleries' },]
		} else if (industry == "Blockchain") {
			optionSector = [{ value: ' Blockchain ', label: ' Blockchain ' },
			{ value: ' Blockchain in Financial Services ', label: ' Blockchain in Financial Services ' },
			{ value: ' Blockchain in Healthcare ', label: ' Blockchain in Healthcare ' },
			{ value: ' Blockchain in Retail ', label: ' Blockchain in Retail ' },
			{ value: ' Blockchain in Energy ', label: ' Blockchain in Energy ' },
			{ value: ' Blockchain in Supply Chain and Logistics ', label: ' Blockchain in Supply Chain and Logistics ' },
			{ value: ' Blockchain in Media and Entertainment ', label: ' Blockchain in Media and Entertainment ' },
			{ value: ' Blockchain IoT ', label: ' Blockchain IoT ' },
			{ value: ' Blockchain in Real Estate and Construction ', label: ' Blockchain in Real Estate and Construction ' },
			{ value: ' Blockchain in Cybersecurity ', label: ' Blockchain in Cybersecurity ' },
			{ value: ' Blockchain in Insurance ', label: ' Blockchain in Insurance ' },
			{ value: ' Blockchain in Gaming ', label: ' Blockchain in Gaming ' },
			{ value: ' Smart Contract Security ', label: ' Smart Contract Security ' },
			{ value: ' Smart Contract Development ', label: ' Smart Contract Development ' },
			{ value: ' DApp Development ', label: ' DApp Development ' },
			{ value: ' Blockchain Infrastructure ', label: ' Blockchain Infrastructure ' },
			{ value: ' Smart Contracts ', label: ' Smart Contracts ' },
			{ value: ' Blockchain Services ', label: ' Blockchain Services ' },
			{ value: ' Non Fungible Tokens ', label: ' Non Fungible Tokens ' },
			{ value: ' Cryptocurrencies ', label: ' Cryptocurrencies ' },
			{ value: ' Web 3.0 ', label: ' Web 3.0 ' },
			{ value: ' Decentralized Finance ', label: ' Decentralized Finance ' },
			{ value: ' Decentralized Autonomous Organizations ', label: ' Decentralized Autonomous Organizations ' },
			{ value: ' Crypto Banks ', label: ' Crypto Banks ' },
			{ value: ' Crypto Exchanges ', label: ' Crypto Exchanges ' },
			{ value: ' Crypto Trading Platforms ', label: ' Crypto Trading Platforms ' },
			{ value: ' Crypto Portfolio Management ', label: ' Crypto Portfolio Management ' },
			{ value: ' Crypto Lending ', label: ' Crypto Lending ' },
			{ value: ' Crypto Payments ', label: ' Crypto Payments ' },
			{ value: ' Crypto Mining ', label: ' Crypto Mining ' },
			{ value: ' Crypto Wallets ', label: ' Crypto Wallets ' },
			{ value: ' Sustainable Crypto Mining ', label: ' Sustainable Crypto Mining ' },
			{ value: ' Blockchain Application Development Platforms ', label: ' Blockchain Application Development Platforms ' },
			{ value: ' Blockchain Protocols ', label: ' Blockchain Protocols ' },
			{ value: ' Blockchain Protocols for Financial Services ', label: ' Blockchain Protocols for Financial Services ' },
			{ value: ' Blockchain Security ', label: ' Blockchain Security ' },
			{ value: ' Crypto Asset Compliance and Risk Management ', label: ' Crypto Asset Compliance and Risk Management ' },
			{ value: ' Sidechain Platforms ', label: ' Sidechain Platforms ' },
			{ value: ' Crypto Mining', label: ' Crypto Mining' }]
		} else if (industry == "Cable & D2H, Cables") {
			optionSector = [{ value: 'Cable', label: 'Cable' },
			{ value: 'D2H', label: 'D2H' },
			{ value: 'Cables', label: 'Cables' },]
		} else if (industry == "Chemicals") {
			optionSector = [{ value: ' Chemicals ', label: ' Chemicals ' },
			{ value: ' Materials Tech ', label: ' Materials Tech ' },
			{ value: ' Advanced Materials ', label: ' Advanced Materials ' },
			{ value: ' Biodegradable Polymers ', label: ' Biodegradable Polymers ' },
			{ value: ' Smart Glass Windows ', label: ' Smart Glass Windows ' },
			{ value: ' Advanced Textile Materials ', label: ' Advanced Textile Materials ' },
			{ value: ' Nanoalloys ', label: ' Nanoalloys ' },
			{ value: ' Carbon Nanotubes ', label: ' Carbon Nanotubes ' },
			{ value: ' Graphene ', label: ' Graphene ' },
			{ value: ' Quantum Dots ', label: ' Quantum Dots ' },
			{ value: ' Carbon Nanostructures ', label: ' Carbon Nanostructures ' },
			{ value: ' Biomaterials ', label: ' Biomaterials ' },
			{ value: ' Sustainable Fabrics ', label: ' Sustainable Fabrics ' },
			{ value: ' Novel Polymers ', label: ' Novel Polymers ' }]
		} else if (industry == "Ciggarettes/ Tobacco") {
			optionSector = [{ value: 'Ciggarettes', label: 'Ciggarettes' },
			{ value: 'Tobacco', label: 'Tobacco' },
			]
		} else if (industry == "Construction & Real Estate - Tech") {
			optionSector = [{ value: ' Construction & Real Estate - Tech ', label: ' Construction & Real Estate - Tech ' },
			{ value: ' Residential Real Estate Tech ', label: ' Residential Real Estate Tech ' },
			{ value: ' Facility Management Tech ', label: ' Facility Management Tech ' },
			{ value: ' Property Management Tech ', label: ' Property Management Tech ' },
			{ value: ' Commercial Real Estate Tech ', label: ' Commercial Real Estate Tech ' },
			{ value: ' Real Estate IT ', label: ' Real Estate IT ' },
			{ value: ' Real Estate Financing Tech ', label: ' Real Estate Financing Tech ' },
			{ value: ' Property Insurance Tech ', label: ' Property Insurance Tech ' },
			{ value: ' Real Estate Tech ', label: ' Real Estate Tech ' },
			{ value: ' Smart Buildings ', label: ' Smart Buildings ' },
			{ value: ' CoWorking Spaces ', label: ' CoWorking Spaces ' },
			{ value: ' CoLiving ', label: ' CoLiving ' },
			{ value: ' On Demand Commercial Space Marketplaces ', label: ' On Demand Commercial Space Marketplaces ' },
			{ value: ' Shared Kitchens ', label: ' Shared Kitchens ' },
			{ value: ' Real Estate Crowdfunding ', label: ' Real Estate Crowdfunding ' },
			{ value: ' Real Estate P2P Lending ', label: ' Real Estate P2P Lending ' },
			{ value: ' Internet First Real Estate Brokers ', label: ' Internet First Real Estate Brokers ' },
			{ value: ' Direct Home Buying ', label: ' Direct Home Buying ' },
			{ value: ' Visitor Management for Residential Communities ', label: ' Visitor Management for Residential Communities ' },
			{ value: ' Apartment Community Management Software ', label: ' Apartment Community Management Software ' },
			{ value: ' Property Portfolio Management ', label: ' Property Portfolio Management ' },
			{ value: ' Property Data and Analytics ', label: ' Property Data and Analytics ' },
			{ value: ' Property Management Suite ', label: ' Property Management Suite ' },
			{ value: ' Renters Insurance ', label: ' Renters Insurance ' },
			{ value: ' Internet First Property Rental Agency ', label: ' Internet First Property Rental Agency ' },
			{ value: ' Building Energy Management System ', label: ' Building Energy Management System ' },
			{ value: ' Commercial Energy Monitoring ', label: ' Commercial Energy Monitoring ' },
			{ value: ' Computerized Maintenance Management System ', label: ' Computerized Maintenance Management System ' },
			{ value: ' Real Estate Listings ', label: ' Real Estate Listings ' },
			{ value: ' Managed Home Rentals ', label: ' Managed Home Rentals ' },
			{ value: ' Enterprise Visitor Management ', label: ' Enterprise Visitor Management ' },
			{ value: ' Real Estate CRM Software ', label: ' Real Estate CRM Software ' },
			{ value: ' Real Estate Marketing Software ', label: ' Real Estate Marketing Software ' },
			{ value: ' Property Appraisal ', label: ' Property Appraisal ' },
			{ value: ' Property Title and Closing ', label: ' Property Title and Closing ' }
			]
		} else if (industry == "Consumer Tech & Food") {
			optionSector = [{ value: ' Consumer Tech & Food ', label: ' Consumer Tech & Food ' },
			{ value: ' Online Travel ', label: ' Online Travel ' },
			{ value: ' Road Transport Tech ', label: ' Road Transport Tech ' },
			{ value: ' Residential Real Estate Tech ', label: ' Residential Real Estate Tech ' },
			{ value: ' Auto E-Commerce & Content ', label: ' Auto E-Commerce & Content ' },
			{ value: ' Logistics Tech ', label: ' Logistics Tech ' },
			{ value: ' Communication Apps ', label: ' Communication Apps ' },
			{ value: ' Local Services ', label: ' Local Services ' },
			{ value: ' Music Tech ', label: ' Music Tech ' },
			{ value: ' Wedding Tech ', label: ' Wedding Tech ' },
			{ value: ' Matchmaking ', label: ' Matchmaking ' },
			{ value: ' Sports Tech ', label: ' Sports Tech ' },
			{ value: ' Online Gifting ', label: ' Online Gifting ' },
			{ value: ' Mobile Utility Tools ', label: ' Mobile Utility Tools ' },
			{ value: ' Mobile App Studios ', label: ' Mobile App Studios ' },
			{ value: ' Social Platforms ', label: ' Social Platforms ' },
			{ value: ' Online Photos ', label: ' Online Photos ' },
			{ value: ' Marketplaces ', label: ' Marketplaces ' },
			{ value: ' Productivity Software ', label: ' Productivity Software ' },
			{ value: ' Vernacular Platforms ', label: ' Vernacular Platforms ' },
			{ value: ' Search Engines ', label: ' Search Engines ' },
			{ value: ' Mobile App Distribution ', label: ' Mobile App Distribution ' },
			{ value: ' Fashion Tech ', label: ' Fashion Tech ' },
			{ value: ' Ratings and Review Platforms ', label: ' Ratings and Review Platforms ' },
			{ value: ' Religion Tech ', label: ' Religion Tech ' },
			{ value: ' B2C E-Commerce ', label: ' B2C E-Commerce ' },
			{ value: ' Passion Economy ', label: ' Passion Economy ' },
			{ value: ' Online Rental ', label: ' Online Rental ' },
			{ value: ' Age Tech ', label: ' Age Tech ' },
			{ value: ' Food & Beverage Products ', label: ' Food & Beverage Products ' },
			{ value: ' Alcoholic Beverage Products ', label: ' Alcoholic Beverage Products ' },
			{ value: ' Home Care Products ', label: ' Home Care Products ' },
			{ value: ' Beauty & Personal Care Products ', label: ' Beauty & Personal Care Products ' },
			{ value: ' Mobiles & Accessories ', label: ' Mobiles & Accessories ' },
			{ value: ' Apparel Brands ', label: ' Apparel Brands ' },
			{ value: ' Home Furnishing Products ', label: ' Home Furnishing Products ' },
			{ value: ' Computers & Accessories ', label: ' Computers & Accessories ' },
			{ value: ' TV & Audiovisual Products ', label: ' TV & Audiovisual Products ' },
			{ value: ' Imaging Products ', label: ' Imaging Products ' },
			{ value: ' Fashion Accessories ', label: ' Fashion Accessories ' },
			{ value: ' Baby & Kids Products ', label: ' Baby & Kids Products ' },
			{ value: ' Pet Care Products ', label: ' Pet Care Products ' },
			{ value: ' Sportswear ', label: ' Sportswear ' },
			{ value: ' Stationery & Gifting Products ', label: ' Stationery & Gifting Products ' },
			{ value: ' Diversified Consumer Electronics Brands ', label: ' Diversified Consumer Electronics Brands ' }
			]
		} else if (industry == "Data & Data Security") {
			optionSector = [{ value: ' Data ', label: ' Data ' },
			{ value: ' Anti Fraud Management ', label: ' Anti Fraud Management ' },
			{ value: ' Identity Theft Protection ', label: ' Identity Theft Protection ' },
			{ value: ' Payment Fraud Prevention ', label: ' Payment Fraud Prevention ' },
			{ value: ' RASP Security ', label: ' RASP Security ' },
			{ value: ' Vulnerability Assessment ', label: ' Vulnerability Assessment ' },
			{ value: ' BYOD Security ', label: ' BYOD Security ' },
			{ value: ' Anti Virus ', label: ' Anti Virus ' },
			{ value: ' Mobile Application Management (MAM) ', label: ' Mobile Application Management (MAM) ' },
			{ value: ' Mobile Device Management (MDM) ', label: ' Mobile Device Management (MDM) ' },
			{ value: ' Container Security ', label: ' Container Security ' },
			{ value: ' Data Security ', label: ' Data Security ' },
			{ value: ' Secure Communication ', label: ' Secure Communication ' },
			{ value: ' Email Security ', label: ' Email Security ' },
			{ value: ' Anti Phishing Software ', label: ' Anti Phishing Software ' },
			{ value: ' Endpoint Security ', label: ' Endpoint Security ' },
			{ value: ' Threat Management ', label: ' Threat Management ' },
			{ value: ' Threat Detection ', label: ' Threat Detection ' },
			{ value: ' Identity Access Management ', label: ' Identity Access Management ' },
			{ value: ' 2 Factor Authentication (2FA) ', label: ' 2 Factor Authentication (2FA) ' },
			{ value: ' Biometric Authentication ', label: ' Biometric Authentication ' },
			{ value: ' Customer Identity Access Management (CIAM) ', label: ' Customer Identity Access Management (CIAM) ' },
			{ value: ' Multi Factor Authentication (MFA) ', label: ' Multi Factor Authentication (MFA) ' },
			{ value: ' Industrial Security ', label: ' Industrial Security ' },
			{ value: ' IoT Security ', label: ' IoT Security ' },
			{ value: ' Network Security ', label: ' Network Security ' },
			{ value: ' Virtual Private Network (VPN) ', label: ' Virtual Private Network (VPN) ' },
			{ value: ' Security Analytics ', label: ' Security Analytics ' },
			{ value: ' Dark Web Monitoring ', label: ' Dark Web Monitoring ' },
			{ value: ' Threat Intelligence ', label: ' Threat Intelligence ' },
			{ value: ' Website Security ', label: ' Website Security ' },
			{ value: ' Web Application Firewall ', label: ' Web Application Firewall ' },
			{ value: ' Cloud Security ', label: ' Cloud Security ' },
			{ value: ' Customer Identity Verification ', label: ' Customer Identity Verification ' },
			{ value: ' Cloud Access Security Broker ', label: ' Cloud Access Security Broker ' },
			{ value: ' DevSecOps ', label: ' DevSecOps ' },
			{ value: ' Identity as a Service ', label: ' Identity as a Service ' }
			]
		} else if (industry == "Diamond & Jewellery- Online") {
			optionSector = [{ value: 'Diamond', label: 'Diamond' },
			{ value: 'Online Jewellery', label: 'Online Jewellery' },
			]
		} else if (industry == "Education & Ed-Tech") {
			optionSector = [{ value: ' Education ', label: ' Education ' },
			{ value: ' Continuing Education Chains ', label: ' Continuing Education Chains ' },
			{ value: ' Test Preparation Chains ', label: ' Test Preparation Chains ' },
			{ value: ' Pre-K EdTech ', label: ' Pre-K EdTech ' },
			{ value: ' K-12 EdTech ', label: ' K-12 EdTech ' },
			{ value: ' Test Preparation Tech ', label: ' Test Preparation Tech ' },
			{ value: ' Higher Education Tech ', label: ' Higher Education Tech ' },
			{ value: ' Continued Learning ', label: ' Continued Learning ' },
			{ value: ' Education IT ', label: ' Education IT ' },
			{ value: ' Online Tutoring ', label: ' Online Tutoring ' },
			{ value: ' Activity Based Learning ', label: ' Activity Based Learning ' },
			{ value: ' Online Courses ', label: ' Online Courses ' },
			{ value: ' Language Learning Tech ', label: ' Language Learning Tech ' },
			{ value: ' Corporate Learning ', label: ' Corporate Learning ' },
			{ value: ' Gamification in Education ', label: ' Gamification in Education ' },
			{ value: ' Online Test Prep Tutoring ', label: ' Online Test Prep Tutoring ' },
			{ value: ' Online Test Prep Courses ', label: ' Online Test Prep Courses ' },
			{ value: ' Preschool Communication Platforms ', label: ' Preschool Communication Platforms ' },
			{ value: ' Preschool Management Software ', label: ' Preschool Management Software ' },
			{ value: ' Digital Storybooks ', label: ' Digital Storybooks ' },
			{ value: ' Income Sharing Agreement Programs ', label: ' Income Sharing Agreement Programs ' },
			{ value: ' Internet First Education Loans ', label: ' Internet First Education Loans ' },
			{ value: ' Online Universities ', label: ' Online Universities ' },
			{ value: ' College Discovery Platforms ', label: ' College Discovery Platforms ' },
			{ value: ' College Admissions Advising Platforms ', label: ' College Admissions Advising Platforms ' },
			{ value: ' Online Schools ', label: ' Online Schools ' },
			{ value: ' Coding for Kids ', label: ' Coding for Kids ' },
			{ value: ' K-12 Learning Communities ', label: ' K-12 Learning Communities ' },
			{ value: ' K-12 Special Education ', label: ' K-12 Special Education ' },
			{ value: ' Personalized Learning ', label: ' Personalized Learning ' }
			]
		} else if (industry == "Electric equipments & Electronics") {
			optionSector = [{ value: ' Electric equipments ', label: ' Electric equipments ' },
			{ value: ' Boilers/ Turbines ', label: ' Boilers/ Turbines ' },
			{ value: ' Switchgears ', label: ' Switchgears ' },
			{ value: ' Transformers ', label: ' Transformers ' },
			{ value: ' Electrodes ', label: ' Electrodes ' },
			{ value: ' Welding Equipments ', label: ' Welding Equipments ' },
			{ value: ' Flexible Batteries ', label: ' Flexible Batteries ' },
			{ value: ' Flexible Sensors ', label: ' Flexible Sensors ' }
			]
		} else if (industry == "Energy") {
			optionSector = [{ value: ' Energy ', label: ' Energy ' },
			{ value: ' Smart Grid ', label: ' Smart Grid ' },
			{ value: ' Renewable Energy Tech ', label: ' Renewable Energy Tech ' },
			{ value: ' Oil & Gas Tech ', label: ' Oil & Gas Tech ' },
			{ value: ' Electric Vehicles ', label: ' Electric Vehicles ' },
			{ value: ' Energy Storage Tech ', label: ' Energy Storage Tech ' },
			{ value: ' Solar Energy Tech ', label: ' Solar Energy Tech ' },
			{ value: ' Wind Energy Tech ', label: ' Wind Energy Tech ' },
			{ value: ' Hydrogen Production and Storage ', label: ' Hydrogen Production and Storage ' },
			{ value: ' Nuclear Fusion ', label: ' Nuclear Fusion ' },
			{ value: ' HVAC Optimization Software ', label: ' HVAC Optimization Software ' },
			{ value: ' Solar Energy ', label: ' Solar Energy ' },
			{ value: ' Wind Energy ', label: ' Wind Energy ' },
			{ value: ' Nuclear Energy ', label: ' Nuclear Energy ' },
			{ value: ' Oil & Gas ', label: ' Oil & Gas ' },
			{ value: ' Bioenergy ', label: ' Bioenergy ' },
			{ value: ' Hydro Power ', label: ' Hydro Power ' },
			{ value: ' Geothermal Energy ', label: ' Geothermal Energy ' },
			{ value: ' Marine Energy ', label: ' Marine Energy ' },
			{ value: ' Oil & Gas Midstream ', label: ' Oil & Gas Midstream ' },
			{ value: ' Oil & Gas Upstream ', label: ' Oil & Gas Upstream ' },
			{ value: ' Oil & Gas Downstream ', label: ' Oil & Gas Downstream ' },
			{ value: ' Oilfield Services ', label: ' Oilfield Services ' },
			{ value: ' Energy Efficiency ', label: ' Energy Efficiency ' },
			{ value: ' Waste to Energy ', label: ' Waste to Energy ' },
			{ value: ' Smart Water Grid ', label: ' Smart Water Grid ' },
			{ value: ' Battery Management System ', label: ' Battery Management System ' },
			{ value: ' Advanced Battery Materials ', label: ' Advanced Battery Materials ' },
			{ value: ' Lithium Ion Batteries ', label: ' Lithium Ion Batteries ' },
			{ value: ' Solid State Lithium Ion Batteries ', label: ' Solid State Lithium Ion Batteries ' },
			{ value: ' Wireless Charging ', label: ' Wireless Charging ' },
			{ value: ' Energy Storage System ', label: ' Energy Storage System ' },
			{ value: ' Lithium Ion Energy Storage System ', label: ' Lithium Ion Energy Storage System ' },
			{ value: ' Redox Flow Energy Storage System ', label: ' Redox Flow Energy Storage System ' },
			{ value: ' Thermal Energy Storage System ', label: ' Thermal Energy Storage System ' },
			{ value: ' Fuel Cells ', label: ' Fuel Cells ' },
			{ value: ' Electric Vehicle Charging ', label: ' Electric Vehicle Charging ' },
			{ value: ' Hydrogen Production ', label: ' Hydrogen Production ' },
			{ value: ' CO2 to Fuel ', label: ' CO2 to Fuel ' },
			{ value: ' Tidal Energy Generation System ', label: ' Tidal Energy Generation System ' },
			{ value: ' Wave Energy Convertor ', label: ' Wave Energy Convertor ' },
			{ value: ' Solar Cleaning Robots ', label: ' Solar Cleaning Robots ' },
			{ value: ' Solar Aircraft ', label: ' Solar Aircraft ' },
			{ value: ' Residential Solar Monitoring ', label: ' Residential Solar Monitoring ' },
			{ value: ' P2P Solar Energy Trading ', label: ' P2P Solar Energy Trading ' },
			{ value: ' Internet First Solar Financier ', label: ' Internet First Solar Financier ' },
			{ value: ' CIGS Solar Cells ', label: ' CIGS Solar Cells ' },
			{ value: ' Organic Solar Cells ', label: ' Organic Solar Cells ' },
			{ value: ' Perovskite Solar Cells ', label: ' Perovskite Solar Cells ' },
			{ value: ' Thin Film Solar Cells ', label: ' Thin Film Solar Cells ' },
			{ value: ' Solar Sales and Design Software ', label: ' Solar Sales and Design Software ' },
			{ value: ' Wind Farm IT ', label: ' Wind Farm IT ' },
			{ value: ' Building Energy Management System ', label: ' Building Energy Management System ' },
			{ value: ' Home Energy Management System ', label: ' Home Energy Management System ' },
			{ value: ' Industrial Energy Management System ', label: ' Industrial Energy Management System ' },
			{ value: ' Commercial HVAC Management System ', label: ' Commercial HVAC Management System ' },
			{ value: ' Lighting Control System ', label: ' Lighting Control System ' },
			{ value: ' Smart Street Lighting Control System ', label: ' Smart Street Lighting Control System ' },
			{ value: ' Commercial Energy Monitoring ', label: ' Commercial Energy Monitoring ' },
			{ value: ' Home Energy Monitoring ', label: ' Home Energy Monitoring ' },
			{ value: ' Energy Comparison Platforms ', label: ' Energy Comparison Platforms ' },
			{ value: ' Smart LED Lights ', label: ' Smart LED Lights ' },
			{ value: ' Smart Agriculture LED Lights ', label: ' Smart Agriculture LED Lights ' },
			{ value: ' OLED Lights ', label: ' OLED Lights ' },
			{ value: ' Advanced Metering Infrastructure ', label: ' Advanced Metering Infrastructure ' },
			{ value: ' Microgrid ', label: ' Microgrid ' },
			{ value: ' Virtual Power Plant ', label: ' Virtual Power Plant ' },
			{ value: ' Grid Monitoring and Intelligence ', label: ' Grid Monitoring and Intelligence ' },
			{ value: ' Oil and Gas Pipeline Monitoring ', label: ' Oil and Gas Pipeline Monitoring ' },
			{ value: ' Digital Oilfield ', label: ' Digital Oilfield ' },
			{ value: ' Grid Energy Storage ', label: ' Grid Energy Storage ' },
			{ value: ' Smart Thermostats ', label: ' Smart Thermostats ' },
			{ value: ' Nuclear Fusion ', label: ' Nuclear Fusion ' },
			{ value: ' Energy Harvesting ', label: ' Energy Harvesting ' },
			{ value: ' Sustainable Batteries ', label: ' Sustainable Batteries ' },
			{ value: ' Solar Energy ', label: ' Solar Energy ' },
			{ value: ' Wind Energy ', label: ' Wind Energy ' },
			{ value: ' Nuclear Energy ', label: ' Nuclear Energy ' },
			{ value: ' Green Buildings ', label: ' Green Buildings ' },
			{ value: ' Waste & Water Management ', label: ' Waste & Water Management ' },
			{ value: ' Electric Vehicles - Asia ', label: ' Electric Vehicles - Asia ' },
			{ value: ' Sustainable Farming ', label: ' Sustainable Farming ' },
			{ value: ' Sustainable ECommerce ', label: ' Sustainable ECommerce ' },
			{ value: ' Sustainable Forestry Management ', label: ' Sustainable Forestry Management ' },
			{ value: ' Sustainable Batteries ', label: ' Sustainable Batteries ' },
			{ value: ' Sustainable Crypto Mining ', label: ' Sustainable Crypto Mining ' },
			{ value: ' Sustainable Fabrics ', label: ' Sustainable Fabrics ' },
			{ value: ' Biomaterials ', label: ' Biomaterials ' },
			{ value: ' Green Chemicals ', label: ' Green Chemicals ' },
			{ value: ' Clean Coal ', label: ' Clean Coal ' },
			{ value: ' Energy Harvesting ', label: ' Energy Harvesting ' },
			{ value: ' Waste to Energy ', label: ' Waste to Energy ' },
			{ value: ' Water Treatment Technologies ', label: ' Water Treatment Technologies ' },
			{ value: ' Tech Enabled Waste Haulers ', label: ' Tech Enabled Waste Haulers ' },
			{ value: ' Commercial Waste Collection Marketplaces ', label: ' Commercial Waste Collection Marketplaces ' },
			{ value: ' Waste Bin Monitoring ', label: ' Waste Bin Monitoring ' },
			{ value: ' Tech Enabled Plastic Recycler ', label: ' Tech Enabled Plastic Recycler ' },
			{ value: ' Robotic Waste Sorting ', label: ' Robotic Waste Sorting ' },
			{ value: ' Water Usage Management ', label: ' Water Usage Management ' },
			{ value: ' Smart Water Grid ', label: ' Smart Water Grid ' },
			{ value: ' Water from Air ', label: ' Water from Air ' },
			{ value: ' Smart Irrigation ', label: ' Smart Irrigation ' },
			{ value: ' Electric Vehicle Charging ', label: ' Electric Vehicle Charging ' },
			{ value: ' Hydrogen Production ', label: ' Hydrogen Production ' },
			{ value: ' CO2 to Fuel ', label: ' CO2 to Fuel ' },
			{ value: ' Tidal Energy Generation System ', label: ' Tidal Energy Generation System ' },
			{ value: ' Wave Energy Convertor ', label: ' Wave Energy Convertor ' },
			{ value: ' Solar Cleaning Robots ', label: ' Solar Cleaning Robots ' },
			{ value: ' Solar Aircraft ', label: ' Solar Aircraft ' },
			{ value: ' Residential Solar Monitoring ', label: ' Residential Solar Monitoring ' },
			{ value: ' P2P Solar Energy Trading ', label: ' P2P Solar Energy Trading ' },
			{ value: ' Internet First Solar Financier ', label: ' Internet First Solar Financier ' },
			{ value: ' CIGS Solar Cells ', label: ' CIGS Solar Cells ' },
			{ value: ' Organic Solar Cells ', label: ' Organic Solar Cells ' },
			{ value: ' Perovskite Solar Cells ', label: ' Perovskite Solar Cells ' },
			{ value: ' Thin Film Solar Cells ', label: ' Thin Film Solar Cells ' },
			{ value: ' Solar Sales and Design Software ', label: ' Solar Sales and Design Software ' },
			{ value: ' Wind Farm IT ', label: ' Wind Farm IT ' },
			{ value: ' Smart LED Lights ', label: ' Smart LED Lights ' },
			{ value: ' Smart Agriculture LED Lights ', label: ' Smart Agriculture LED Lights ' },
			{ value: ' OLED Lights ', label: ' OLED Lights ' },
			{ value: ' Waste Heat Recovery ', label: ' Waste Heat Recovery ' },
			{ value: ' Air Quality Monitoring ', label: ' Air Quality Monitoring ' },
			{ value: ' Carbon Capture and Offset ', label: ' Carbon Capture and Offset ' },
			{ value: ' Advanced Metering Infrastructure ', label: ' Advanced Metering Infrastructure ' },
			{ value: ' Microgrid ', label: ' Microgrid ' },
			{ value: ' Virtual Power Plant ', label: ' Virtual Power Plant ' },
			{ value: ' Grid Monitoring and Intelligence ', label: ' Grid Monitoring and Intelligence ' },
			{ value: ' Grid Energy Storage ', label: ' Grid Energy Storage ' },
			{ value: ' Smart Thermostats ', label: ' Smart Thermostats ' },
			{ value: ' Nuclear Fusion ', label: ' Nuclear Fusion ' },
			{ value: ' HVAC Optimization Software ', label: ' HVAC Optimization Software ' },
			{ value: ' Climate Intelligence ', label: ' Climate Intelligence ' },
			{ value: ' Carbon Accounting ', label: ' Carbon Accounting ' },
			{ value: ' Carbon Capture and Offset ', label: ' Carbon Capture and Offset ' },
			{ value: ' Climate FinTech ', label: ' Climate FinTech ' },
			{ value: ' Climate Insurance ', label: ' Climate Insurance ' },
			{ value: ' Renewable Energy Tech ', label: ' Renewable Energy Tech ' },
			{ value: ' Energy Efficiency Tech ', label: ' Energy Efficiency Tech ' },
			{ value: ' Smart Grid ', label: ' Smart Grid ' },
			{ value: ' Solar Energy Tech ', label: ' Solar Energy Tech ' },
			{ value: ' Green Transport ', label: ' Green Transport ' },
			{ value: ' Precision Farming ', label: ' Precision Farming ' },
			{ value: ' Air Pollution Management Tech ', label: ' Air Pollution Management Tech ' },
			{ value: ' Solid Waste Management Tech ', label: ' Solid Waste Management Tech ' },
			{ value: ' Water and Wastewater Management Tech ', label: ' Water and Wastewater Management Tech ' },
			{ value: ' Circular Economy ', label: ' Circular Economy ' },
			{ value: ' Hydrogen Production and Storage ', label: ' Hydrogen Production and Storage ' },
			{ value: ' Microfinance ', label: ' Microfinance ' },
			{ value: ' Affordable Housing ', label: ' Affordable Housing ' },
			{ value: ' Green Buildings ', label: ' Green Buildings ' },
			{ value: ' Climate Intelligence ', label: ' Climate Intelligence ' },
			{ value: ' Carbon Accounting ', label: ' Carbon Accounting ' },
			{ value: ' Carbon Capture and Offset  ', label: ' Carbon Capture and Offset  ' },
			{ value: ' Climate FinTech ', label: ' Climate FinTech ' },
			{ value: ' Climate Insurance ', label: ' Climate Insurance ' },
			{ value: ' Renewable Energy Tech ', label: ' Renewable Energy Tech ' },
			{ value: ' Energy Efficiency Tech ', label: ' Energy Efficiency Tech ' },
			{ value: ' Smart Grid ', label: ' Smart Grid ' },
			{ value: ' Solar Energy Tech ', label: ' Solar Energy Tech ' },
			{ value: ' Green Transport ', label: ' Green Transport ' },
			{ value: ' Precision Farming ', label: ' Precision Farming ' },
			{ value: ' Air Pollution Management Tech ', label: ' Air Pollution Management Tech ' },
			{ value: ' Solid Waste Management Tech ', label: ' Solid Waste Management Tech ' },
			{ value: ' Water and Wastewater Management Tech ', label: ' Water and Wastewater Management Tech ' },
			{ value: ' Circular Economy ', label: ' Circular Economy ' },
			{ value: ' Hydrogen Production and Storage ', label: ' Hydrogen Production and Storage ' },
			{ value: ' Climate Tech ', label: ' Climate Tech ' },
			]
		} else if (industry == "Enterprise Applications") {
			optionSector = [{ value: ' Enterprise Applications ', label: ' Enterprise Applications ' },
			{ value: ' Enterprise Collaboration ', label: ' Enterprise Collaboration ' },
			{ value: ' Sales Force Automation ', label: ' Sales Force Automation ' },
			{ value: ' MarketingTech ', label: ' MarketingTech ' },
			{ value: ' HRTech ', label: ' HRTech ' },
			{ value: ' Business Intelligence ', label: ' Business Intelligence ' },
			{ value: ' Big Data Analytics ', label: ' Big Data Analytics ' },
			{ value: ' Enterprise Resource Planning ', label: ' Enterprise Resource Planning ' },
			{ value: ' Legal Tech ', label: ' Legal Tech ' },
			{ value: ' Customer Success Management ', label: ' Customer Success Management ' },
			{ value: ' SCM Software ', label: ' SCM Software ' },
			{ value: ' PR Tech ', label: ' PR Tech ' },
			{ value: ' Field Force Automation ', label: ' Field Force Automation ' },
			{ value: ' Events Tech ', label: ' Events Tech ' },
			{ value: ' Data as a Service ', label: ' Data as a Service ' },
			{ value: ' Customer Service Software ', label: ' Customer Service Software ' },
			{ value: ' Product Management Tools ', label: ' Product Management Tools ' },
			{ value: ' GovTech ', label: ' GovTech ' },
			{ value: ' GRC Software ', label: ' GRC Software ' },
			{ value: ' Loyalty Software ', label: ' Loyalty Software ' },
			{ value: ' Nonprofit Tech ', label: ' Nonprofit Tech ' },
			{ value: ' Manufacturing Tech ', label: ' Manufacturing Tech ' },
			{ value: ' Robotic Process Automation ', label: ' Robotic Process Automation ' },
			{ value: ' Mining Tech ', label: ' Mining Tech ' },
			{ value: ' SaaS ', label: ' SaaS ' },
			{ value: ' Business Process Management Software ', label: ' Business Process Management Software ' },
			{ value: ' Enterprise Information Management ', label: ' Enterprise Information Management ' },
			{ value: ' Localisation - L10n Tech ', label: ' Localisation - L10n Tech ' },
			{ value: ' Global SaaS ', label: ' Global SaaS ' },
			{ value: ' Digital Transaction Management ', label: ' Digital Transaction Management ' },
			{ value: ' Content Management System (CMS) ', label: ' Content Management System (CMS) ' },
			{ value: ' Video Creation Tools ', label: ' Video Creation Tools ' },
			{ value: ' Enterprise Video ', label: ' Enterprise Video ' },
			{ value: ' AdTech ', label: ' AdTech ' },
			{ value: ' Tech for Traditional Advertising ', label: ' Tech for Traditional Advertising ' },
			{ value: ' Mobile Advertising ', label: ' Mobile Advertising ' },
			{ value: ' Vertical Business Intelligence ', label: ' Vertical Business Intelligence ' },
			{ value: ' SME Business Management Software ', label: ' SME Business Management Software ' },
			{ value: ' Procurement IT ', label: ' Procurement IT ' },
			{ value: ' Corporate Learning ', label: ' Corporate Learning ' },
			{ value: ' WhatsApp for Business ', label: ' WhatsApp for Business ' },
			{ value: ' Community Tools ', label: ' Community Tools ' },
			{ value: ' Enterprise Software ', label: ' Enterprise Software ' },
			{ value: ' Enterprise Content Management ', label: ' Enterprise Content Management ' },
			{ value: ' Records Management ', label: ' Records Management ' },
			{ value: ' Enterprise Data Management ', label: ' Enterprise Data Management ' },
			{ value: ' Enterprise Search ', label: ' Enterprise Search ' },
			{ value: ' Knowledge Graph Platforms ', label: ' Knowledge Graph Platforms ' },
			{ value: ' Data Governance ', label: ' Data Governance ' },
			{ value: ' Data Security ', label: ' Data Security ' },
			{ value: ' Enterprise Document Management ', label: ' Enterprise Document Management ' },
			{ value: ' Knowledge Management ', label: ' Knowledge Management ' },
			{ value: ' Big Data Security ', label: ' Big Data Security ' },
			{ value: ' Big Data Warehouse ', label: ' Big Data Warehouse ' },
			{ value: ' Enterprise Search ', label: ' Enterprise Search ' },
			{ value: ' NoSQL Database ', label: ' NoSQL Database ' },
			{ value: ' Graph Database ', label: ' Graph Database ' },
			{ value: ' Streaming Analytics Platforms ', label: ' Streaming Analytics Platforms ' },
			{ value: ' Network Processors ', label: ' Network Processors ' },
			{ value: ' AI Processors ', label: ' AI Processors ' },
			{ value: ' Memory Chips ', label: ' Memory Chips ' },
			{ value: ' Electronic Signature ', label: ' Electronic Signature ' },
			{ value: ' Workflow Automation ', label: ' Workflow Automation ' },
			{ value: ' Enterprise Document Management ', label: ' Enterprise Document Management ' },
			]
		} else if (industry == "Finance,Fintech & Insurtech") {
			optionSector = [{ value: ' Finance ', label: ' Finance ' },
			{ value: ' Investment ', label: ' Investment ' },
			{ value: ' NBFC ', label: ' NBFC ' },
			{ value: ' Stock Broking ', label: ' Stock Broking ' },
			{ value: ' Housing ', label: ' Housing ' },
			{ value: ' Alternative Lending ', label: ' Alternative Lending ' },
			{ value: ' Investment Tech ', label: ' Investment Tech ' },
			{ value: ' Cryptocurrencies ', label: ' Cryptocurrencies ' },
			{ value: ' Payments ', label: ' Payments ' },
			{ value: ' Banking Tech ', label: ' Banking Tech ' },
			{ value: ' Digital Remittance ', label: ' Digital Remittance ' },
			{ value: ' Crowdfunding ', label: ' Crowdfunding ' },
			{ value: ' RegTech ', label: ' RegTech ' },
			{ value: ' Forex Tech ', label: ' Forex Tech ' },
			{ value: ' Islamic FinTech ', label: ' Islamic FinTech ' },
			{ value: ' Internet First Insurance Platforms ', label: ' Internet First Insurance Platforms ' },
			{ value: ' Employer Insurance ', label: ' Employer Insurance ' },
			{ value: ' Insurance IT ', label: ' Insurance IT ' },
			{ value: ' Finance & Accounting Tech ', label: ' Finance & Accounting Tech ' },
			{ value: ' Agri FinTech ', label: ' Agri FinTech ' },
			{ value: ' Real Estate Financing Tech ', label: ' Real Estate Financing Tech ' },
			{ value: ' Property Insurance Tech ', label: ' Property Insurance Tech ' },
			{ value: ' Internet First Healthcare Financing Platforms ', label: ' Internet First Healthcare Financing Platforms ' },
			{ value: ' Internet First Health Insurance Platforms ', label: ' Internet First Health Insurance Platforms ' },
			{ value: ' Robo Advisors ', label: ' Robo Advisors ' },
			{ value: ' Automotive FinTech ', label: ' Automotive FinTech ' },
			{ value: ' Non Fungible Tokens ', label: ' Non Fungible Tokens ' },
			{ value: ' Embedded Finance ', label: ' Embedded Finance ' },
			{ value: ' Travel Fintech ', label: ' Travel Fintech ' },
			{ value: ' Embedded Lending ', label: ' Embedded Lending ' },
			{ value: ' Decentralized Finance ', label: ' Decentralized Finance ' },
			{ value: ' Data Privacy Software ', label: ' Data Privacy Software ' },
			{ value: ' Fraud Detection in Financial Services ', label: ' Fraud Detection in Financial Services ' },
			{ value: ' KYC Solutions ', label: ' KYC Solutions ' },
			{ value: ' Risk Monitoring in Financial Services ', label: ' Risk Monitoring in Financial Services ' },
			{ value: ' Internet First Insurance Platforms ', label: ' Internet First Insurance Platforms ' },
			{ value: ' Employer Insurance ', label: ' Employer Insurance ' },
			{ value: ' Insurance IT ', label: ' Insurance IT ' },
			{ value: ' Internet First Health Insurance Platforms ', label: ' Internet First Health Insurance Platforms ' },
			{ value: ' Property Insurance Tech ', label: ' Property Insurance Tech ' },
			{ value: ' Banks ', label: ' Banks ' },
			{ value: ' Asset Finance ', label: ' Asset Finance ' },
			{ value: ' Consumer and SME Loans ', label: ' Consumer and SME Loans ' },
			{ value: ' Microfinance ', label: ' Microfinance ' },
			{ value: ' Financial Exchanges ', label: ' Financial Exchanges ' },
			{ value: ' Housing Finance ', label: ' Housing Finance ' },
			{ value: ' Asset Management ', label: ' Asset Management ' },
			{ value: ' Insurance Carriers ', label: ' Insurance Carriers ' },
			{ value: ' Credit Rating Agencies ', label: ' Credit Rating Agencies ' },
			{ value: ' REITs ', label: ' REITs ' },
			{ value: ' Venture Capital Funds ', label: ' Venture Capital Funds ' },
			{ value: ' Private Equity Funds ', label: ' Private Equity Funds ' },
			{ value: ' Accelerators & Incubators ', label: ' Accelerators & Incubators ' },
			{ value: ' Angel Networks ', label: ' Angel Networks ' },
			{ value: ' Investment Banks ', label: ' Investment Banks ' },
			{ value: ' Single Family Offices ', label: ' Single Family Offices ' },
			{ value: ' Limited Partners ', label: ' Limited Partners ' },
			{ value: ' Private Debt Funds ', label: ' Private Debt Funds ' },
			{ value: ' Real Estate Funds ', label: ' Real Estate Funds ' },
			{ value: ' Secondary Market Funds ', label: ' Secondary Market Funds ' },
			{ value: ' Hedge Funds ', label: ' Hedge Funds ' },
			{ value: ' Infrastructure Funds ', label: ' Infrastructure Funds ' },
			{ value: ' Pension Funds ', label: ' Pension Funds ' },
			{ value: ' Endowment Funds ', label: ' Endowment Funds ' },
			{ value: ' Sovereign Wealth Funds ', label: ' Sovereign Wealth Funds ' },
			{ value: ' Social Impact Funds ', label: ' Social Impact Funds ' },
			{ value: ' Special Purpose Acquisition Companies ', label: ' Special Purpose Acquisition Companies ' },
			{ value: ' Corporate Accelerators ', label: ' Corporate Accelerators ' },
			{ value: ' Green Funds ', label: ' Green Funds ' },
			{ value: ' Cryptocurrency Remittance ', label: ' Cryptocurrency Remittance ' },
			{ value: ' P2P Wallet Remittance ', label: ' P2P Wallet Remittance ' },
			{ value: ' B2B Remittance ', label: ' B2B Remittance ' },
			{ value: ' P2P Remittance ', label: ' P2P Remittance ' },
			]
		} else if (industry == "Technology") {
			optionSector = [{ value: ' Technology ', label: ' Technology ' },
			{ value: ' Industrial Robotics ', label: ' Industrial Robotics ' },
			{ value: ' Smart Homes ', label: ' Smart Homes ' },
			{ value: ' 3D Printing ', label: ' 3D Printing ' },
			{ value: ' Consumer Robotics ', label: ' Consumer Robotics ' },
			{ value: ' Drones ', label: ' Drones ' },
			{ value: ' Virtual Reality ', label: ' Virtual Reality ' },
			{ value: ' Augmented Reality ', label: ' Augmented Reality ' },
			{ value: ' Geographic Information Systems ', label: ' Geographic Information Systems ' },
			{ value: ' Advanced Materials ', label: ' Advanced Materials ' },
			{ value: ' Chatbots ', label: ' Chatbots ' },
			{ value: ' Nanotechnology ', label: ' Nanotechnology ' },
			{ value: ' Security & Surveillance Technology ', label: ' Security & Surveillance Technology ' },
			{ value: ' Quantum Computing ', label: ' Quantum Computing ' },
			{ value: ' 3D Scanning ', label: ' 3D Scanning ' },
			{ value: ' Gesture Recognition ', label: ' Gesture Recognition ' },
			{ value: ' Haptic Technology ', label: ' Haptic Technology ' },
			{ value: ' Brain Computer Interface ', label: ' Brain Computer Interface ' },
			{ value: ' Eye Tracking ', label: ' Eye Tracking ' },
			{ value: ' Smart Contracts ', label: ' Smart Contracts ' },
			{ value: ' Blockchain Infrastructure ', label: ' Blockchain Infrastructure ' },
			{ value: ' Cognitive Computing ', label: ' Cognitive Computing ' },
			{ value: ' Speech and Voice Recognition ', label: ' Speech and Voice Recognition ' },
			{ value: ' Internet of Things Infrastructure ', label: ' Internet of Things Infrastructure ' },
			{ value: ' Edge Computing ', label: ' Edge Computing ' },
			{ value: ' Digital Twin ', label: ' Digital Twin ' },
			{ value: ' Smart Cities ', label: ' Smart Cities ' },
			{ value: ' Wearable Technology ', label: ' Wearable Technology ' },
			{ value: ' AI Infrastructure ', label: ' AI Infrastructure ' },
			{ value: ' Image Recognition ', label: ' Image Recognition ' },
			{ value: ' 3D Animation ', label: ' 3D Animation ' },
			{ value: ' Audio Tech ', label: ' Audio Tech ' },
			{ value: ' IoT Applications ', label: ' IoT Applications ' },
			{ value: ' Computer Vision ', label: ' Computer Vision ' },
			{ value: ' Smart Factories ', label: ' Smart Factories ' },
			{ value: ' Industrial Internet of Things ', label: ' Industrial Internet of Things ' },
			{ value: ' Professional Service Robots ', label: ' Professional Service Robots ' },
			{ value: ' Natural Language Processing ', label: ' Natural Language Processing ' },
			{ value: ' Consumer Internet of Things ', label: ' Consumer Internet of Things ' },
			{ value: ' IoT Services ', label: ' IoT Services ' },
			{ value: ' AI Services ', label: ' AI Services ' },
			{ value: ' 3D Rendering ', label: ' 3D Rendering ' },
			{ value: ' Intelligent 3D Avatars ', label: ' Intelligent 3D Avatars ' },
			{ value: ' Motion Capture Animation ', label: ' Motion Capture Animation ' },
			]
		} else if (industry == "Food & Food Tech") {
			optionSector = [{ value: ' Food Tech ', label: ' Food Tech ' },
			{ value: ' Food Processing ', label: ' Food Processing ' },
			{ value: ' Novel Foods ', label: ' Novel Foods ' },
			{ value: ' Food Delivery ', label: ' Food Delivery ' },
			{ value: ' Restaurant IT ', label: ' Restaurant IT ' },
			{ value: ' Internet First Restaurants ', label: ' Internet First Restaurants ' },
			{ value: ' Recipe Box ', label: ' Recipe Box ' },
			{ value: ' Alcoholic Beverage E-Commerce ', label: ' Alcoholic Beverage E-Commerce ' },
			{ value: ' Shared Kitchens ', label: ' Shared Kitchens ' },
			{ value: ' B2B Farm Produce E-Commerce ', label: ' B2B Farm Produce E-Commerce ' },
			{ value: ' Micro-Delivery Grocery Service ', label: ' Micro-Delivery Grocery Service ' },
			{ value: ' Organic Farming ', label: ' Organic Farming ' },
			{ value: ' Surplus Food Marketplaces ', label: ' Surplus Food Marketplaces ' },
			{ value: ' Meat Delivery ', label: ' Meat Delivery ' },
			{ value: ' Internet First Snack Brands ', label: ' Internet First Snack Brands ' },
			{ value: ' Food Safety Analysis ', label: ' Food Safety Analysis ' },
			{ value: ' Thela Tech ', label: ' Thela Tech ' },
			{ value: ' Ultrafast Grocery Delivery ', label: ' Ultrafast Grocery Delivery ' },
			{ value: ' Vegan Food ', label: ' Vegan Food ' },
			{ value: ' Restaurant SaaS ', label: ' Restaurant SaaS ' },
			{ value: ' Grocery Subscription ', label: ' Grocery Subscription ' },
			{ value: ' Fruits & Vegetables Ecommerce ', label: ' Fruits & Vegetables Ecommerce ' },
			{ value: ' Smart Farming ', label: ' Smart Farming ' },
			{ value: ' Online Grocery ', label: ' Online Grocery ' },
			{ value: ' Beverage Tech ', label: ' Beverage Tech ' },
			{ value: ' Agriculture E-Commerce ', label: ' Agriculture E-Commerce ' },
			{ value: ' Food & Beverage Products ', label: ' Food & Beverage Products ' },
			{ value: ' Alcoholic Beverage Products ', label: ' Alcoholic Beverage Products ' },
			{ value: ' Food Service Chains ', label: ' Food Service Chains ' }
			]
		} else if (industry == "Fashion- Tech  & Footwear") {
			optionSector = [{ value: ' Footwear ', label: ' Footwear ' },
			{ value: ' AI Based Personalized Stylist ', label: ' AI Based Personalized Stylist ' },
			{ value: ' Smart Fashion ', label: ' Smart Fashion ' },
			{ value: ' Made-to-Measure Apparel ', label: ' Made-to-Measure Apparel ' },
			{ value: ' Smart Fitting Solutions ', label: ' Smart Fitting Solutions ' },
			{ value: ' Internet First Footwear Brands ', label: ' Internet First Footwear Brands ' },
			{ value: ' Internet First Jewellery Brands ', label: ' Internet First Jewellery Brands ' },
			{ value: ' Internet First Luggage Brands ', label: ' Internet First Luggage Brands ' },
			{ value: ' Internet First Apparel Brands ', label: ' Internet First Apparel Brands ' },
			{ value: ' Internet First Sportswear Brands ', label: ' Internet First Sportswear Brands ' },
			{ value: ' Internet First Innerwear Brands ', label: ' Internet First Innerwear Brands ' },
			{ value: ' Eyewear E-Commerce ', label: ' Eyewear E-Commerce ' },
			{ value: ' B2B Fashion E-Commerce ', label: ' B2B Fashion E-Commerce ' },
			{ value: ' Smart Watches ', label: ' Smart Watches ' },
			{ value: ' Performance Wear ', label: ' Performance Wear ' },
			{ value: ' Online Personal Shopper ', label: ' Online Personal Shopper ' },
			{ value: ' Innovative Fashion Materials ', label: ' Innovative Fashion Materials ' },
			{ value: ' Online Fashion Rentals ', label: ' Online Fashion Rentals ' },
			{ value: ' Luxury Fashion Consignment ', label: ' Luxury Fashion Consignment ' },
			{ value: ' Second Hand Fashion Goods ', label: ' Second Hand Fashion Goods ' },
			{ value: ' Plus Size Fashion ', label: ' Plus Size Fashion ' },
			{ value: ' Smart Jewelry ', label: ' Smart Jewelry ' },
			{ value: "Internet First Men's Clothing Brands", label: " Internet First Men's Clothing Brands " },
			{ value: "Internet First Women's Clothing Brands", label: "Internet First Women's Clothing Brands" },
			{ value: ' Internet First Kids Clothing Brands ', label: ' Internet First Kids Clothing Brands ' },
			{ value: ' Sustainable Fashion E-Commerce ', label: ' Sustainable Fashion E-Commerce ' },
			{ value: ' Sustainable Fabrics ', label: ' Sustainable Fabrics ' },
			]
		} else if (industry == "Home Tech") {
			optionSector = [{ value: ' Home Tech ', label: ' Home Tech ' },
			{ value: ' Smart Home Control Systems ', label: ' Smart Home Control Systems ' },
			{ value: ' Smart Thermostats ', label: ' Smart Thermostats ' },
			{ value: ' Home Air Purifiers ', label: ' Home Air Purifiers ' },
			{ value: ' Smart Kitchen Appliances ', label: ' Smart Kitchen Appliances ' },
			{ value: ' Smart Lights ', label: ' Smart Lights ' },
			{ value: ' Smart Switches ', label: ' Smart Switches ' },
			{ value: ' Smart Locks ', label: ' Smart Locks ' },
			{ value: ' Home Monitoring Cameras ', label: ' Home Monitoring Cameras ' },
			{ value: ' Smart Home Suite ', label: ' Smart Home Suite ' },
			{ value: ' Energy Monitoring for Smart Homes ', label: ' Energy Monitoring for Smart Homes ' }
			]
		} else if (industry == "Hospital & Healthcare") {
			optionSector = [{ value: ' Hospital ', label: ' Hospital ' },
			{ value: ' Telemedicine ', label: ' Telemedicine ' },
			{ value: ' Online Health Information ', label: ' Online Health Information ' },
			{ value: ' Freelancer Marketplace ', label: ' Freelancer Marketplace ' },
			{ value: ' Horizontal E-Commerce ', label: ' Horizontal E-Commerce ' },
			{ value: ' Gambling Tech ', label: ' Gambling Tech ' },
			{ value: ' Recipe Box ', label: ' Recipe Box ' },
			{ value: ' Online Gaming Platforms ', label: ' Online Gaming Platforms ' },
			{ value: ' Online Courses ', label: ' Online Courses ' },
			{ value: ' B2C Grocery Delivery ', label: ' B2C Grocery Delivery ' },
			{ value: ' OTT Streaming Platforms ', label: ' OTT Streaming Platforms ' },
			{ value: ' Kids Hobby Classes ', label: ' Kids Hobby Classes ' },
			{ value: ' Online Events ', label: ' Online Events ' },
			{ value: ' At Home Fitness ', label: ' At Home Fitness ' },
			{ value: ' Plant Based Protein ', label: ' Plant Based Protein ' },
			{ value: ' Online Tutoring ', label: ' Online Tutoring ' },
			{ value: ' AR VR in Retail ', label: ' AR VR in Retail ' },
			{ value: ' AR VR in Healthcare ', label: ' AR VR in Healthcare ' },
			{ value: ' AR VR in Education ', label: ' AR VR in Education ' },
			{ value: ' AR VR in Real Estate ', label: ' AR VR in Real Estate ' },
			{ value: ' Remote Work Tools ', label: ' Remote Work Tools ' },
			{ value: ' Hospital Chains ', label: ' Hospital Chains ' },
			{ value: ' Clinic Chains ', label: ' Clinic Chains ' },
			{ value: ' Biopharma Outsourcing ', label: ' Biopharma Outsourcing ' },
			{ value: ' Cannabis Testing Labs ', label: ' Cannabis Testing Labs ' },
			{ value: ' Cannabis Clinic Chains ', label: ' Cannabis Clinic Chains ' },
			{ value: ' Clinical Laboratory Chains ', label: ' Clinical Laboratory Chains ' },
			{ value: ' Fitness and Wellness Chains ', label: ' Fitness and Wellness Chains ' },
			{ value: ' Pharmaceutical Distributors ', label: ' Pharmaceutical Distributors ' },
			{ value: ' Rehabilitation and Long-Term Care ', label: ' Rehabilitation and Long-Term Care ' },
			{ value: ' Pharmacies ', label: ' Pharmacies ' },
			{ value: ' DTC Genetic Testing ', label: ' DTC Genetic Testing ' },
			{ value: ' Doctor Appointment Booking ', label: ' Doctor Appointment Booking ' },
			{ value: ' Doctor House Call ', label: ' Doctor House Call ' },
			{ value: ' Mental Health Telemedicine ', label: ' Mental Health Telemedicine ' },
			{ value: ' PERS ', label: ' PERS ' },
			{ value: ' Internet First Health Insurers ', label: ' Internet First Health Insurers ' },
			{ value: ' Medical Expenses Financing ', label: ' Medical Expenses Financing ' },
			{ value: ' Tech Heavy Clinics ', label: ' Tech Heavy Clinics ' },
			{ value: ' Cosmetic Procedures Marketplaces ', label: ' Cosmetic Procedures Marketplaces ' },
			{ value: ' Online Medical Tourism ', label: ' Online Medical Tourism ' },
			{ value: ' Online Pharmacies ', label: ' Online Pharmacies ' },
			{ value: ' Online Supplement Stores ', label: ' Online Supplement Stores ' },
			{ value: ' Telepharmacy ', label: ' Telepharmacy ' },
			{ value: ' Baby Health Monitoring ', label: ' Baby Health Monitoring ' },
			{ value: ' Sleep Monitoring ', label: ' Sleep Monitoring ' },
			{ value: ' Fertility Monitoring ', label: ' Fertility Monitoring ' },
			{ value: ' Personal Health Records ', label: ' Personal Health Records ' },
			{ value: ' Caregivers Marketplaces ', label: ' Caregivers Marketplaces ' },
			{ value: ' Internet First Home Healthcare ', label: ' Internet First Home Healthcare ' },
			{ value: ' Online Patient Communities ', label: ' Online Patient Communities ' },
			{ value: ' Symptom Checkers ', label: ' Symptom Checkers ' },
			{ value: ' Veterinary Telemedicine ', label: ' Veterinary Telemedicine ' },
			{ value: ' Online Veterinary Pharmacies ', label: ' Online Veterinary Pharmacies ' },
			{ value: ' Remote Patient Monitoring ', label: ' Remote Patient Monitoring ' },
			{ value: ' Digital Therapeutics ', label: ' Digital Therapeutics ' },
			{ value: ' Pharmacy Automation Systems ', label: ' Pharmacy Automation Systems ' },
			{ value: ' Pharmacy B2B Ecommerce ', label: ' Pharmacy B2B Ecommerce ' },
			{ value: ' Neurological Diagnostics ', label: ' Neurological Diagnostics ' },
			{ value: ' Mental Health Assessment ', label: ' Mental Health Assessment ' },
			{ value: ' Digital Diagnostics Platforms ', label: ' Digital Diagnostics Platforms ' },
			{ value: ' Radiology Image Analysis ', label: ' Radiology Image Analysis ' },
			{ value: ' Non Invasive Diabetes Monitoring ', label: ' Non Invasive Diabetes Monitoring ' },
			{ value: ' Vital Signs Monitoring ', label: ' Vital Signs Monitoring ' },
			{ value: ' Medication Adherence ', label: ' Medication Adherence ' },
			{ value: ' Oncology ', label: ' Oncology ' },
			{ value: ' Diabetes ', label: ' Diabetes ' },
			{ value: ' Orthopedics ', label: ' Orthopedics ' },
			{ value: ' Women Health ', label: ' Women Health ' },
			{ value: ' Cardiac and Vascular Disorders ', label: ' Cardiac and Vascular Disorders ' },
			{ value: ' Neurology ', label: ' Neurology ' },
			{ value: ' Ophthalmology ', label: ' Ophthalmology ' },
			{ value: ' Metabolic Diseases ', label: ' Metabolic Diseases ' },
			{ value: ' Dermatology ', label: ' Dermatology ' },
			{ value: ' Pulmonology ', label: ' Pulmonology ' },
			{ value: ' Gastroenterology ', label: ' Gastroenterology ' },
			{ value: ' Hematology ', label: ' Hematology ' },
			{ value: ' Infectious Diseases ', label: ' Infectious Diseases ' },
			{ value: ' Sleep Medicine ', label: ' Sleep Medicine ' },
			{ value: ' Rare Diseases ', label: ' Rare Diseases ' },
			{ value: ' Pediatrics ', label: ' Pediatrics ' },
			{ value: ' Obesity ', label: ' Obesity ' },
			{ value: ' Immunology ', label: ' Immunology ' },
			{ value: ' Pain Management ', label: ' Pain Management ' },
			{ value: ' Nephrology ', label: ' Nephrology ' },
			{ value: ' Dental Disorders ', label: ' Dental Disorders ' },
			{ value: ' Geriatrics ', label: ' Geriatrics ' },
			{ value: ' ENT Disorders ', label: ' ENT Disorders ' },
			{ value: ' Imaging Diagnostics ', label: ' Imaging Diagnostics ' },
			{ value: ' Toxicology ', label: ' Toxicology ' },
			{ value: ' Transplant Medicine ', label: ' Transplant Medicine ' },
			{ value: ' Genitourinary Disorders ', label: ' Genitourinary Disorders ' },
			{ value: ' Wound Care ', label: ' Wound Care ' },
			{ value: ' Surgical Instruments ', label: ' Surgical Instruments ' },
			{ value: ' Hospital Equipment ', label: ' Hospital Equipment ' },
			{ value: ' Drug Delivery ', label: ' Drug Delivery ' },
			{ value: ' Medical Devices ', label: ' Medical Devices ' },
			{ value: ' Biopharmaceuticals ', label: ' Biopharmaceuticals ' },
			{ value: ' In Vitro Diagnostics ', label: ' In Vitro Diagnostics ' },
			{ value: ' Clinical Genomics ', label: ' Clinical Genomics ' },
			{ value: ' Gene Therapy ', label: ' Gene Therapy ' },
			{ value: ' Microbiome Therapeutics ', label: ' Microbiome Therapeutics ' },
			{ value: ' Regenerative Medicine ', label: ' Regenerative Medicine ' },
			{ value: ' Cell Therapy ', label: ' Cell Therapy ' },
			{ value: ' Immunotherapy ', label: ' Immunotherapy ' },
			{ value: ' Life Sciences Tech ', label: ' Life Sciences Tech ' },
			{ value: ' Genomics ', label: ' Genomics ' },
			{ value: ' Life Sciences Platforms and Tools ', label: ' Life Sciences Platforms and Tools ' },
			{ value: ' Nutraceuticals Tech ', label: ' Nutraceuticals Tech ' },
			{ value: ' Cannabis CRO Services ', label: ' Cannabis CRO Services ' },
			{ value: ' Anti Aging ', label: ' Anti Aging ' },
			{ value: ' Liquid Biopsy ', label: ' Liquid Biopsy ' }
			]
		} else if (industry == "Hotel, Resort & Restaurants") {
			optionSector = [{ value: ' Restaurants ', label: ' Restaurants ' },
			{ value: ' Hotel Aggregators ', label: ' Hotel Aggregators ' },
			{ value: ' Online Travel Agencies ', label: ' Online Travel Agencies ' },
			{ value: ' Vacation Rentals ', label: ' Vacation Rentals ' },
			{ value: ' Corporate Travel ', label: ' Corporate Travel ' },
			{ value: ' Holiday Packages ', label: ' Holiday Packages ' },
			{ value: ' Global Distribution Systems ', label: ' Global Distribution Systems ' },
			{ value: ' Tours & Activities ', label: ' Tours & Activities ' },
			{ value: ' Online Bus Ticketing ', label: ' Online Bus Ticketing ' },
			{ value: ' Online Private Charter Booking ', label: ' Online Private Charter Booking ' },
			{ value: ' Travelogue Sharing ', label: ' Travelogue Sharing ' },
			{ value: ' Hotel Channel Management Software ', label: ' Hotel Channel Management Software ' },
			{ value: ' Vacation Rentals Channel Management Software ', label: ' Vacation Rentals Channel Management Software ' },
			{ value: ' Hotel Data Analytics Software ', label: ' Hotel Data Analytics Software ' },
			{ value: ' Hotel Marketing Software ', label: ' Hotel Marketing Software ' },
			{ value: ' B2B Hotel Distribution Network ', label: ' B2B Hotel Distribution Network ' },
			{ value: ' Hotel Management Software ', label: ' Hotel Management Software ' },
			{ value: ' Vacation Rentals Management Software ', label: ' Vacation Rentals Management Software ' },
			{ value: ' Hotel Revenue Management Software ', label: ' Hotel Revenue Management Software ' },
			{ value: ' VR Theme Parks ', label: ' VR Theme Parks ' },
			{ value: ' Online Travel ', label: ' Online Travel ' },
			{ value: ' Road Transport Tech ', label: ' Road Transport Tech ' },
			{ value: ' Aviation IT ', label: ' Aviation IT ' },
			{ value: ' Autonomous Hotels ', label: ' Autonomous Hotels ' },
			{ value: ' Hotel IT ', label: ' Hotel IT ' },
			{ value: ' Rail IT ', label: ' Rail IT ' },
			{ value: ' Smart Public Transport ', label: ' Smart Public Transport ' },
			{ value: ' Theme Parks Tech ', label: ' Theme Parks Tech ' },
			{ value: ' Art and Museum Tech ', label: ' Art and Museum Tech ' },
			{ value: ' Travel Fintech ', label: ' Travel Fintech ' }
			]
		} else if (industry == "HR Tech") {
			optionSector = [{ value: ' HR Tech ', label: ' HR Tech ' },
			{ value: ' Applicant Testing Platforms ', label: ' Applicant Testing Platforms ' },
			{ value: ' Background Screening ', label: ' Background Screening ' },
			{ value: ' Employee Wellness Administration ', label: ' Employee Wellness Administration ' },
			{ value: ' Contingent Workforce Management ', label: ' Contingent Workforce Management ' },
			{ value: ' Blue Collar Job Boards ', label: ' Blue Collar Job Boards ' },
			{ value: ' Job Matching Platforms ', label: ' Job Matching Platforms ' },
			{ value: ' Pre Assessed Candidates ', label: ' Pre Assessed Candidates ' },
			{ value: ' Employee Performance Management ', label: ' Employee Performance Management ' },
			{ value: ' Workforce Analytics ', label: ' Workforce Analytics ' },
			{ value: ' Freelancer Marketplace ', label: ' Freelancer Marketplace ' },
			{ value: ' Job Boards ', label: ' Job Boards ' },
			{ value: ' HRMS for Enterprises ', label: ' HRMS for Enterprises ' },
			{ value: ' HRMS for SMBs ', label: ' HRMS for SMBs ' },
			{ value: ' Recruitment Agency Marketplace ', label: ' Recruitment Agency Marketplace ' },
			{ value: ' Payroll Management ', label: ' Payroll Management ' },
			{ value: ' Career Guidance ', label: ' Career Guidance ' },
			{ value: ' Employee Engagement ', label: ' Employee Engagement ' },
			{ value: ' Candidate Sourcing ', label: ' Candidate Sourcing ' },
			{ value: ' Freelancer Management Platforms ', label: ' Freelancer Management Platforms ' },
			{ value: ' On Demand Staffing Platforms ', label: ' On Demand Staffing Platforms ' },
			{ value: ' Talent as a Service ', label: ' Talent as a Service ' },
			{ value: ' Remote Work Tools ', label: ' Remote Work Tools ' },
			]
		} else if (industry == "Industrial Goods, Manufacturing- Tech & Infrastructure") {
			optionSector = [{ value: ' Industrial Goods, Manufacturing- Tech & Infrastructure ', label: ' Industrial Goods, Manufacturing- Tech & Infrastructure ' },
			{ value: ' Manufacturing Tech ', label: ' Manufacturing Tech ' },
			{ value: ' Industrial Automation Equipment ', label: ' Industrial Automation Equipment ' },
			{ value: ' Industrial Air Conditioning Systems ', label: ' Industrial Air Conditioning Systems ' },
			{ value: ' Packaging Tech ', label: ' Packaging Tech ' },
			{ value: ' Packaging Machinery ', label: ' Packaging Machinery ' },
			{ value: ' Industrial Safety ', label: ' Industrial Safety ' },
			{ value: ' Non Destructive Testing Equipment ', label: ' Non Destructive Testing Equipment ' },
			{ value: ' Destructive Testing Equipment ', label: ' Destructive Testing Equipment ' },
			{ value: ' Packaging and Labeling Services ', label: ' Packaging and Labeling Services ' },
			{ value: ' Mining Tech ', label: ' Mining Tech ' },
			{ value: ' Computerized Maintenance Management System ', label: ' Computerized Maintenance Management System ' },
			{ value: ' Predictive Machine Maintenance ', label: ' Predictive Machine Maintenance ' },
			{ value: ' Computer Aided Manufacturing ', label: ' Computer Aided Manufacturing ' },
			{ value: ' Industrial Quality Control ', label: ' Industrial Quality Control ' },
			{ value: ' Manufacturing Process Analytics ', label: ' Manufacturing Process Analytics ' },
			{ value: ' Computer Aided Design ', label: ' Computer Aided Design ' },
			{ value: ' Electronic Design Automation ', label: ' Electronic Design Automation ' },
			{ value: ' Computer Aided Engineering ', label: ' Computer Aided Engineering ' },
			{ value: ' PLM Software ', label: ' PLM Software ' },
			{ value: ' Smart Factories ', label: ' Smart Factories ' },
			{ value: ' Cloud Manufacturing ', label: ' Cloud Manufacturing ' },
			{ value: ' Datacenter Colocation ', label: ' Datacenter Colocation ' },
			{ value: ' Green Datacenters ', label: ' Green Datacenters ' },
			{ value: ' Open Source Databases ', label: ' Open Source Databases ' },
			]
		} else if (industry == "IT & Robotics") {
			optionSector = [{ value: ' IT ', label: ' IT ' },
			{ value: ' Robotics in Healthcare ', label: ' Robotics in Healthcare ' },
			{ value: ' Robotics in Education ', label: ' Robotics in Education ' },
			{ value: ' Robotics in Agriculture ', label: ' Robotics in Agriculture ' },
			{ value: ' Robotics in Logistics ', label: ' Robotics in Logistics ' },
			{ value: ' Robotics in Manufacturing ', label: ' Robotics in Manufacturing ' },
			{ value: ' Robotics in Retail ', label: ' Robotics in Retail ' },
			{ value: ' Application Delivery Networks ', label: ' Application Delivery Networks ' },
			{ value: ' Content Delivery Network (CDN) ', label: ' Content Delivery Network (CDN) ' },
			{ value: ' Networking Equipment ', label: ' Networking Equipment ' },
			{ value: ' Network Analytics ', label: ' Network Analytics ' },
			{ value: ' Network Performance Monitoring ', label: ' Network Performance Monitoring ' },
			{ value: ' Network OS ', label: ' Network OS ' },
			{ value: ' Network Routing Software ', label: ' Network Routing Software ' },
			{ value: ' Network Functions Virtualization ', label: ' Network Functions Virtualization ' },
			{ value: ' Network Simulators ', label: ' Network Simulators ' },
			{ value: ' SD-WAN ', label: ' SD-WAN ' },
			{ value: ' Software Defined Networking (SDN) ', label: ' Software Defined Networking (SDN) ' },
			{ value: ' Application Performance Monitoring ', label: ' Application Performance Monitoring ' },
			{ value: ' Cloud Management Platforms ', label: ' Cloud Management Platforms ' },
			{ value: ' IT Automation ', label: ' IT Automation ' },
			{ value: ' IT Operations Analytics ', label: ' IT Operations Analytics ' },
			{ value: ' IT Orchestration ', label: ' IT Orchestration ' },
			{ value: ' IT Infrastructure Management ', label: ' IT Infrastructure Management ' },
			{ value: ' Log Management ', label: ' Log Management ' },
			{ value: ' Network Management Tools ', label: ' Network Management Tools ' },
			{ value: ' Server Management Tools ', label: ' Server Management Tools ' },
			{ value: ' Software Asset Management ', label: ' Software Asset Management ' },
			{ value: ' Backup and Disaster Recovery ', label: ' Backup and Disaster Recovery ' },
			{ value: ' IT Incident Management ', label: ' IT Incident Management ' },
			{ value: ' ITSM Suite ', label: ' ITSM Suite ' },
			{ value: ' Application Lifecycle Management ', label: ' Application Lifecycle Management ' },
			{ value: ' Application Performance Monitoring for Developers ', label: ' Application Performance Monitoring for Developers ' },
			{ value: ' Infrastructure as Code ', label: ' Infrastructure as Code ' },
			{ value: ' Continuous Integration ', label: ' Continuous Integration ' },
			{ value: ' Continuous Delivery ', label: ' Continuous Delivery ' },
			{ value: ' Continuous Deployment ', label: ' Continuous Deployment ' },
			{ value: ' DevSecOps ', label: ' DevSecOps ' },
			{ value: ' Database as a Service (DBaaS) ', label: ' Database as a Service (DBaaS) ' },
			{ value: ' Database Management Tools ', label: ' Database Management Tools ' },
			{ value: ' In-Memory Database ', label: ' In-Memory Database ' },
			{ value: ' NoSQL Database ', label: ' NoSQL Database ' },
			{ value: ' Object Database ', label: ' Object Database ' },
			{ value: ' NewSQL Database ', label: ' NewSQL Database ' },
			{ value: ' SQL Database ', label: ' SQL Database ' },
			{ value: ' Graph Database ', label: ' Graph Database ' },
			{ value: ' Product Prototyping Platforms ', label: ' Product Prototyping Platforms ' },
			{ value: ' Requirement Management Software ', label: ' Requirement Management Software ' },
			{ value: ' Product Usage Analytics ', label: ' Product Usage Analytics ' },
			{ value: ' User Feedback Tools ', label: ' User Feedback Tools ' },
			{ value: ' Design Collaboration ', label: ' Design Collaboration ' },
			{ value: ' Automated Testing Tools ', label: ' Automated Testing Tools ' },
			{ value: ' Test Management ', label: ' Test Management ' },
			{ value: ' Crowdsourced Testing ', label: ' Crowdsourced Testing ' },
			{ value: ' Cross Browser Testing ', label: ' Cross Browser Testing ' },
			{ value: ' Security Testing ', label: ' Security Testing ' },
			{ value: ' Defense Contractors ', label: ' Defense Contractors ' },
			{ value: ' Aircraft Manufacturers ', label: ' Aircraft Manufacturers ' },
			{ value: ' Aircraft Engines ', label: ' Aircraft Engines ' },
			{ value: ' Cloud Backup Storage ', label: ' Cloud Backup Storage ' },
			{ value: ' Software Defined Storage ', label: ' Software Defined Storage ' },
			{ value: ' Network Attached Storage (NAS) ', label: ' Network Attached Storage (NAS) ' },
			{ value: ' Storage Management ', label: ' Storage Management ' },
			{ value: ' All Flash Arrays (AFA) ', label: ' All Flash Arrays (AFA) ' },
			{ value: ' Hyper Converged Infrastructure ', label: ' Hyper Converged Infrastructure ' },
			{ value: ' Collaboration Tools for Field Workers ', label: ' Collaboration Tools for Field Workers ' },
			{ value: ' Field Sales Software ', label: ' Field Sales Software ' },
			{ value: ' Field Workforce Management ', label: ' Field Workforce Management ' },
			{ value: ' Unmanned Surface Vehicles ', label: ' Unmanned Surface Vehicles ' },
			{ value: ' Robot Control System ', label: ' Robot Control System ' },
			{ value: ' Robots for Security and Surveillance ', label: ' Robots for Security and Surveillance ' },
			]
		} else if (industry == "ITES & Internet of Things ") {
			optionSector = [{ value: ' ITES ', label: ' ITES ' },
			{ value: ' Smart Homes ', label: ' Smart Homes ' },
			{ value: ' Wearable Technology ', label: ' Wearable Technology ' },
			{ value: ' Smart Cities ', label: ' Smart Cities ' },
			{ value: ' Digital Twin ', label: ' Digital Twin ' },
			{ value: ' IoT in Healthcare ', label: ' IoT in Healthcare ' },
			{ value: ' IoT In Automotive ', label: ' IoT In Automotive ' },
			{ value: ' IoT in Retail ', label: ' IoT in Retail ' },
			{ value: ' IoT in Agriculture ', label: ' IoT in Agriculture ' },
			{ value: ' IoT in Manufacturing ', label: ' IoT in Manufacturing ' },
			{ value: ' IoT in Food ', label: ' IoT in Food ' },
			{ value: ' Connected Vehicles ', label: ' Connected Vehicles ' },
			{ value: ' Edge Computing ', label: ' Edge Computing ' },
			{ value: ' IoT Applications ', label: ' IoT Applications ' },
			{ value: ' Smart Buildings ', label: ' Smart Buildings ' },
			{ value: ' Blockchain IoT ', label: ' Blockchain IoT ' },
			{ value: ' Industrial Internet of Things ', label: ' Industrial Internet of Things ' },
			{ value: ' IoT in Sports ', label: ' IoT in Sports ' },
			{ value: ' Consumer Internet of Things ', label: ' Consumer Internet of Things ' },
			{ value: ' IT Risk Management ', label: ' IT Risk Management ' },
			{ value: ' Integrated GRC Software ', label: ' Integrated GRC Software ' },
			{ value: ' Access Governance ', label: ' Access Governance ' },
			{ value: ' Environmental Health and Safety Compliance ', label: ' Environmental Health and Safety Compliance ' },
			{ value: ' Integrated Compliance Management ', label: ' Integrated Compliance Management ' },
			{ value: ' Data Security ', label: ' Data Security ' },
			{ value: ' Data Governance ', label: ' Data Governance ' },
			{ value: ' Data Privacy Software ', label: ' Data Privacy Software ' },
			{ value: ' Risk Monitoring in Financial Services ', label: ' Risk Monitoring in Financial Services ' },
			{ value: ' Anti Money Laundering Software ', label: ' Anti Money Laundering Software ' },
			{ value: ' Big Data in Financial Services ', label: ' Big Data in Financial Services ' },
			{ value: ' Big Data in Healthcare ', label: ' Big Data in Healthcare ' },
			{ value: ' Big Data in Retail ', label: ' Big Data in Retail ' },
			{ value: ' Big Data in Advertising and Marketing ', label: ' Big Data in Advertising and Marketing ' },
			{ value: ' Big Data in Manufacturing ', label: ' Big Data in Manufacturing ' },
			{ value: ' Big Data in Cybersecurity ', label: ' Big Data in Cybersecurity ' },
			{ value: ' Communication ICs ', label: ' Communication ICs ' },
			{ value: ' IoT System On Chips ', label: ' IoT System On Chips ' },
			{ value: ' IoT Network Security ', label: ' IoT Network Security ' },
			{ value: ' IoT Device Security ', label: ' IoT Device Security ' },
			{ value: ' IoT Data Analytics ', label: ' IoT Data Analytics ' },
			{ value: ' IoT Sensors ', label: ' IoT Sensors ' },
			{ value: ' IoT Device Management ', label: ' IoT Device Management ' },
			{ value: ' IoT Cellular Connectivity ', label: ' IoT Cellular Connectivity ' },
			{ value: ' IoT Infrastructure Development Platforms ', label: ' IoT Infrastructure Development Platforms ' },
			{ value: ' Fog Computing Platforms ', label: ' Fog Computing Platforms ' },
			{ value: ' IoT System On Chips ', label: ' IoT System On Chips ' },
			{ value: ' Source Code Management ', label: ' Source Code Management ' },
			{ value: ' Cross Platform IDEs ', label: ' Cross Platform IDEs ' },
			{ value: ' Low Code Development Platforms ', label: ' Low Code Development Platforms ' },
			{ value: ' Website Builders ', label: ' Website Builders ' },
			{ value: ' Automated Code Review ', label: ' Automated Code Review ' },
			{ value: ' Sleep Tech using BCI ', label: ' Sleep Tech using BCI ' },
			{ value: ' Wellness Devices using BCI ', label: ' Wellness Devices using BCI ' },
			{ value: ' Invasive Brain Implants ', label: ' Invasive Brain Implants ' },
			{ value: ' Brain Computer Interface for Diagnostics ', label: ' Brain Computer Interface for Diagnostics ' },
			{ value: ' Internet First Point of Sale Financing ', label: ' Internet First Point of Sale Financing ' },
			{ value: ' Internet First Invoice Financing ', label: ' Internet First Invoice Financing ' },
			{ value: ' Internet First Personal Loans ', label: ' Internet First Personal Loans ' },
			{ value: ' Peer to Peer Lending ', label: ' Peer to Peer Lending ' },
			{ value: ' Consumer Credit Score Management ', label: ' Consumer Credit Score Management ' },
			{ value: ' Alternative Credit Score Providers ', label: ' Alternative Credit Score Providers ' },
			{ value: ' Online Loan Comparison Platforms ', label: ' Online Loan Comparison Platforms ' },
			{ value: ' Internet First Term Loans for Businesses ', label: ' Internet First Term Loans for Businesses ' },
			{ value: ' Internet First Working Capital Financing ', label: ' Internet First Working Capital Financing ' },
			{ value: ' Internet First Education Loans ', label: ' Internet First Education Loans ' },
			{ value: ' Internet First Payday Loans ', label: ' Internet First Payday Loans ' },
			{ value: ' Internet First Residential Mortgage Providers ', label: ' Internet First Residential Mortgage Providers ' },
			{ value: ' B2B Online Loan Platforms ', label: ' B2B Online Loan Platforms ' },
			{ value: ' Secondary Ticketing Platforms ', label: ' Secondary Ticketing Platforms ' },
			{ value: ' Enterprise Ticketing Platforms ', label: ' Enterprise Ticketing Platforms ' },
			{ value: ' Data Preparation Platforms ', label: ' Data Preparation Platforms ' },
			{ value: ' Enterprise Search ', label: ' Enterprise Search ' },
			{ value: ' Call Analytics ', label: ' Call Analytics ' },
			{ value: ' Natural Language Generation ', label: ' Natural Language Generation ' },
			{ value: ' Data Visualization ', label: ' Data Visualization ' },
			{ value: ' Image and Video Analytics ', label: ' Image and Video Analytics ' },
			{ value: ' Text Analytics ', label: ' Text Analytics ' },
			]
		} else if (industry == "Logistics") {
			optionSector = [{ value: 'Logistics', label: 'Logistics' },
			]
		} else if (industry == "LPG") {
			optionSector = [{ value: 'LPG', label: 'LPG' },
			]
		} else if (industry == "Marketing and Sales -Tech") {
			optionSector = [{ value: 'Marketing and Sales -Tech', label: 'Marketing and Sales -Tech' },
			]
		} else if (industry == "Media, Entertainment & Travel") {
			optionSector = [{ value: 'Media, Entertainment & Travel', label: 'Media, Entertainment & Travel' },
			]
		} else if (industry == "Medical & Medical Tech'") {
			optionSector = [{ value: 'Medical & Medical Tech', label: 'Medical & Medical Tech' },
			]
		} else if (industry == "Metals") {
			optionSector = [{ value: 'Metals', label: 'Metals' },
			]
		} else if (industry == "Others- Trending Themes") {
			optionSector = [{ value: 'Others- Trending Themes', label: 'Others- Trending Themes' },
			]
		} else if (industry == "Oil Exploration & production'") {
			optionSector = [{ value: 'Oil Exploration & production', label: 'Oil Exploration & production' },
			]
		} else if (industry == "Online Services- Fitness, Gig Economy, Gaming & Wedding Tech") {
			optionSector = [{ value: 'Online Services- Fitness, Gig Economy, Gaming & Wedding Tech', label: 'Online Services- Fitness, Gig Economy, Gaming & Wedding Tech' },
			]
		} else if (industry == "Pacakging") {
			optionSector = [{ value: 'Pacakging', label: 'Pacakging' },
			]
		} else if (industry == "Paints") {
			optionSector = [{ value: 'Paints', label: 'Paints' },
			]
		} else if (industry == "Paper, Paper Products & printing") {
			optionSector = [{ value: 'Paper, Paper Products & printing', label: 'Paper, Paper Products & printing' },
			]
		} else if (industry == "Plastics") {
			optionSector = [{ value: 'Plastics', label: 'Plastics' },
			]
		} else if (industry == "Ports & Shipping") {
			optionSector = [{ value: 'Ports & Shipping', label: 'Ports & Shipping' },
			]
		} else if (industry == "Real Estate") {
			optionSector = [{ value: 'Real Estate', label: 'Real Estate' },
			]
		} else if (industry == "Retail") {
			optionSector = [{ value: 'Retail', label: 'Retail' },
			]
		} else if (industry == "Semi- Conductors & Artificial Intelligence") {
			optionSector = [{ value: 'Semi- Conductors & Artificial Intelligence', label: 'Semi- Conductors & Artificial Intelligence' },
			]
		} else if (industry == "Tech") {
			optionSector = [{ value: 'Tech', label: 'Tech' },
			]
		} else if (industry == "Telecom, Mobile & Tele-Communication") {
			optionSector = [{ value: 'Telecom, Mobile & Tele-Communication', label: 'Telecom, Mobile & Tele-Communication' },
			]
		} else if (industry == "Textiles") {
			optionSector = [{ value: 'Textiles', label: 'Textiles' },
			]
		} else if (industry == "TV & Web Broadcasting") {
			optionSector = [{ value: 'TV & Web Broadcasting', label: 'TV & Web Broadcasting' },
			]
		} else if (industry == "Wood") {
			optionSector = [{ value: 'Wood', label: 'Wood' },
			]
		}
		setSectorValues(optionSector)
		console.log(optionSector)
	}, [industry])
	console.log(optionSector)
	const searchProperty = async () => {
		let dataParams = "";
		if (companyName) {
			if (dataParams == "") {
				dataParams = `companyName=${companyName}`
			} else {
				dataParams = `${dataParams}&companyName=${companyName}`
			}
		}
		if (investmentType) {
			if (dataParams == "") {
				dataParams = `investmentType=${investmentType}`
			} else {
				dataParams = `${dataParams}&investmentType=${investmentType}`
			}
		}
		if (industry) {
			if (dataParams == "") {
				dataParams = `industry=${industry}`
			} else {
				console.log("in payload", industry)
				// if(industry.includes('&')){
				// 	console.log("in payload",industry)
				// 	industry = industry.replace("&","%26")
				// 	console.log("in payload",industry)
				// }
				dataParams = `${dataParams}&industry=${encodeURIComponent(industry)}`
			}
		}
		if (sector) {
			if (dataParams == "") {
				dataParams = `sector=${sector}`
			} else {
				dataParams = `${dataParams}&sector=${sector}`
			}
		}

		dataParams = `${dataParams}&typeSearch=aifSearch`


		if (dataParams.includes(' & ')) {
			dataParams = dataParams.replace("&", "%26")
		}
		console.log("Data Params", dataParams)

		let config = {
			method: 'get',
			// url: `https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/getrealstate?minPrice=${minPrice}&maxPrice=${maxPrice}&propertyType=${propertyType}&country=${country}&city=${city}&states=${state}`,
			url: `https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/getrealstate?${dataParams}`,

			// headers: { "Content-Type": "application/json", "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token", "Access-Control-Allow-Methods": "OPTIONS,POST,GET", "Access-Control-Allow-Credentials": true, "Access-Control-Allow-Origin": "*", "X-Requested-With": "*" }
			headers: {
				// "Access-Control-Allow-Headers": "*",
				// "Content-Type": "application/json",
				// "Access-Control-Allow-Origin": "*"
			}
			// headers: {
			// 	"Content-Type": "application/json",
			// 	"Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
			// 	"Access-Control-Allow-Methods": "OPTIONS,POST,GET",
			// 	"Access-Control-Allow-Credentials": true,
			// 	"Access-Control-Allow-Origin": "*",
			// 	"X-Requested-With": "*"
			// }
		};
		axios(config)
			.then(function (response) {
				console.log("aif search fields", JSON.stringify(response.data));
				setFetchedAIFData(response.data.data)
			})
			.catch(function (error) {
				console.log(error);
			});

		console.log("Data inserted")
		// const res = await fetch(`https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/getrealstate?minPrice=${minPrice}&maxPrice=${maxPrice}&propertyType=${propertyType}&country=${country}&city=${city}&states=${state}`);
		// console.log("Data fetched", res)
	}

	// const handleChange = (e) => {
	// 	setPropertyType(e.target.value)
	// 	console.log("event inside handle change", e.target.value)
	// }

	return (
		<div className="ltn__slider-area ltn__slider-4">
			<div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">
				{/* ltn__slide-item */}
				<div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-4 bg-image bg-overlay-theme-black-60" data-bs-bg={publicUrl + "assets/img/slider/11.jpg"}>
					<div className="ltn__slide-item-inner text-left">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 align-self-center">
									<div className="slide-item-car-dealer-form">
										<div className="ltn__car-dealer-form-tab">
											<div className="ltn__tab-menu  ">
												<div className="nav margin-home" >
													<div className='row'>
														<div className='col-lg-3'><a className="active show text-uppercase" data-bs-toggle="tab" href="#ltn__form_tab_1_1"><i className="fas fa-home" />Invest Market</a> </div>
														<div className='col-lg-9' style={{ fontSize: 14, color: "#000", textAlign: "center" }}><span style={{ fontWeight: 600, fontSize: 23 }}>India's #1 Investment Marketplace</span><br />Start Investing in Alternative investment options like Franchises, Corporate Bonds, Asset leasing, Private Equity, etc. directly from the reputed companies. </div>
													</div>

													{/* <a data-bs-toggle="tab" href="#ltn__form_tab_1_2" className><i className="fas fa-home" />Sale Home</a> */}
												</div>
											</div>

											<Carousel
												ssr
												partialVisbile
												deviceType={"desktop"}
												itemClass="image-item"
												responsive={responsive}
											>
												{
													recommendedProduct.map((product, index) => {
														console.log("recommended product inside map", recommendedProduct)
														return (<div>
															<div class="card" onClick={()=>{
																navigate(`${product.segment=="real-estate" ? "/product-realstate-details?id=":"/product-aif-details?id="}${product?._id}`, { state: product })
															}}>
																<img src={product?.pictures[0]?.url} alt="Avatar" style={{ width: "auto", height: 250 }} />
																<div class="container">
																<p style={{fontWeight:"bold",color:"#963636"}}> {product.segment=="aif"?product.investmentType:product.propertyType}</p>
																	<h4><b>{product.segment=="aif"?product.companyName : product.developer}</b></h4>
																	<p><i className="flaticon-pin" /> &nbsp;{product.segment=="aif"?product.industry : product.country}</p>
																	<p style={{fontWeight:"bold",color:"#963636"}}>&#8377; {product.minPrice} - &#8377; {product.maxPrice}</p>
																</div>
															</div>
														</div>)
													})
												}
												{/* <div>
													<div class="card">
														
														<div class="container">
															<h4><b>John Doe</b></h4>
															<p>Architect & Engineer</p>
														</div>
													</div>
												</div>
												<div>Item 2
													<img src="https://images.unsplash.com/photo-1550064824-8f993041ffd3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60" />
												</div>
												<div>Item 3
													<img src="https://images.unsplash.com/photo-1550064824-8f993041ffd3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60" />
												</div>
												<div>Item 4
													<img src="https://images.unsplash.com/photo-1550064824-8f993041ffd3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60" />
												</div> */}
											</Carousel>


											<div className="tab-content">
												<div className="tab-pane fade active show" id="ltn__form_tab_1_1">
													<div className="car-dealer-form-inner">
														<div className=" row">
															<div className="car-price-filter-range col-lg-12" style={{ marginTop: 25 }}>
																<input type="text" className="companyName" id='input11' name="companyName" placeholder="Search by Company Name" onChange={(e) => {
																	console.log("event value", e.target.value)
																	setCompanyName(e.target.value)
																}} />
															</div>
															<label>Investment Type</label>
															<Select options={optionsInvestmentType} onChange={(val) => {
																console.log("property type option", val.value)
																setInvestmentType(val.value)
															}} />
															<label>Industry Type</label>
															<Select options={optionsIndustry} onChange={(value) => {
																console.log("property type option", value)
																setIndustry(value.label)
															}} />


															<label>Sector</label>
															<Select options={sectorValues} onChange={(value) => {
																console.log("property type option", value)
																setSector(value.label)
															}} />

															<div className="car-price-filter-range col-lg-12" style={{ marginTop: 25 }}>
																{/* <div className="price_filter">
																	<div className="price_slider_amount"> */}

																{/* <input type="submit" defaultValue="Your range:" onChange={(e)=>console.log("event value 1",e.target.value)} onClick={}/> */}

																{/* </div> */}

																{/* <div className="slider-range" id='range' onChange={(e)=>console.log("event value 1",e.target.value)}/> */}
																{/* </div> */}

																<div className="btn-wrapper text-center go-top">

																	<button className="btn theme-btn-1 btn-effect-1 text-uppercase" onClick={searchProperty}>Search</button>
																</div>
															</div>
														</div>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BannerV5

