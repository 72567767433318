import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import AddListing from './section-components/Add-listing';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import AddlistingAIFSec from './section-components/Add-listing-aif'

const AddListing_Aif = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="AIF" />
        <AddlistingAIFSec />
        {/* <CallToActionV1 />
        <Footer /> */}
    </div>
}

export default AddListing_Aif

