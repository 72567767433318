import React from 'react'
import App from '../App'
import { RootProvider } from '../provider/RootProvider'


const AppContextRoute = () => {
    return (
        <RootProvider>
            <App />
        </RootProvider>
    )
}

export default AppContextRoute