import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";
import RootProvider from "./provider/RootProvider"
import AppContextRoute from "./contextRoutes/AppContextRoute"
import RealStateDisplay from './components/home-v6'
import ProductDetails from './components/product-details'
import AddlistingAIF from './components/addListingAif'
import SearchAif from './components/aifSearch'
import ProductDetailsAif from './components/product-details-aif'
import AboutUs from './AboutUs';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/admin-realstate" element={<AppContextRoute/>} />
        <Route path="/realstate" element={<RealStateDisplay/>} />
        <Route path="/product-realstate-details" element={<ProductDetails/>} />
        <Route path="/product-aif-details" element={<ProductDetailsAif/>} />
        <Route path="/admin-aif" element={<AddlistingAIF/>} />
        <Route path="/about" element={<AboutUs/>} />
        <Route path="/" element={<SearchAif/>} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('quarter')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
