
import React from 'react'
import AddListing_V1 from "./components/add-listing"
import AddListing_V1_PMS from './components/ass-listing-pms'


const App = () => {
  return (
   
    <div className="App">
      <AddListing_V1/>
    </div>
  )
}

export default App



