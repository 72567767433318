import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ProductSliderAif from './shop-components/product-slider-aif';
import ProductDetailsAIF from './shop-components/shop-details-aif';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useLocation } from 'react-router-dom';
import { useEffect,useState } from 'react';
import axios from 'axios';
import { useLayoutEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const Product_Details_AIF = (props) => {
    const location = useLocation();
    const [productData,setProductData] = useState({})
    // const { state } = props.location
    console.log("Product Details 123",location.state)
    const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('id');
const [open, setOpen] = useState(false);
const [fname,setFName] = useState('')
const [lname,setLName] = useState('')
const [message,setMessage] = useState('')
const [emailSender,setEmailSender] = useState('')
const [phoneNumber,setPhoneNumber] = useState('')

const handleSendEmail = () =>{
    console.log("Inside function")
    let data={
        "interestedIn":productData.companyName,
        "fName":fname,
        "lName":lname,
        "message":message,
        "senderEmail":emailSender,
        "category":"AIF",
        "phoneNum":phoneNumber
    }

    var config = {
        method: 'post',
        url: 'https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/contactus',
        headers: {
            'Content-Type': 'application/json'
            // 'Access-Control-Allow-Headers': 'Content-Type',
            // "Content-Type": "application/json",
            // 'Access-Control-Allow-Methods':'OPTIONS,POST',
            // "Access-Control-Allow-Origin":"*"
            // "X-Requested-With":'*'
            // 'Access-Control-Allow-Origin': '*',
            // 'Content-Type': 'application/json',
        },
        data
    };

    axios(config)
        .then(function (response) {
            onCloseModal()
            // alert('Your account is successfully registered. You can now login with your registered email ID and password.')
            alert("Details Submitted, we will contact ASAP")

        })
        .catch(function (error) {
            console.log(error);
        });
}

const onOpenModal = () => setOpen(true);
const onCloseModal = () => setOpen(false);

console.log("id ____________",myParam,productData)

useLayoutEffect(() => {
    searchProperty(myParam)
}, [myParam]);

const searchProperty = async () => {

    let data =await fetch(`https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/aif?id=${myParam}`).then(response => response.json())
    .then(data => {
        console.log("data aif",data.companyName)
        setProductData(data)
    });
    // console.log("data aif",data)
    
    // let config = {
    //     method: 'get',
    //     // url: `https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/getrealstate?minPrice=${minPrice}&maxPrice=${maxPrice}&propertyType=${propertyType}&country=${country}&city=${city}&states=${state}`,
    //     url: `https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/aif?id=${myParam}`,

    //     // headers: { "Content-Type": "application/json", "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token", "Access-Control-Allow-Methods": "OPTIONS,POST,GET", "Access-Control-Allow-Credentials": true, "Access-Control-Allow-Origin": "*", "X-Requested-With": "*" }
    //     headers: {
    //         "Access-Control-Allow-Headers": "*",
    //         "Content-Type": "application/json",
    //         "Access-Control-Allow-Origin": "*"
    //     }
    //     // headers: {
    //     // 	"Content-Type": "application/json",
    //     // 	"Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
    //     // 	"Access-Control-Allow-Methods": "OPTIONS,POST,GET",
    //     // 	"Access-Control-Allow-Credentials": true,
    //     // 	"Access-Control-Allow-Origin": "*",
    //     // 	"X-Requested-With": "*"
    //     // }
    // };
    // axios(config)
    //     .then(function (response) {
    //         console.log("aif individual",JSON.stringify(response.data));
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     });

    // console.log("Data inserted")
    // const res = await fetch(`https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/getrealstate?minPrice=${minPrice}&maxPrice=${maxPrice}&propertyType=${propertyType}&country=${country}&city=${city}&states=${state}`);
    // console.log("Data fetched", res)
}
    
    return <div>
        {productData?._id && <>
            <div id="mybutton">
					<button class="feedback" onClick={onOpenModal}>Contact Us</button>
				</div>
				<Modal open={open} onClose={onCloseModal} center>
				<div className="col-xl-8 col-md-8 col-sm-8 col-12">
								<div className="footer-widget footer-menu-widget clearfix">
									<h4 className="footer-title">Contact Us
									</h4>
				<div className="footer-menu go-top">
										{/* <ul>
				                <li><Link to="/cart">Cart</Link></li>
				                <li><Link to="/wishlist">Wish List</Link></li>
				                <li><Link to="/login">Login</Link></li>
				                <li><Link to="/checkout">Checkout</Link></li>
				                <li><Link to="/about">Terms &amp; Conditions</Link></li>
				                <li><Link to="/shop">Promotional Offers</Link></li>
				              </ul> */}
										{/* <form> */}
											<label>
												Name:
												<input type="text" name="fname" style={{ height: 35 }} onChange={(e)=>{
													console.log("first name",e.target.value)
													setFName(e.target.value)
												}} required/>
											</label>
											{/* <label>
												Last Name:
												<input type="text" name="lname" style={{ height: 35 }} onChange={(e)=>{
													console.log("first name",e.target.value)
													setLName(e.target.value)
												}} required/>
											</label> */}
											<label>
												Email:
												<input type="email" name="email" style={{ height: 35 }} onChange={(e)=>{
													console.log("first name",e.target.value)
													setEmailSender(e.target.value)
												}} required/>
											</label>
											<label>
												Interested Investment:
												<input type="text" name="message" style={{ height: 35 }} onChange={(e)=>{
													console.log("first name",e.target.value)
													setMessage(e.target.value)
												}} required/>
											</label>
											<label>
											Phone Number:
												<input type="tel" name="message" style={{ height: 35 }} pattern="[0-9]{10}" onChange={(e)=>{
													console.log("first name",e.target.value)
													setPhoneNumber(e.target.value)
												}} required/>
											</label>
											{/* <label>
												Phone Number:
												<input type="tel" name="message" style={{ height: 35,marginBottom:25 }} pattern="[0-9]{10}" onChange={(e)=>{
													console.log("first name",e.target.value)
													// setPhoneNumber(e.target.value)
												}} required/>
											</label> */}
											<input type="button" value="Submit" onClick={handleSendEmail} />
										{/* </form> */}
									</div>
									</div>
									</div>
								
				</Modal>
        <Navbar product={productData} />
        <PageHeader headertitle="Product Details" customclass="mb-0" />
        <ProductSliderAif product={productData}  />
        <ProductDetailsAIF product={productData} />
        {/* <CallToActionV1 product={location.state} /> */}
        <Footer interested={productData.companyName} category={"AIF"}/>
        </>}
        
    </div>
}

export default Product_Details_AIF

