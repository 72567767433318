import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import RootContext from '../../provider/RootProvider';
import ProductDetails from '../product-details'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import axios from 'axios';


const ProductListingAIF = () => {
	let publicUrl = process.env.PUBLIC_URL + '/'
	const { fetchedAIFData, setFetchedAIFData } = useContext(RootContext);
	const [individualRealStateData, setIndividualRealStateData] = useState(null)
	const [ifClicked, setIfClicked] = useState(false);
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [fname,setFName] = useState('')
	const [lname,setLName] = useState('')
	const [message,setMessage] = useState('')
	const [emailSender,setEmailSender] = useState('')
	const [phoneNumber,setPhoneNumber] = useState('')
	
	const handleSendEmail = () =>{
		console.log("Inside function")
		let data={
			"interestedIn":"In AIF Listing Page",
			"fName":fname,
			"lName":lname,
			"message":message,
			"senderEmail":emailSender,
			"category":"AIF",
			"phoneNum":phoneNumber
		}
	
		var config = {
			method: 'post',
			url: 'https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/contactus',
			headers: {
				'Content-Type': 'application/json'
				// 'Access-Control-Allow-Headers': 'Content-Type',
				// "Content-Type": "application/json",
				// 'Access-Control-Allow-Methods':'OPTIONS,POST',
				// "Access-Control-Allow-Origin":"*"
				// "X-Requested-With":'*'
				// 'Access-Control-Allow-Origin': '*',
				// 'Content-Type': 'application/json',
			},
			data
		};
	
		axios(config)
			.then(function (response) {
				onCloseModal()
				// alert('Your account is successfully registered. You can now login with your registered email ID and password.')
				alert("Details Submitted, we will contact ASAP")
	
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);
	useEffect(() => {

	}, [fetchedAIFData]);
	console.log("fetched real state data", fetchedAIFData)

	return (
		<div>
			<div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-70">
			<div id="mybutton">
					<button class="feedback" onClick={onOpenModal}>Contact Us</button>
				</div>
				<Modal open={open} onClose={onCloseModal} center>
				<div className="col-xl-8 col-md-8 col-sm-8 col-12">
								<div className="footer-widget footer-menu-widget clearfix">
									<h4 className="footer-title">Contact Us
									</h4>
				<div className="footer-menu go-top">
										{/* <ul>
				                <li><Link to="/cart">Cart</Link></li>
				                <li><Link to="/wishlist">Wish List</Link></li>
				                <li><Link to="/login">Login</Link></li>
				                <li><Link to="/checkout">Checkout</Link></li>
				                <li><Link to="/about">Terms &amp; Conditions</Link></li>
				                <li><Link to="/shop">Promotional Offers</Link></li>
				              </ul> */}
										{/* <form> */}
											<label>
												Name:
												<input type="text" name="fname" style={{ height: 35 }} onChange={(e)=>{
													console.log("first name",e.target.value)
													setFName(e.target.value)
												}} required/>
											</label>
											{/* <label>
												Last Name:
												<input type="text" name="lname" style={{ height: 35 }} onChange={(e)=>{
													console.log("first name",e.target.value)
													setLName(e.target.value)
												}} required/>
											</label> */}
											<label>
												Email:
												<input type="email" name="email" style={{ height: 35 }} onChange={(e)=>{
													console.log("first name",e.target.value)
													setEmailSender(e.target.value)
												}} required/>
											</label>
											<label>
											Interested Investment:
												<input type="text" name="message" style={{ height: 35 }} onChange={(e)=>{
													console.log("first name",e.target.value)
													setMessage(e.target.value)
												}} required/>
											</label>
											<label>
											Phone Number:
												<input type="tel" name="message" style={{ height: 35 }} pattern="[0-9]{10}" onChange={(e)=>{
													console.log("first name",e.target.value)
													setPhoneNumber(e.target.value)
												}} required/>
											</label>
											{/* <label>
												Phone Number:
												<input type="tel" name="message" style={{ height: 35,marginBottom:25 }} pattern="[0-9]{10}" onChange={(e)=>{
													console.log("first name",e.target.value)
													// setPhoneNumber(e.target.value)
												}} required/>
											</label> */}
											<input type="button" value="Submit" onClick={handleSendEmail} />
										{/* </form> */}
									</div>
									</div>
									</div>
								
				</Modal>
				<div className="container margin-home">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title-area ltn__section-title-2--- text-center">
								<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Deals</h6>
								<h1 className="section-title">Related Deals</h1>
							</div>
						</div>
					</div>
					<div className="row ltn__product-slider-item-three-active--- slick-arrow-1">
						{/* ltn__product-item */}

						{
							fetchedAIFData?.Items?.map((realState, index) => {
								console.log("single real state", realState)
								return <div className="col-lg-4 col-md-6 "> <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
									<div className="product-img go-top">
										{/* <Link to={{
											pathname: "/product-realstate-details",
											state: realState // your data array of objects
										}}> */}
											<img src={realState?.productPicture[0]?.url} alt="#" onClick={() => {
											// setIndividualRealStateData(fetchedRealStateData.Items)
											// setIfClicked(true)
											navigate(`/product-aif-details?id=${realState?._id}`,{state:realState})
										}} />
										{/* </Link> */}
										<div className="real-estate-agent">
											{/* <div className="agent-img">
									<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
									</div> */}
										</div>
									</div>
									<div className="product-info">
										<div className="product-badge">
											<ul>
												<li className="sale-badg">{realState.investmentType}</li>
											</ul>
										</div>
										<h2 className="product-title go-top">{realState.companyName}</h2>
										<div className="product-img-location">
											<ul>
												<li>
													<i className="flaticon-pin" /> {realState.industry}
												</li>
											</ul>
										</div>
										{/* <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
									<li><span>3 </span>
									Bed
									</li>
									<li><span>2 </span>
									Bath
									</li>
									<li><span>3450 </span>
									Square Ft
									</li>
								</ul> */}
										{/* <div className="product-hover-action">
									<ul>
									<li>
										<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
										<i className="flaticon-expand" />
										</a>
									</li>
									<li>
										<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
										<i className="flaticon-heart-1" /></a>
									</li>
									<li className="go-top">
										<Link to="/product-details" title="Product Details">
										<i className="flaticon-add" />
										</Link>
									</li>
									</ul>
								</div> */}
									</div>
									<div className="product-info-bottom">
										<div className="product-price">
											<span>₹ {realState.minPrice} - ₹ {realState.maxPrice}<label></label></span>
										</div>
									</div>

									{/* ltn__product-item */}

									{/*  */}
								</div>
								</div>
							})
						}

					</div>
				</div>
			</div>
			{/* {
				ifClicked && <ProductDetails
					realStataData={individualRealStateData}
				/>
			} */}
		</div>

	);
}

export default ProductListingAIF;


// class ProductListingV1 extends Component {

//     render() {

//         let publicUrl = process.env.PUBLIC_URL+'/'

//     return   <div>
// 				<div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-70">
// 					<div className="container">
// 					<div className="row">
// 						<div className="col-lg-12">
// 						<div className="section-title-area ltn__section-title-2--- text-center">
// 							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Property</h6>
// 							<h1 className="section-title">Latest Listings</h1>
// 						</div>
// 						</div>
// 					</div>
// 					<div className="row ltn__product-slider-item-three-active--- slick-arrow-1">
// 						{/* ltn__product-item */}
// 						<div className="col-xl-4 col-sm-6 col-12">
// 						<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
// 							<div className="product-img go-top">
// 							<Link to="/product-details"><img src={publicUrl+"assets/img/product-3/1.jpg"} alt="#" /></Link>
// 							<div className="real-estate-agent">
// 								<div className="agent-img">
// 								<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
// 								</div>
// 							</div>
// 							</div>
// 							<div className="product-info">
// 							<div className="product-badge">
// 								<ul>
// 								<li className="sale-badg">For Rent</li>
// 								</ul>
// 							</div>
// 							<h2 className="product-title go-top"><Link to="/product-details">New Apartment Nice View</Link></h2>
// 							<div className="product-img-location">
// 								<ul>
// 								<li>
// 									<Link to="/contact"><i className="flaticon-pin" /> Belmont Gardens, Chicago</Link>
// 								</li>
// 								</ul>
// 							</div>
// 							<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
// 								<li><span>3 </span>
// 								Bed
// 								</li>
// 								<li><span>2 </span>
// 								Bath
// 								</li>
// 								<li><span>3450 </span>
// 								Square Ft
// 								</li>
// 							</ul>
// 							<div className="product-hover-action">
// 								<ul>
// 								<li>
// 									<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
// 									<i className="flaticon-expand" />
// 									</a>
// 								</li>
// 								<li>
// 									<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
// 									<i className="flaticon-heart-1" /></a>
// 								</li>
// 								<li className="go-top">
// 									<Link to="/product-details" title="Product Details">
// 									<i className="flaticon-add" />
// 									</Link>
// 								</li>
// 								</ul>
// 							</div>
// 							</div>
// 							<div className="product-info-bottom">
// 							<div className="product-price">
// 								<span>$34,900<label>/Month</label></span>
// 							</div>
// 							</div>
// 						</div>
// 						</div>
// 						{/* ltn__product-item */}
// 						<div className="col-xl-4 col-sm-6 col-12">
// 						<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
// 							<div className="product-img go-top">
// 							<Link to="/product-details"><img src={publicUrl+"assets/img/product-3/2.jpg"} alt="#" /></Link>
// 							<div className="real-estate-agent">
// 								<div className="agent-img">
// 								<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
// 								</div>
// 							</div>
// 							</div>
// 							<div className="product-info">
// 							<div className="product-badge">
// 								<ul>
// 								<li className="sale-badg">For Sale</li>
// 								</ul>
// 							</div>
// 							<h2 className="product-title go-top"><Link to="/product-details">New Apartment Nice View</Link></h2>
// 							<div className="product-img-location">
// 								<ul>
// 								<li>
// 									<Link to="/contact"><i className="flaticon-pin" /> Belmont Gardens, Chicago</Link>
// 								</li>
// 								</ul>
// 							</div>
// 							<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
// 								<li><span>3 </span>
// 								Bed
// 								</li>
// 								<li><span>2 </span>
// 								Bath
// 								</li>
// 								<li><span>3450 </span>
// 								Square Ft
// 								</li>
// 							</ul>
// 							<div className="product-hover-action">
// 								<ul>
// 								<li>
// 									<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
// 									<i className="flaticon-expand" />
// 									</a>
// 								</li>
// 								<li>
// 									<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
// 									<i className="flaticon-heart-1" /></a>
// 								</li>
// 								<li className="go-top">
// 									<Link to="/product-details" title="Product Details">
// 									<i className="flaticon-add" />
// 									</Link>
// 								</li>
// 								</ul>
// 							</div>
// 							</div>
// 							<div className="product-info-bottom">
// 							<div className="product-price">
// 								<span>$34,900<label>/Month</label></span>
// 							</div>
// 							</div>
// 						</div>
// 						</div>
// 						{/* ltn__product-item */}
// 						<div className="col-xl-4 col-sm-6 col-12">
// 						<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
// 							<div className="product-img go-top">
// 							<Link to="/product-details"><img src={publicUrl+"assets/img/product-3/3.jpg"} alt="#" /></Link>
// 							<div className="real-estate-agent">
// 								<div className="agent-img">
// 								<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
// 								</div>
// 							</div>
// 							</div>
// 							<div className="product-info">
// 							<div className="product-badge">
// 								<ul>
// 								<li className="sale-badg">For Rent</li>
// 								</ul>
// 							</div>
// 							<h2 className="product-title go-top"><Link to="/product-details">New Apartment Nice View</Link></h2>
// 							<div className="product-img-location">
// 								<ul>
// 								<li>
// 									<Link to="/contact"><i className="flaticon-pin" /> Belmont Gardens, Chicago</Link>
// 								</li>
// 								</ul>
// 							</div>
// 							<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
// 								<li><span>3 </span>
// 								Bed
// 								</li>
// 								<li><span>2 </span>
// 								Bath
// 								</li>
// 								<li><span>3450 </span>
// 								Square Ft
// 								</li>
// 							</ul>
// 							<div className="product-hover-action">
// 								<ul>
// 								<li>
// 									<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
// 									<i className="flaticon-expand" />
// 									</a>
// 								</li>
// 								<li>
// 									<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
// 									<i className="flaticon-heart-1" /></a>
// 								</li>
// 								<li className="go-top">
// 									<Link to="/product-details" title="Product Details">
// 									<i className="flaticon-add" />
// 									</Link>
// 								</li>
// 								</ul>
// 							</div>
// 							</div>
// 							<div className="product-info-bottom">
// 							<div className="product-price">
// 								<span>$34,900<label>/Month</label></span>
// 							</div>
// 							</div>
// 						</div>
// 						</div>
// 						{/* ltn__product-item */}
// 						<div className="col-xl-4 col-sm-6 col-12">
// 						<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
// 							<div className="product-img go-top">
// 							<Link to="/product-details"><img src={publicUrl+"assets/img/product-3/4.jpg"} alt="#" /></Link>
// 							<div className="real-estate-agent">
// 								<div className="agent-img">
// 								<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
// 								</div>
// 							</div>
// 							</div>
// 							<div className="product-info">
// 							<div className="product-badge">
// 								<ul>
// 								<li className="sale-badg">For Rent</li>
// 								</ul>
// 							</div>
// 							<h2 className="product-title go-top"><Link to="/product-details">New Apartment Nice View</Link></h2>
// 							<div className="product-img-location">
// 								<ul>
// 								<li>
// 									<Link to="/contact"><i className="flaticon-pin" /> Belmont Gardens, Chicago</Link>
// 								</li>
// 								</ul>
// 							</div>
// 							<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
// 								<li><span>3 </span>
// 								Bed
// 								</li>
// 								<li><span>2 </span>
// 								Bath
// 								</li>
// 								<li><span>3450 </span>
// 								Square Ft
// 								</li>
// 							</ul>
// 							<div className="product-hover-action">
// 								<ul>
// 								<li>
// 									<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
// 									<i className="flaticon-expand" />
// 									</a>
// 								</li>
// 								<li>
// 									<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
// 									<i className="flaticon-heart-1" /></a>
// 								</li>
// 								<li className="go-top">
// 									<Link to="/product-details" title="Product Details">
// 									<i className="flaticon-add" />
// 									</Link>
// 								</li>
// 								</ul>
// 							</div>
// 							</div>
// 							<div className="product-info-bottom">
// 							<div className="product-price">
// 								<span>$34,900<label>/Month</label></span>
// 							</div>
// 							</div>
// 						</div>
// 						</div>
// 						{/* ltn__product-item */}
// 						<div className="col-xl-4 col-sm-6 col-12">
// 						<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
// 							<div className="product-img go-top">
// 							<Link to="/product-details"><img src={publicUrl+"assets/img/product-3/5.jpg"} alt="#" /></Link>
// 							<div className="real-estate-agent">
// 								<div className="agent-img">
// 								<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
// 								</div>
// 							</div>
// 							</div>
// 							<div className="product-info">
// 							<div className="product-badge">
// 								<ul>
// 								<li className="sale-badg">For Rent</li>
// 								</ul>
// 							</div>
// 							<h2 className="product-title go-top"><Link to="/product-details">New Apartment Nice View</Link></h2>
// 							<div className="product-img-location">
// 								<ul>
// 								<li>
// 									<Link to="/contact"><i className="flaticon-pin" /> Belmont Gardens, Chicago</Link>
// 								</li>
// 								</ul>
// 							</div>
// 							<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
// 								<li><span>3 </span>
// 								Bed
// 								</li>
// 								<li><span>2 </span>
// 								Bath
// 								</li>
// 								<li><span>3450 </span>
// 								Square Ft
// 								</li>
// 							</ul>
// 							<div className="product-hover-action">
// 								<ul>
// 								<li>
// 									<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
// 									<i className="flaticon-expand" />
// 									</a>
// 								</li>
// 								<li>
// 									<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
// 									<i className="flaticon-heart-1" /></a>
// 								</li>
// 								<li className="go-top">
// 									<Link to="/product-details" title="Product Details">
// 									<i className="flaticon-add" />
// 									</Link>
// 								</li>
// 								</ul>
// 							</div>
// 							</div>
// 							<div className="product-info-bottom">
// 							<div className="product-price">
// 								<span>$34,900<label>/Month</label></span>
// 							</div>
// 							</div>
// 						</div>
// 						</div>
// 						{/* ltn__product-item */}
// 						<div className="col-xl-4 col-sm-6 col-12">
// 						<div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
// 							<div className="product-img go-top">
// 							<Link to="/product-details"><img src={publicUrl+"assets/img/product-3/6.jpg"} alt="#" /></Link>
// 							<div className="real-estate-agent">
// 								<div className="agent-img">
// 								<Link to="/team-details"><img src={publicUrl+"assets/img/blog/author.jpg"} alt="#" /></Link>
// 								</div>
// 							</div>
// 							</div>
// 							<div className="product-info">
// 							<div className="product-badge">
// 								<ul>
// 								<li className="sale-badg">For Rent</li>
// 								</ul>
// 							</div>
// 							<h2 className="product-title go-top"><Link to="/product-details">New Apartment Nice View</Link></h2>
// 							<div className="product-img-location">
// 								<ul>
// 								<li>
// 									<Link to="/contact"><i className="flaticon-pin" /> Belmont Gardens, Chicago</Link>
// 								</li>
// 								</ul>
// 							</div>
// 							<ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
// 								<li><span>3 </span>
// 								Bed
// 								</li>
// 								<li><span>2 </span>
// 								Bath
// 								</li>
// 								<li><span>3450 </span>
// 								Square Ft
// 								</li>
// 							</ul>
// 							<div className="product-hover-action">
// 								<ul>
// 								<li>
// 									<a href="#" title="Quick View" data-bs-toggle="modal" data-bs-target="#quick_view_modal">
// 									<i className="flaticon-expand" />
// 									</a>
// 								</li>
// 								<li>
// 									<a href="#" title="Wishlist" data-bs-toggle="modal" data-bs-target="#liton_wishlist_modal">
// 									<i className="flaticon-heart-1" /></a>
// 								</li>
// 								<li className="go-top">
// 									<Link to="/product-details" title="Product Details">
// 									<i className="flaticon-add" />
// 									</Link>
// 								</li>
// 								</ul>
// 							</div>
// 							</div>
// 							<div className="product-info-bottom">
// 							<div className="product-price">
// 								<span>$34,900<label>/Month</label></span>
// 							</div>
// 							</div>
// 						</div>
// 						</div>
// 						{/*  */}
// 					</div>
// 					</div>
// 				</div>
// 				<div className="ltn__modal-area ltn__add-to-cart-modal-area">
// 					<div className="modal fade" id="liton_wishlist_modal" tabIndex={-1}>
// 						<div className="modal-dialog modal-md" role="document">
// 						<div className="modal-content">
// 							<div className="modal-header">
// 							<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
// 								<span aria-hidden="true">×</span>
// 							</button>
// 							</div>
// 							<div className="modal-body">
// 							<div className="ltn__quick-view-modal-inner">
// 								<div className="modal-product-item">
// 								<div className="row">
// 									<div className="col-12">
// 									<div className="modal-product-img">
// 										<img src={publicUrl+"assets/img/product/7.png"} alt="#" />
// 									</div>
// 									<div className="modal-product-info go-top">
// 										<h5><Link to="/product-details">Brake Conversion Kit</Link></h5>
// 										<p className="added-cart"><i className="fa fa-check-circle" />  Successfully added to your Wishlist</p>
// 										<div className="btn-wrapper">
// 										<Link to="/wishlist" className="theme-btn-1 btn btn-effect-1">View Wishlist</Link>
// 										</div>
// 									</div>
// 									{/* additional-info */}
// 									<div className="additional-info d-none">
// 										<p>We want to give you <b>10% discount</b> for your first order, <br />  Use discount code at checkout</p>
// 										<div className="payment-method">
// 										<img src={publicUrl+"assets/img/icons/payment.png"} alt="#" />
// 										</div>
// 									</div>
// 									</div>
// 								</div>
// 								</div>
// 							</div>
// 							</div>
// 						</div>
// 						</div>
// 					</div>
// 				</div>

// 				<div className="ltn__modal-area ltn__quick-view-modal-area">
// 					<div className="modal fade" id="quick_view_modal" tabIndex={-1}>
// 						<div className="modal-dialog modal-lg" role="document">
// 						<div className="modal-content">
// 							<div className="modal-header">
// 							<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
// 								<span aria-hidden="true">×</span>
// 								{/* <i class="fas fa-times"></i> */}
// 							</button>
// 							</div>
// 							<div className="modal-body">
// 							<div className="ltn__quick-view-modal-inner">
// 								<div className="modal-product-item">
// 								<div className="row">
// 									<div className="col-lg-6 col-12">
// 									<div className="modal-product-img">
// 										<img src={publicUrl+"assets/img/product/4.png"} alt="#" />
// 									</div>
// 									</div>
// 									<div className="col-lg-6 col-12">
// 									<div className="modal-product-info">
// 										<div className="product-ratting">
// 										<ul>
// 											<li><a href="#"><i className="fas fa-star" /></a></li>
// 											<li><a href="#"><i className="fas fa-star" /></a></li>
// 											<li><a href="#"><i className="fas fa-star" /></a></li>
// 											<li><a href="#"><i className="fas fa-star-half-alt" /></a></li>
// 											<li><a href="#"><i className="far fa-star" /></a></li>
// 											<li className="review-total"> <a href="#"> ( 95 Reviews )</a></li>
// 										</ul>
// 										</div>
// 										<h3>Brake Conversion Kit</h3>
// 										<div className="product-price">
// 										<span>$149.00</span>
// 										<del>$165.00</del>
// 										</div>
// 										<div className="modal-product-meta ltn__product-details-menu-1">
// 										<ul>
// 											<li>
// 											<strong>Categories:</strong> 
// 											<span className="go-top">
// 												<Link to="/blog">Parts</Link>
// 												<Link to="/blog">Car</Link>
// 												<Link to="/blog">Seat</Link>
// 												<Link to="/blog">Cover</Link>
// 											</span>
// 											</li>
// 										</ul>
// 										</div>
// 										<div className="ltn__product-details-menu-2">
// 										<ul>
// 											<li>
// 											<div className="cart-plus-minus">
// 												<input type="text" defaultValue="02" name="qtybutton" className="cart-plus-minus-box" />
// 											</div>
// 											</li>
// 											<li>
// 											<a href="#" className="theme-btn-1 btn btn-effect-1" title="Add to Cart" data-bs-toggle="modal" data-bs-target="#add_to_cart_modal">
// 												<i className="fas fa-shopping-cart" />
// 												<span>ADD TO CART</span>
// 											</a>
// 											</li>
// 										</ul>
// 										</div>
// 										<hr />
// 										<div className="ltn__social-media">
// 										<ul>
// 											<li>Share:</li>
// 											<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
// 											<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
// 											<li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
// 											<li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
// 										</ul>
// 										</div>
// 									</div>
// 									</div>
// 								</div>
// 								</div>
// 							</div>
// 							</div>
// 						</div>
// 						</div>
// 					</div>
// 				</div>

// 				<div className="ltn__modal-area ltn__add-to-cart-modal-area">
// 					<div className="modal fade" id="add_to_cart_modal" tabIndex={-1}>
// 						<div className="modal-dialog modal-md" role="document">
// 						<div className="modal-content">
// 							<div className="modal-header">
// 							<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
// 								<span aria-hidden="true">×</span>
// 							</button>
// 							</div>
// 							<div className="modal-body">
// 							<div className="ltn__quick-view-modal-inner">
// 								<div className="modal-product-item">
// 								<div className="row">
// 									<div className="col-12">
// 									<div className="modal-product-img">
// 										<img src={publicUrl+"assets/img/product/1.png"} alt="#" />
// 									</div>
// 									<div className="modal-product-info go-top">
// 										<h5 className="go-top"><Link to="/product-details">Brake Conversion Kit</Link></h5>
// 										<p className="added-cart"><i className="fa fa-check-circle" />Successfully added to your Cart</p>
// 										<div className="btn-wrapper">
// 										<Link to="/cart" className="theme-btn-1 btn btn-effect-1">View Cart</Link>
// 										<Link to="/checkout" className="theme-btn-2 btn btn-effect-2">Checkout</Link>
// 										</div>
// 									</div>
// 									{/* additional-info */}
// 									<div className="additional-info d-none">
// 										<p>We want to give you <b>10% discount</b> for your first order, <br />  Use discount code at checkout</p>
// 										<div className="payment-method">
// 										<img src={publicUrl+"assets/img/icons/payment.png"} alt="#" />
// 										</div>
// 									</div>
// 									</div>
// 								</div>
// 								</div>
// 							</div>
// 							</div>
// 						</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
//         }
// }

// export default ProductListingV1