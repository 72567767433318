import React from 'react';
import AIFSearch from './section-components/aifSearchFields';
import ProductListingAIF from './section-components/Product-listing-aif';
import Featuresv1 from './section-components/features-v1';
import ProSlider from './section-components/product-slider-v1';
import Apartment from './section-components/apartment-v1';
import VideoV1 from './section-components/video-v1';
import Category from './section-components/category-v1';
import Testimonial from './section-components/testimonial-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

import Navbar from './global-components/navbar';
import { RootProvider } from '../provider/RootProvider';

const AifSearch = () => {
    return <RootProvider>
    <div>
        {/* <Navbar CustomClass="ltn__header-transparent gradient-color-2" />  */}
        <Navbar />
        <AIFSearch />
        <ProductListingAIF />
        {/* <Featuresv1 customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"/> */}
        {/* <ProSlider /> */}
        {/* <Apartment /> */}
        {/* <VideoV1 /> */}
        {/* <Category /> */}
        {/* <Testimonial /> */}
        {/* <BlogSlider customClass="section-subtitle-2"/> */}
        {/* <CallToActionV1 /> */}
       <div className='margin-footer' ><Footer /></div> 
    </div>
    </RootProvider>
}

export default AifSearch;

