import React, { Component, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import RootContext from '../../provider/RootProvider';
import useId from 'react-use-uuid';
import axios from 'axios';
import Files from 'react-files'
import csc from "country-state-city";
import { useFormik } from "formik";
import Select from "react-select";


const AddListing = () => {

    const { projectData, setProjectData, pictureUrl, setPictureUrl, similarProducts, setSimilarProducts } = useContext(RootContext);
    const _id = useId();

    const [country, setCountry] = useState('')
    const [state, setStates] = useState('')
    const [city, setCity] = useState('')
    // let publicUrl = process.env.PUBLIC_URL + '/';
    const addressFromik = useFormik({
        initialValues: {
            country: "India",
            state: null,
            city: null
        },
        onSubmit: (values) => console.log(JSON.stringify(values))
    });

    let countries 
let allCountry = csc.getAllCountries();
console.log("Country india",allCountry)
let updatedCountries=[];
for(let i=0;i<allCountry.length-1;i++){
    console.log("Country india 1",allCountry[i])
    if(allCountry[i].name=="India" ||allCountry[i].name=="United Arab Emirates" ){
        console.log("Country india 2",allCountry[i].name)
        updatedCountries.push({
            label: allCountry[i].name,
                value: allCountry[i].id, 
        })
    }
}
    
    // const updatedCountries = countries.map((country) => ({
    //     label: country.name,
    //     value: country.id,
    //     ...country
    // }));
    const updatedStates = (countryId) =>
        csc
            .getStatesOfCountry(countryId)
            .map((state) => ({ label: state.name, value: state.id, ...state }));
    const updatedCities = (stateId) =>
        csc
            .getCitiesOfState(stateId)
            .map((city) => ({ label: city.name, value: city.id, ...city }));

    const { values, handleSubmit, setFieldValue, setValues } = addressFromik;
    console.log("values of banner", values)
    useEffect(() => {
        if (values.country) {
            setCountry(values.country.name)
        } else
            if (values.state) {
                setStates(values.state.name)
            }
        if (values.city) {
            setCity(values.city.name)
        }
    }, [values]);
    // if(values){
    // 	if(values.country){
    // 		setCountry(values.country.name)
    // 	}
    // }
    console.log("values of banner", country, state, city);

    useEffect(() => {

        setProjectData(prev => { return { ...prev, _id } });
    }, [])

    const onFilesChange = (files) => {
        console.log(files)
    }

    const onFilesError = (error, file) => {
        console.log('error code ' + error.code + ': ' + error.message)
    }

    const handleRemovePictureUrl = (index, id) => {
        console.log('index', index)

        let pictureUrls = [...pictureUrl];
        var pictureUrls_count = pictureUrls.length;
        console.log('picture urlssss', pictureUrls)
        if (pictureUrls_count > 1) {
            // Add item to it
            //   pictureUrls.splice(index, 1);
            let filtered = pictureUrls.filter(function (value, index, arr) {
                return value.id !== id;
            });
            setPictureUrl(filtered)
            // Set state
            //   this.setState({ pictureUrls });


        }






        console.log('picture url', pictureUrl)

    }

    useEffect(() => {

    }, [pictureUrl])

    const handleAddPictureUrl = () => {
        let pictureUrls = [...pictureUrl];

        pictureUrls.push({ id: (Math.random() + 1).toString(36).substring(7), url: "" });


        setPictureUrl(pictureUrls)
        console.log('picture url', pictureUrl)
    }

    const onChangePictureHandler = (e, id) => {
        // console.lo
        let pictureUrls = [...pictureUrl];

        pictureUrls.map((url) => {
            if (url.id == id) {
                url.url = e.target.value;
            }
        })


        setPictureUrl(pictureUrls)
        console.log('picture url', pictureUrl)

    }

    const onChangeHandler = (event) => {

        setProjectData((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })

    }

    const handleAddSimilarProducts = () => {
        let pictureUrls = [...similarProducts];

        pictureUrls.push({ id: (Math.random() + 1).toString(36).substring(7), url: "" });


        setSimilarProducts(pictureUrls)
        console.log('picture url', similarProducts)
    }

    const handleRemoveSimilarProducts = (index, id) => {
        console.log('index', index)

        let pictureUrls = [...similarProducts];
        var pictureUrls_count = pictureUrls.length;
        console.log('picture urlssss', pictureUrls)
        if (pictureUrls_count > 1) {
            // Add item to it
            //   pictureUrls.splice(index, 1);
            let filtered = pictureUrls.filter(function (value, index, arr) {
                return value.id !== id;
            });
            setSimilarProducts(filtered)
            // Set state
            //   this.setState({ pictureUrls });


        }






        console.log('picture url', similarProducts)

    }

    const onChangeSimilarProductsHandler = (e, id) => {
        // console.lo
        let pictureUrls = [...similarProducts];

        pictureUrls.map((url) => {
            if (url.id == id) {
                url.url = e.target.value;
            }
        })


        setSimilarProducts(pictureUrls)
        console.log('picture url', similarProducts)
    }

    const submitProjectDataHandler = () => {
        console.log("project data: ", projectData);
        if (!projectData._id ||
            !projectData.projectName ||
            !projectData.developer ||
            !projectData.locationData ||
            !projectData.propertyType ||
            !projectData.constructionStatus ||
            !projectData.minPrice ||
            !projectData.maxPrice ||
            !projectData.projectHighlights ||
            !projectData.projectInfo ||
            !projectData.developerInfo ||
            !projectData.projectVideo ||
            !projectData.logoUrl ||
            !projectData.locationMap)
            return;


        let data = JSON.stringify({ ...projectData, "pictureUrls": pictureUrl, "similarProducts": similarProducts, "persona": [], "city": city, "states": state, "country": country, "type":"realState" })
        // let data = { ...projectData,"pictureUrls": pictureUrl, "similarProducts": similarProducts, "persona": [],"city":city,"state":state, "country":country }
        console.log("data", data)
        // data = JSON.stringify(data);
        var config = {
            method: 'post',
            url: 'https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/realstateadd',
            headers: {
                'Content-Type': 'application/json'
                // 'Access-Control-Allow-Headers': 'Content-Type',
                // "Content-Type": "application/json",
                // 'Access-Control-Allow-Methods':'OPTIONS,POST',
                // "Access-Control-Allow-Origin":"*"
                // "X-Requested-With":'*'
                // 'Access-Control-Allow-Origin': '*',
                // 'Content-Type': 'application/json',
            },
            data
        };

        axios(config)
            .then(function (response) {

                // alert('Your account is successfully registered. You can now login with your registered email ID and password.')
                alert("RECORD added successfully!!")

            })
            .catch(function (error) {
                console.log(error);
            });


        alert("Record Added Successfully")

    }

    // const AddPictureContainer = () =>{
    //     return (
    //         <div className="row">

    //                                     <div className="col-md-6">
    //                                         <input type="text" value={url.value} id={url.id} name={url.id} placeholder="picture link" onChange={onChangePictureHandler} />
    //                                     </div>
    //                                     <div className="col-md-6">
    //                                         <svg xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} height='32px' width='32px' viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-minus-circle">
    //                                             <circle cx="12" cy="12" r="10"></circle>
    //                                             <line x1="8" y1="12" x2="16" y2="12"></line>
    //                                         </svg>
    //                                         {/* <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="button" onClick={handleRemovePictureUrl}>Remove </button> */}
    //                                     </div>

    //                                 </div>
    //     )
    // }


    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
        <div className="ltn__appointment-area pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ltn__appointment-inner">
                            {/* <form action="#"> */}

                            <div className="row">

                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.projectName} id="projectName" name="projectName" placeholder="Project Name" onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.developer} id="developer" name="developer" placeholder="Developer" onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.locationData} id="locationData" name="locationData" placeholder="locationData" onChange={onChangeHandler} />
                                    </div>

                                </div>
                            </div>
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.propertyType} id="propertyType" name="propertyType" placeholder="Property Type" onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.constructionStatus} id="constructionStatus" name="constructionStatus" placeholder="Construction Status" onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-2">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.minPrice} id="minPrice" name="minPrice" placeholder="Min Price" onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-2">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.maxPrice} id="maxPrice" name="maxPrice" placeholder="Max Price" onChange={onChangeHandler} />
                                    </div>

                                </div>
                            </div>
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.projectHighlights} id="projectHighlights" name="projectHighlights" placeholder="Project Highlights" onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.projectInfo} id="projectInfo" name="projectInfo" placeholder="Project Info" onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.developerInfo} id="developerInfo" name="developerInfo" placeholder="Developer Info" onChange={onChangeHandler} />
                                    </div>

                                </div>
                            </div>

                            <h6>Logo URL</h6>
                            <div className="input-item input-item-name ">
                                <input type="text" id="logoUrl" value={projectData.logoUrl} name="logoUrl" placeholder="logo url" onChange={onChangeHandler} />
                            </div>



                            <h6>Project Video</h6>
                            <div className="input-item input-item-name ">
                                <input type="text" id="projectVideo" value={projectData.projectVideo} name="projectVideo" placeholder="video url" onChange={onChangeHandler} />
                            </div>

                            {/* <input type="file" id="projectVideo" value={projectData.projectVideo} name="projectVideo" className="btn theme-btn-3 mb-10" onChange={onChangeHandler}/><br /> */}




                            <h6>Location</h6>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="input-item input-item-name ">
                                        <input type="text" value={projectData.locationMap} id="locationMap" name="locationMap" placeholder="Location link" onChange={onChangeHandler} />
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-6"><h6>Pictures</h6></div>
                                <div className="col-md-6">

                                    <svg onClick={handleAddPictureUrl} xmlns="http://www.w3.org/2000/svg" height='32px' width='32px' viewBox="0 0 24 24" style={{ cursor: "pointer", fill: "none", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2px" }}>
                                        <g id="_8.add" data-name="8.add">
                                            <circle class="cls-1" cx="12" cy="12" r="11" />
                                            <line class="cls-1" x1="12" y1="6" x2="12" y2="18" />
                                            <line class="cls-1" x1="18" y1="12" x2="6" y2="12" />
                                        </g>
                                    </svg>
                                    {/* <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="button" onClick={handleRemovePictureUrl}>ADD </button> */}
                                </div>
                            </div>


                            {
                                pictureUrl.map((url, index) => {
                                    console.log('changed url')
                                    return (<div className="row">

                                        <div className="col-md-6">
                                            <input type="text" index={index} value={url.url} id={url.id} name={url.id} placeholder="picture link" onChange={(e) => onChangePictureHandler(e, url.id)} />
                                        </div>
                                        <div className="col-md-6" >
                                            <svg onClick={() => handleRemovePictureUrl(index, url.id)} index={index} xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} height='32px' width='32px' viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-minus-circle">
                                                <circle index={index} cx="12" cy="12" r="10"></circle>
                                                <line index={index} x1="8" y1="12" x2="16" y2="12"></line>
                                            </svg>

                                        </div>

                                    </div>
                                    )
                                })
                            }


                            <div className="row">
                                <div className="col-md-6"><h6>similar Products</h6></div>
                                <div className="col-md-6">

                                    <svg onClick={handleAddSimilarProducts} xmlns="http://www.w3.org/2000/svg" height='32px' width='32px' viewBox="0 0 24 24" style={{ cursor: "pointer", fill: "none", stroke: "#000", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2px" }}>
                                        <g id="_8.add" data-name="8.add">
                                            <circle class="cls-1" cx="12" cy="12" r="11" />
                                            <line class="cls-1" x1="12" y1="6" x2="12" y2="18" />
                                            <line class="cls-1" x1="18" y1="12" x2="6" y2="12" />
                                        </g>
                                    </svg>
                                    {/* <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="button" onClick={handleRemovePictureUrl}>ADD </button> */}
                                </div>
                            </div>


                            {
                                similarProducts.map((url, index) => {
                                    console.log('changed url')
                                    return (<div className="row">

                                        <div className="col-md-6">
                                            <input type="text" index={index} value={url.url} id={url.id} name={url.id} placeholder="product link" onChange={(e) => onChangeSimilarProductsHandler(e, url.id)} />
                                        </div>
                                        <div className="col-md-6" >
                                            <svg onClick={() => handleRemoveSimilarProducts(index, url.id)} index={index} xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} height='32px' width='32px' viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-minus-circle">
                                                <circle index={index} cx="12" cy="12" r="10"></circle>
                                                <line index={index} x1="8" y1="12" x2="16" y2="12"></line>
                                            </svg>

                                        </div>

                                    </div>
                                    )
                                })
                            }

                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-4 col-md-6">
                                <label>Country</label>
                                <Select
                                    id="country"
                                    name="country"
                                    label="country"
                                    options={updatedCountries}
                                    value={values.country}
                                    // onChange={value => {
                                    //   setFieldValue("country", value);
                                    //   setFieldValue("state", null);
                                    //   setFieldValue("city", null);
                                    // }}
                                    onChange={(value) => {
                                        setValues({ country: value, state: null, city: null }, false);
                                    }}
                                />
                            </div>
                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-4 col-md-6">
                            <label>State</label>
                                <Select
                                    id="state"
                                    name="state"
                                    options={updatedStates(values.country ? values.country.value : null)}
                                    value={values.state}
                                    onChange={(value) => {
                                        setValues({ state: value, city: null }, false);
                                    }}
                                />
                            </div>
                            <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-4 col-md-6">
                            <label>City</label>
                                <Select
                                    id="city"
                                    name="city"
                                    options={updatedCities(values.state ? values.state.value : null)}
                                    value={values.city}
                                    onChange={(value) => setFieldValue("city", value)}
                                />
                            </div>





                            <div className="btn-wrapper text-center mt-30">
                                <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="button" onClick={submitProjectDataHandler}>Submit </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AddListing



