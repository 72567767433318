import axios from 'axios';
import React, { Component } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';

const Footer_v1 = (props) => {

	const [fname, setFName] = useState('')
	const [lname, setLName] = useState('')
	const [message, setMessage] = useState('')
	const [emailSender, setEmailSender] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')

	// componentDidMount() {

	// 	const $ = window.$;

	// 	let publicUrl = process.env.PUBLIC_URL + '/'
	// 	const minscript = document.createElement("script");
	// 	minscript.async = true;
	// 	minscript.src = publicUrl + "assets/js/main.js";

	// 	document.body.appendChild(minscript);

	// 	$('.go-top').find('a').on('click', function () {

	// 		$(".quarter-overlay").fadeIn(1);

	// 		$(window).scrollTop(0);

	// 		setTimeout(function () {
	// 			$(".quarter-overlay").fadeOut(300);
	// 		}, 800);

	// 	});


	// 	$(document).on('click', '.theme-btn-1 ', function () {
	// 		$('div').removeClass('modal-backdrop');
	// 		$('div').removeClass('show');
	// 		$('div').removeClass('fade');
	// 		$('body').attr("style", "");
	// 	});
	// }

	// render() {

	const handleSendEmail = () => {
		console.log("Inside function")
		let data = {
			"interestedIn": props.interested,
			"fName": fname,
			"lName": lname,
			"message": message,
			"senderEmail": emailSender,
			"category": props.category,
			"phoneNum": phoneNumber
		}

		var config = {
			method: 'post',
			url: 'https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/contactus',
			headers: {
				'Content-Type': 'application/json'
				// 'Access-Control-Allow-Headers': 'Content-Type',
				// "Content-Type": "application/json",
				// 'Access-Control-Allow-Methods':'OPTIONS,POST',
				// "Access-Control-Allow-Origin":"*"
				// "X-Requested-With":'*'
				// 'Access-Control-Allow-Origin': '*',
				// 'Content-Type': 'application/json',
			},
			data
		};

		axios(config)
			.then(function (response) {

				// alert('Your account is successfully registered. You can now login with your registered email ID and password.')
				alert("Details Submitted, we will contact ASAP")

			})
			.catch(function (error) {
				console.log(error);
			});
	}

	let publicUrl = process.env.PUBLIC_URL + '/'
	let imgattr = "Footer logo"

	return (
		<footer className="ltn__footer-area  ">
			<div className="footer-top-area  section-bg-2 plr--5">
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-6 col-md-6 col-sm-6 col-12">
							<div className="footer-widget footer-about-widget">
								<div className="footer-logo">
									<div className="site-logo">
										<img src={publicUrl + "assets/img/logonew.jpg"} alt="Logo" style={{ width: 200 }} />
									</div>
								</div>
								<p>
									Invest.Market is the registered trademark of Wealth Centric Enterprises.
									Information provided herein has been gathered from public sources. Wealth Centric Enterprises (OPC) Pvt Ltd disclaims any and all responsibility in connection with veracity of this data. Information presented on this website is for educational purposes only and should not be treated as legal, financial , or any other form of advice. Wealth Centric Enterprises (OPC) Pvt Ltd is not liable for financial or any other form of loss incurred by the user or any affiliated party on the basis of information provided herein. Wealth Centric Enterprises (OPC) Pvt Ltd is neither a stock exchange nor does it intend to get recognized as a stock exchange under the Securities Contracts Regulation Act, 1956.Wealth Centric Enterprises (OPC) Pvt Ltd is not authorized by the capital markets regulator to solicit investments. The securities traded on these platforms are not traded on any regulated exchange. Wealth Centric also provides that it does not facilitate any online or offline buying, selling, or trading of securities.</p>
								{/* <div className="footer-address">
				              <ul>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-placeholder" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p>Brooklyn, New York, United States</p>
				                  </div>
				                </li>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-call" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href="tel:+971-581197123">+971581197123</a></p>
									<p><a href="tel:+91-9398198921">+91-93981 98921</a></p>
				                  </div>
				                </li>
				                <li>
				                  <div className="footer-address-icon">
				                    <i className="icon-mail" />
				                  </div>
				                  <div className="footer-address-info">
				                    <p><a href="mailto:info@wealc.com">info@wealc.com</a></p>
									<p><a href="mailto:bhargav_vontethu@yahoo.com">bhargav_vontethu@yahoo.com</a></p>
				                  </div>
				                </li>
				              </ul>
				            </div> */}
								<div className="ltn__social-media mt-20">
									<Social />
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-md-6 col-sm-6 col-12">
							<div className="footer-widget footer-menu-widget clearfix">
								<h4 className="footer-title">Company</h4>
								<div className="footer-menu go-top">
									<div className="footer-address">
										<ul>
											<li>
												<div className="footer-address-icon">
													<i className="icon-placeholder" />
												</div>
												<div className="footer-address-info">
													<p>13-14, Road no 8
														Snehapuri Colony, Hyderabad, India.</p>
												</div>
											</li>
											<li>
												<div className="footer-address-icon">
													<i className="icon-call" />
												</div>
												<div className="footer-address-info">
													<p><a href="tel:+971-581197123">+971581197123</a></p>
													<p><a href="tel:+91-9398198921">+91-93981 98921</a></p>
												</div>
											</li>
											<li>
												<div className="footer-address-icon">
													<i className="icon-mail" />
												</div>
												<div className="footer-address-info">
													<p><a href="mailto:info@invest.market">info@invest.market</a></p>
													{/* <p><a href="mailto:bhargav_vontethu@yahoo.com">bhargav_vontethu@yahoo.com</a></p> */}
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-md-6 col-sm-6 col-12">
							<div className="footer-widget footer-menu-widget clearfix">
								<h4 className="footer-title">Contact Us
								</h4>
								<div className="footer-menu go-top">
									{/* <ul>
				                <li><Link to="/cart">Cart</Link></li>
				                <li><Link to="/wishlist">Wish List</Link></li>
				                <li><Link to="/login">Login</Link></li>
				                <li><Link to="/checkout">Checkout</Link></li>
				                <li><Link to="/about">Terms &amp; Conditions</Link></li>
				                <li><Link to="/shop">Promotional Offers</Link></li>
				              </ul> */}
									{/* <form> */}
									<label>
										Name:
										<input type="text" name="fname" style={{ height: 35 }} onChange={(e) => {
											console.log("first name", e.target.value)
											setFName(e.target.value)
										}} required />
									</label>
									{/* <label>
												Last Name:
												<input type="text" name="lname" style={{ height: 35 }} onChange={(e)=>{
													console.log("first name",e.target.value)
													setLName(e.target.value)
												}} required/>
											</label> */}
									<label>
										Email:
										<input type="email" name="email" style={{ height: 35 }} onChange={(e) => {
											console.log("first name", e.target.value)
											setEmailSender(e.target.value)
										}} required />
									</label>
									<label>
										Interested Investment:
										<input type="text" name="message" style={{ height: 35 }} onChange={(e) => {
											console.log("first name", e.target.value)
											setMessage(e.target.value)
										}} required />
									</label>
									<label>
										Phone Number:
										<input type="tel" name="message" style={{ height: 35, marginBottom: 25 }} pattern="[0-9]{10}" onChange={(e) => {
											console.log("first name", e.target.value)
											setPhoneNumber(e.target.value)
										}} required />
									</label>
									<input type="button" value="Submit" onClick={handleSendEmail} />
									{/* </form> */}
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<Copyright />
		</footer>
	)
	// }
}


export default Footer_v1