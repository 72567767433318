import React, { createContext, useState, useReducer } from "react";
import useId from 'react-use-uuid';

const RootContext = createContext();

export const RootProvider = (props) => {
    const _id = useId();


    const [projectData, setProjectData] = useState({
        _id:'',
        developer :'',
        projectName : '',
        locationData: '',
        propertyType:'',
        constructionStatus:'',
        minPrice:'',
        maxPrice:'',
        logoUrl:'',
        projectHighlights:'',
        projectInfo:'',
        developerInfo:'',
        projectVideo:'',
        locationMap:'',
        // pictureUrls:[{id:(Math.random() + 1).toString(36).substring(7),url:""}]
        // projectPictures:[],
        // similarProducts:[],

        
       
    });

    const [pictureUrl , setPictureUrl ] = useState([{id:(Math.random() + 1).toString(36).substring(7),url:""}])
    const [similarProducts , setSimilarProducts ] = useState([{id:(Math.random() + 1).toString(36).substring(7),url:""}])
    const [fetchedRealStateData,setFetchedRealStateData] = useState([])
    const [fetchedAIFData, setFetchedAIFData] = useState([])
    
    




    const context = {
        projectData, setProjectData,
        pictureUrl , setPictureUrl,
        similarProducts , setSimilarProducts,
        fetchedRealStateData,setFetchedRealStateData,
        fetchedAIFData, setFetchedAIFData

    };

    return (
        <RootContext.Provider value={context}>
            {props.children}
        </RootContext.Provider>
    );
};

export default RootContext;
