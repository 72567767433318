import React, { Component, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import csc from "country-state-city";
import { useFormik } from "formik";
import Select from "react-select";
import RootContext from '../../provider/RootProvider';
// import { Axios } from 'axios';
var axios = require('axios');
const BannerV5 = () => {
	const { fetchedRealStateData, setFetchedRealStateData } = useContext(RootContext);
	const [propertyType, setPropertyType] = useState('');
	const [minPrice, setMinPrice] = useState('');
	const [maxPrice, setMaxPrice] = useState('');
	const [country, setCountry] = useState('')
	const [state, setStates] = useState('')
	const [city, setCity] = useState('')
	const [developerName, setDeveloperName] = useState('');
	const [projectName, setProjectName] = useState('');
	let publicUrl = process.env.PUBLIC_URL + '/';
	const addressFromik = useFormik({
		initialValues: {
			country: "India",
			state: null,
			city: null
		},
		onSubmit: (values) => console.log(JSON.stringify(values))
	});
	const optionsPropertyType = [
		{ value: 'Commercial', label: 'Commercial' },
		{ value: 'Residential', label: 'Residential' },
	]

	let countries 
	let allCountry = csc.getAllCountries();
	console.log("Country india",allCountry)
	let updatedCountries=[];
	for(let i=0;i<allCountry.length-1;i++){
		console.log("Country india 1",allCountry[i])
		if(allCountry[i].name=="India" ||allCountry[i].name=="United Arab Emirates" ){
			console.log("Country india 2",allCountry[i].name)
			updatedCountries.push({
				label: allCountry[i].name,
					value: allCountry[i].id, 
			})
		}
	}


	// const countries = csc.getAllCountries();

	// const updatedCountries = countries.map((country) => ({
	// 	label: country.name,
	// 	value: country.id,
	// 	...country
	// }));
	const updatedStates = (countryId) =>
		csc
			.getStatesOfCountry(countryId)
			.map((state) => ({ label: state.name, value: state.id, ...state }));
	const updatedCities = (stateId) =>
		csc
			.getCitiesOfState(stateId)
			.map((city) => ({ label: city.name, value: city.id, ...city }));

	const { values, handleSubmit, setFieldValue, setValues } = addressFromik;
	console.log("values of banner", values)

	const searchProperty = async () => {
		let dataParams = "";
		if (minPrice) {
			if (dataParams == "") {
				dataParams = `minPrice=${minPrice}`
			} else {
				dataParams = `${dataParams}&minPrice=${minPrice}`
			}
		}
		if (maxPrice) {
			if (dataParams == "") {
				dataParams = `maxPrice=${maxPrice}`
			} else {
				dataParams = `${dataParams}&maxPrice=${maxPrice}`
			}
		}
		if (propertyType) {
			if (dataParams == "") {
				dataParams = `propertyType=${propertyType}`
			} else {
				dataParams = `${dataParams}&propertyType=${propertyType}`
			}
		}
		if (country) {
			if (dataParams == "") {
				dataParams = `country=${country}`
			} else {
				dataParams = `${dataParams}&country=${country}`
			}
		}
		if (city) {
			if (dataParams == "") {
				dataParams = `city=${city}`
			} else {
				dataParams = `${dataParams}&city=${city}`
			}
		}
		if (state) {
			if (dataParams == "") {
				dataParams = `states=${state}`
			} else {
				dataParams = `${dataParams}&states=${state}`
			}
		}
		if (projectName) {
			if (dataParams == "") {
				dataParams = `projectName=${projectName}`
			} else {
				dataParams = `${dataParams}&projectName=${projectName}`
			}
		}
		if (developerName) {
			if (dataParams == "") {
				dataParams = `developerName=${developerName}`
			} else {
				dataParams = `${dataParams}&developerName=${developerName}`
			}
		}
		dataParams = `${dataParams}&typeSearch=realState`

		console.log("Data Params", dataParams)

		let config = {
			method: 'get',
			// url: `https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/getrealstate?minPrice=${minPrice}&maxPrice=${maxPrice}&propertyType=${propertyType}&country=${country}&city=${city}&states=${state}`,
			url: `https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/getrealstate?${dataParams}`,

			// headers: { "Content-Type": "application/json", "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token", "Access-Control-Allow-Methods": "OPTIONS,POST,GET", "Access-Control-Allow-Credentials": true, "Access-Control-Allow-Origin": "*", "X-Requested-With": "*" }
			headers: {
				// "Access-Control-Allow-Headers": "*",
				// "Content-Type": "application/json",
				// "Access-Control-Allow-Origin": "*"
			}
			// headers: {
			// 	"Content-Type": "application/json",
			// 	"Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
			// 	"Access-Control-Allow-Methods": "OPTIONS,POST,GET",
			// 	"Access-Control-Allow-Credentials": true,
			// 	"Access-Control-Allow-Origin": "*",
			// 	"X-Requested-With": "*"
			// }
		};
		axios(config)
			.then(function (response) {
				console.log(JSON.stringify(response.data));
				setFetchedRealStateData(response.data.data)
			})
			.catch(function (error) {
				console.log(error);
			});

		console.log("Data inserted", propertyType, minPrice, maxPrice, country, state, city,projectName,developerName)
		// const res = await fetch(`https://dbo8irotta.execute-api.us-east-1.amazonaws.com/dev/getrealstate?minPrice=${minPrice}&maxPrice=${maxPrice}&propertyType=${propertyType}&country=${country}&city=${city}&states=${state}`);
		// console.log("Data fetched", res)
	}

	const handleChange = (e) => {
		setPropertyType(e.target.value)
		console.log("event inside handle change", e.target.value)
	}

	useEffect(() => {
		if (values.country) {
			setCountry(values.country.name)
		} else
			if (values.state) {
				setStates(values.state.name)
			}
		if (values.city) {
			setCity(values.city.name)
		}
	}, [values]);
	// if(values){
	// 	if(values.country){
	// 		setCountry(values.country.name)
	// 	}
	// }
	console.log("values of banner", country, state, city);
	console.log("event value 2", minPrice)

	// const input = document.getElementById('input1');
	// const log = document.getElementById('range');
	// input.addEventListener('input', updateValue);

	// function updateValue(e) {
	//   log.textContent = e.target.value;
	// }
	useEffect(() => { }, [values]);
	console.log("prpperty type", propertyType)
	return (
		<div className="ltn__slider-area ltn__slider-4">
			<div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">
				{/* ltn__slide-item */}
				<div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-4 bg-image bg-overlay-theme-black-60" data-bs-bg={publicUrl + "assets/img/slider/11.jpg"}>
					<div className="ltn__slide-item-inner text-left">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 align-self-center">
									<div className="slide-item-car-dealer-form">
										<div className="ltn__car-dealer-form-tab">
											<div className="ltn__tab-menu  text-uppercase">
												<div className="nav">
													<a className="active show" data-bs-toggle="tab" href="#ltn__form_tab_1_1"><i className="fas fa-home" />Buy Property</a>
													{/* <a data-bs-toggle="tab" href="#ltn__form_tab_1_2" className><i className="fas fa-home" />Sale Home</a> */}
												</div>
											</div>
											<div className="tab-content">
												<div className="tab-pane fade active show" id="ltn__form_tab_1_1">
													<div className="car-dealer-form-inner">
														<div className=" row">
															{/* <div className="col-lg-4 col-md-6">
																<label>Property Type</label>
																
																<select  value={propertyType} onChange={handleChange}>
																	<option value="Commercial">Commercial</option>
																	<option value="Residential">Residential</option>
																
																</select>
															</div> */}
															<label>Property Type</label>
															<Select options={optionsPropertyType} onChange={(value) => {
																console.log("property type option", value)
																setPropertyType(value.label)
															}} />
															{/* <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-4 col-md-6">
																<select className="nice-select">
																	<option>Location</option>
																	<option>chicago</option>
																	<option>London</option>
																	<option>Los Angeles</option>
																	<option>New York</option>
																	<option>New Jersey</option>
																</select>
															</div> */}
															{/* <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-4 col-md-6">
																<select className="nice-select">
																	<option>Budget</option>
																	<option>Less than $100</option>
																	<option>Less than $1000</option>
																	<option>Less than $10000</option>
																	<option>Less than $1000000</option>
																	
																</select>

															</div> */}
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-4 col-md-6">
																<label>Country</label>
																<Select
																	id="country"
																	name="country"
																	label="country"
																	options={updatedCountries}
																	value={values.country}
																	// onChange={value => {
																	//   setFieldValue("country", value);
																	//   setFieldValue("state", null);
																	//   setFieldValue("city", null);
																	// }}
																	onChange={(value) => {
																		setValues({ country: value, state: null, city: null }, false);
																	}}
																/>
															</div>
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-4 col-md-6">
																<label>State</label>
																<Select
																	id="state"
																	name="state"
																	options={updatedStates(values.country ? values.country.value : null)}
																	value={values.state}
																	onChange={(value) => {
																		setValues({ state: value, city: null }, false);
																	}}
																/>
															</div>
															<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-4 col-md-6">
																<label>City</label>
																<Select
																	id="city"
																	name="city"
																	options={updatedCities(values.state ? values.state.value : null)}
																	value={values.city}
																	onChange={(value) => setFieldValue("city", value)}
																/>
															</div>
															{/* <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-lg-4 col-md-6">
										<select className="nice-select">
											<option>Bedrooms</option>
											<option>1</option>
											<option>2</option>
											<option>3</option>
											<option>4</option>
										</select>
										</div>  */}
															{/* <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-4 col-md-6">                                                            
										<div className="input-item input-item-name ltn__custom-icon">
											<input type="text" name="name" placeholder="Min size (in sqft)" />
										</div>
										</div>  */}
															{/* <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-4 col-md-6">                                                            
										<div className="input-item input-item-name ltn__custom-icon">
											<input type="text" name="name" placeholder="Max size (in sqft)" />
										</div>
										</div>  */}
															<div className="car-price-filter-range col-lg-12" style={{ marginTop: 25 }}>
																<div className="price_filter">
																	<div className="price_slider_amount">

																		{/* <input type="submit" defaultValue="Your range:" onChange={(e)=>console.log("event value 1",e.target.value)} onClick={}/> */}
																		<input type="text" className="minPrice" id='input11' name="minprice" placeholder="Search by Min Price" onChange={(e) => {
																			console.log("event value", e.target.value)
																			setMinPrice(e.target.value)
																		}} />
																	</div>
																	<div className="price_slider_amount">

																		{/* <input type="submit" defaultValue="Your range:" onChange={(e)=>console.log("event value 1",e.target.value)} onClick={}/> */}
																		<input type="text" className="maxPrice" id='input12' name="maxprice" placeholder="Search by Max Price" onChange={(e) => {
																			console.log("event value", e.target.value)
																			setMaxPrice(e.target.value)
																		}} />
																	</div>
																	{/* <div className="slider-range" id='range' onChange={(e)=>console.log("event value 1",e.target.value)}/> */}
																</div>

																<div className="price_filter">
																	<div className="price_slider_amount">

																		{/* <input type="submit" defaultValue="Your range:" onChange={(e)=>console.log("event value 1",e.target.value)} onClick={}/> */}
																		<input type="text" className="projectName" id='input111' name="projectName" placeholder="Search by Project Name" onChange={(e) => {
																			console.log("event value", e.target.value)
																			setProjectName(e.target.value)
																		}} />
																	</div>
																	<div className="price_slider_amount">

																		{/* <input type="submit" defaultValue="Your range:" onChange={(e)=>console.log("event value 1",e.target.value)} onClick={}/> */}
																		<input type="text" className="developerName" id='input121' name="developerName" placeholder="Search by Developer Name" onChange={(e) => {
																			console.log("event value", e.target.value)
																			setDeveloperName(e.target.value)
																		}} />
																	</div>
																	{/* <div className="slider-range" id='range' onChange={(e)=>console.log("event value 1",e.target.value)}/> */}
																</div>

																<div className="btn-wrapper text-center go-top">

																	<button className="btn theme-btn-1 btn-effect-1 text-uppercase" onClick={searchProperty}>Search Property</button>
																</div>
															</div>
														</div>
													</div>
												</div>
												{/* <div className="tab-pane fade" id="ltn__form_tab_1_2">
									<div className="car-dealer-form-inner">
									<form action="#" className="ltn__car-dealer-form-box row">
										<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-4 col-md-6">
										<select className="nice-select">
											<option>Property Type</option>
											<option>Apartment</option>
											<option>Co-op</option>
											<option>Condo</option>
											<option>Single Family Home</option>
										</select>
										</div> 
										<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-4 col-md-6">
										<select className="nice-select">
											<option>Location</option>
											<option>chicago</option>
											<option>London</option>
											<option>Los Angeles</option>
											<option>New York</option>
											<option>New Jersey</option>
										</select>
										</div> 
										<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-4 col-md-6">
										<select className="nice-select">
											<option>Sub Location</option>
											<option>Bayonne</option>
											<option>Greenville</option>
											<option>Manhattan</option>
											<option>Queens</option>
											<option>The Heights</option>
											<option>Upper East Side</option>
											<option>West Side</option>
										</select>
										</div> 
										<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-lg-4 col-md-6">
										<select className="nice-select">
											<option>Bedrooms</option>
											<option>1</option>
											<option>2</option>
											<option>3</option>
											<option>4</option>
										</select>
										</div> 
										<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-4 col-md-6">                                                            
										<div className="input-item input-item-name ltn__custom-icon">
											<input type="text" name="name" placeholder="Min size (in sqft)" />
										</div>
										</div> 
										<div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-4 col-md-6">                                                            
										<div className="input-item input-item-name ltn__custom-icon">
											<input type="text" name="name" placeholder="Max size (in sqft)" />
										</div>
										</div> 
										<div className="car-price-filter-range col-lg-12">
										<div className="price_filter">
											<div className="price_slider_amount">
											<input type="submit" defaultValue="Your range:" /> 
											<input type="text" className="amount" name="price" placeholder="Add Your Price" /> 
											</div>
											<div className="slider-range" />
										</div>
										<div className="btn-wrapper text-center go-top">
										
											<Link to="/shop-right-sidebar" className="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</Link>
										</div>
										</div>
									</form>
									</div>
								</div> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BannerV5

